@import '../../../stylesheets/colors';

.b2b { // B2B Onboarding
    &-cr {
        margin: 40px 0;
        font-weight: 500;
        font-size: 18px;
        &1 {
            margin: 0;
        }
    }

    &-fc { // form input container
        width: 100% !important;

        /* For adding ticket id in the form */
        /* &-id { // for id input
            display: flex;
            margin: 0;
        }

        &-lb {
            text-align: left !important;
        } */

        &__lb { // form input label
            width:fit-content !important;
            font-size: 18px !important;
            font-weight: 700 !important;
            line-height: 20px !important;
            margin-right: 0px !important;
            text-align: left !important;
        }

        &__ip { // input field
            width: 100% !important;
            textarea {
                resize: both !important;
            }
        }

        &__tip { // text input field
            margin: 10px;
        }
    }
    //To make button width fit the text and not stretch
    &-btn { // button
        width: fit-content;
    }

    &-rbcont {  // radio button container
        flex-direction: column !important;
        justify-content: start;
        width: 100%;

        input {
            height: auto !important;
        }
        div {
            margin: 5px 0;
        }
    }

    &-err { // error
        color: $color-vermilion;
        font-size: 12px;
        line-height: 15px;
        height: 15px;
        width: fit-content;
        
        font-family: 'Inter', sans-serif;
        margin: 30px auto 30px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
    }
    &-res-msg {
        color: $color-forest-green;
        font-size: 12px;
        line-height: 15px;
        height: 15px;
        width: fit-content;
    }
    &-upload-csv { // upload csv screen
        margin: 0 auto;
        max-width: 800px;
    
        &-fc { // form container
            margin: 0 0 30px;
    
            &__ip {
                max-width: 400px !important;

                &-aside {
                    max-width: 350px;
                }
                &-remv {
                    color: $color-purple;
                }
            }
        }
    
        &-sample-link {
            margin-top: 6px;
            color: $color-stale-blue;
        }
    }
}
