.search {
    display: flex;
    position: relative;
    margin: 20px 5px 20px 20px;
    width:300px;

    &-modal {
        @extend .search;
        width: 400px;
    }

    &-type {
        width: 150px;
        margin: 20px 20px 20px 5px;
        border-radius: 18px;
    }

    &-input {
        width: 100%;
        border-radius: 18px;
        padding: 16px;
        padding-left: 40px;
        padding-right: 26px;
        font-size: 16px;
        text-overflow: ellipsis;
    }

    &-icon {
        position: absolute;
        left: 12px;
        top: 6px;
        width: 24px;
        height: 24px;
    }

    &-cross-icon {
        cursor: pointer;
        right: 12px;
        position: absolute;
        top: 11px;
        width: 14px;
        height: 14px;
    }

    &-title {
        color: #6B7280;
        margin-bottom: 5px;
    }

    &-default {
        margin: 20px;

        &-nsr {
            padding: 20px;
        }
    }

    &-results {
        margin: 20px;

        &-single {
            padding: 10px;
            &:hover {
                background-color: rgba(0, 184, 153, 0.1);
                cursor: pointer;
            }
        }

        &-icon {
            padding:5px;
            padding-right: 10px;
        }

        &-all {
            margin-top: 10px;
            border-top: 1px solid #E6E9ED;
            padding: 7px 10px;
            padding-top: 15px;
            &:hover {
                cursor: pointer;
            };
        }

        &-type {
            font-size: 9px;
            background-color: #f2daff;
            border-radius: 15%;
            border: 1px solid #cd70ff;
            padding: 1px 5px;
            margin: 1px;
            color: #8d4eae;
            text-transform: capitalize;

            &-sop {
                border: 1px solid #84b04f;
                color: #5a803a;
                background-color: #dcffda;
            }
        }

        &-value {
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
        }
    }
}
