.wlui {
    &-cr { // container
        display: flex;
        flex-direction: column;
        margin: 40px auto;
        max-width: 700px;
        width: 100%;
    }

    &-hw { // heading wrapper
        display: flex;
        margin-bottom: 40px;

        &__hd {
            font-size: 18px;
            font-weight: 400;
            margin: 0 auto;
            text-transform: uppercase;
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }

    &-cwr { // content wrapper
        display: flex;
        margin-bottom: 12px;

        &__cl { // content label
            font-size: 16px;
            text-transform: capitalize;
            width: 200px;
        }

        &__sp { // separator
            width: 50px;
        }

        &__cv { // content value
            font-size: 16px;
            text-align: left;
            word-break: break-word;
            width: 350px;
        }

        &__cpl { // copy label
            margin-top: 2px;
            margin-left: 12px;
        }
    }

    &-rfc { // reason form container
        display: flex;
        margin: 10px 0 40px;

        // overriding css of higher specificity from common component
        &__lb {
            width: 200px !important;
            margin-right: 50px !important;
            text-align: left !important;
        }

        &__ta {
            width: 300px;
        }
    }

    &-ctas {
        display: flex;
        justify-content: space-around;

        button {
            width: 170px;
        }
    }

    &-modal {
        &__hl {
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 30px;
            text-align: center;
        }

        &__sbhl {
            font-size: 16px;
            margin-bottom: 60px;
            text-align: center;
        }

        &__ctas { // ctas wrapper
            justify-content: space-around;
            width: 100%;
        }
    }
}
