.check {
    height: 25px;
    width: 25px;
    background-color: #86BA6F;
    border-radius: 100%;
    padding: 1px;

    &__icon {
        filter: invert(96%) sepia(8%) saturate(100%) hue-rotate(70deg) brightness(100%) contrast(100%)
    }
}

.cross {
    height: 25px;
    width: 25px;
    background-color: #AA301F;
    border-radius: 100%;
    padding: 1px;

    &__icon {
        width: 100%;
        height: 100%;
        filter: brightness(3)
    }
}
