.otpattmpt {
    margin: 0 auto 40px;
    max-width: 1000px;
    width: 100%;

    &-hw { // heading wrapper
        display: flex;
        margin-bottom: 40px;

        &__hd {
            font-size: 18px;
            font-weight: 400;
            margin: 0 auto;
            text-transform: uppercase;
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }
}
