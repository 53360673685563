.data-grid {
    &-box {
        &-title {
            width: 200px;
        }

        &-values {
            width: 100%;
        }

        &-grid {
            display: grid;
            grid-template-columns: 200px 200px 200px;
        }

        &-cell {
            padding: 20px;
            overflow-wrap: anywhere;

            &-title {
                color: #6B7280;
                font-weight: 600;
                padding: 5px 0;
                text-align: left;
            }

            &-value {
                color: #1F2937;
                font-weight: 600;
                font-size: 1rem;
                padding: 1px 0;
                text-align: left;
            }
        }
    }
}
