.agent-listing-wrapper {
    padding: 40px 40px 100px;
    margin: 0 auto;
    max-width: 1104px; /* 1024px + 80px */
    width: 100%;
    height: 100%;
    overflow: scroll;

    .header-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
}

.action-list {
    display: flex;
    justify-content: center;
}

.action-separator {
    padding: 0 10px;
}

.action-item {
    color: #A590FB;
    font-weight: 400;
    text-decoration: none;
}

.action-item:hover {
    color: #937def;
    cursor: pointer;
}
