.payuser {
    margin: 0 auto;
    max-width: 900px;

    &-fc {
        display: flex;
        margin-bottom: 30px;
    }

    &-fwr-lb {
        width: 200px !important;
    }

    &-ilb {
        width: 210px;
        margin-right: 30px;
    }

    &-lb {
        width: 200px !important;
        text-align: left !important;
    }

    &-errlb {
        margin: 5px 0 10px;
    }

    &-fc { // form input container
        width: 100% !important;

        &1 { // for dropdown input
            @extend .payuser-fc;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__lb { // form input label
            width: 200px !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            margin-right: 20px !important;
            text-align: left !important;
        }

        &__ip { // form input field
            width: 210px !important;
            margin: 0 !important;
        }
    }

    &-modal {
        &__hl {
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 30px;
            text-align: center;
        }

        &__sbhl {
            font-size: 16px;
            margin-bottom: 60px;
            text-align: center;
        }

        &__ctas { // ctas wrapper
            justify-content: space-around;
            width: 100%;
        }
    }
}
