.accesslevel {
    margin: 40px auto;

    &-label {
        font-family: Figtree, sans-serif;
        font-weight: 400;
        font-size: 18px;
        text-align: center;
    }

    &-cr {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 30px;
        row-gap: 30px;
        margin-top: 40px;
    }

    &-item {
        background-color: #00b899;
        border-radius: 12px;
        color: #ffffff;
        cursor: pointer;
        font-family: Figtree, sans-serif;
        font-weight: 400;
        font-size: 16px;
        text-align: center;
        padding: 20px 40px;
    }

    &-errlb { // error label
        color: #FA3B11;;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
    }
}
