.heading {
    padding: 20px 50px;

    &-title {
        text-transform: capitalize;
    }

    &-other {
        padding: 0 10px;
    }

    &-avatar {
        padding-right: 5px;
        width:40px;
        border-radius:20px;
    }

    &-user {
        padding: 0 20px;
    }

    &-logout {
        &-button {
            padding: 5px 10px;
            border-radius: 5px;
            background-color: #b23b3b;
            color: white;
            border: none;
            cursor: pointer;
            margin: 0 10px;
            font-weight: 400;
        }
    }
}
