.applogs {
    &-container {
        margin: 40px auto 80px;
        max-width: 900px;
        width: 100%;
    }

    &-fc {
        &--m0 {
            margin: 0;
        }

        &__lb {
            text-align: left !important;
            width: 180px !important;
        }
    }

    &-tab {
        // align-items: center;
        background-color: #D6FFC2;
        border-radius: 6px 6px 0px 0px;
        display: flex;
        font-size: 16px;
        padding: 8px 12px;
        position: fixed;
        top: 50px;

        &-lb { // label
            font-weight: 400;
        }

        &-cb { // close btn
            cursor: pointer;
            margin-left: 16px;
        }
    }
}

.applogslist {
    &-hl { // heading label
        font-size: 20px;
        font-weight: 600;
        margin: 40px 0 20px;
    }

    &-fc { // form container
        &--m0 {
            margin: 0;
            display: flex;
            margin-bottom: 20px;
        }

        &__lb { // label
            text-align: left !important;
            width: 180px !important;

            &-dd {
                margin-right: 20px;
            }
        }

        &__ip { // input field
            width: 210px !important;
            margin-right: 30px;
        }
    }

    &-sc { // search container
        padding: 10px 15px 20px;

        &__3r { // 3rd row
            margin-top: 20px;
        }

        &__cta {
            width: 200px;
        }
    }

    &-ffl { // form field label
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: left !important;
        width: 180px !important;
    }

    &-el { // err label
        margin: 5px 0 10px;
    }

    &-src { // search results container
        margin-top: 100px;
    }

    &-ec { // expandable card
        position: absolute;
        width: 100%;
        z-index: 1;
    }
}

.apploginfo {
    &-hw { // heading wrapper
        margin: 0 0 20px;

        &__hl { // heading label
            font-size: 20px;
            font-weight: 600;
        } 
    }

    &-cc { // content container
        margin: 0 auto;
        max-width: 900px;

        &--modal {
            height: 100%;
        }

        &__cwr { // content wrapper
            border: 2px solid #ccc;
            max-height: 600px;
            overflow: scroll;
            padding: 20px;
            white-space: pre-wrap;
            word-break: break-all;

            &--modal {
                font-size: 18px;
                max-height: 95%;
            }
        }

        &__fwr { // footer wrapper
            margin-top: 20px;
        }
    }
}
