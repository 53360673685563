.rops { // risk ops
    &-modal { // modal
        &__cr { // container 
            height: 100%;
            overflow: auto;
            position: relative;
        }

        &__hl { // heading label
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 30px;
            text-align: center;
        }

        &__sbhl { // sub heading label
            font-size: 16px;
            margin-bottom: 60px;
            text-align: center;
        }

        &__cta { // button
            justify-content: space-around;
            width: 100%;
        }
    }

    &-l2 { // L2 annotation details container
        &-cr {
            max-height: 400px;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            padding: 20px 30px 20px;
            overflow: auto;
            white-space: pre;
        }

        &-kvcr { // kv pair container
            width: 900px;
            margin: 30px 0;
        }

        &-fc { // form input container
            width: 700px !important;
    
            &1 { // for dropdown input
                @extend .rops-l2-fc;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
    
            &2 { // for text input
                @extend .rops-l2-fc;
                margin: 0;
            }
    
            &__lb { // form input label
                width: 200px !important;
                font-size: 18px !important;
                font-weight: 400 !important;
                line-height: 20px !important;
                margin-right: 20px !important;
                text-align: left !important;
            }
    
            &__ip { // form input field
                width: 350px !important;
                margin: 0 !important;
            }
        }
    }

    &-elb { // error label
        margin: 10px 0;
        font-size: 14px;
    }

    &-ec { // expandable container
        width: 100%;
        max-width: 1000px;
    }

    &-dp { // date picker
        margin: 0 0 15px;

        &__ip {
            width: 250px !important;
        }
    }

    &-idd { // single item dropdown
        width: 300px;
        padding: 0px;

        &1 { // for liveness and facematch payload drop ddown
            @extend .rops-idd;
            width: 380px;
        }

        &__cc {
            width: 300px;
            margin: 0 -1px;

            &1 { // for liveness and facematch payload drop ddown
                @extend .rops-idd__cc;
                width: 350px;
            }
        }

        &__cl {
            width: 100%;
            text-align: left;
        }

        &__lb { // label
            font-weight: 400;
            line-height: 20px;
            font-size: 18px;
        }
    }

    &-hl { // heading label
        &__cr {
            display: flex;
        }

        &1 {
            font-size: 20px;
            font-weight: 400;
            text-transform: capitalize;
        }

        &2 {
            font-size: 16px;
        }
    }

    &-barw { // back arrow
        cursor: pointer;
        height: 20px;
        margin-right: 20px;

        &__cr {
            display: flex;
            align-items: center;
        }
    }

    &-cr { // container
        margin: 40px auto 20px;
        max-width: 900px;
        width: 100%;

        &1 { // container for showing dequeue button
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &el { // err label
            margin: 0 auto 40px;
            font-size: 16px;
            white-space: pre-wrap;
            text-align: center;
        }
    }

    &-src { // search result container
        padding-top: 20px;
    }
}
