.udbi { // user details by id
    margin: 40px auto 80px;
    max-width: 1000px;

    &--mt0 {
        margin-top: 0;
    }

    &-fc {
        display: flex;
        margin-bottom: 30px;
    }

    &-fwr-lb {
        width: 200px !important;
    }

    &-ilb {
        width: 210px;
        margin-right: 20px;
    }

    &-lb {
        width: 200px !important;
        text-align: left !important;
    }

    &-errlb {
        margin: 0 0 20px 220px;
    }

    &-cta {
        margin-left: 220px;
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }

    &-hwr { // heading wrapper
        display: flex;
        margin-bottom: 40px;

        &__hd {
            font-size: 18px;
            font-weight: 400;
            margin: 0 auto;
            text-transform: uppercase;
        }
    }

    &-cwr { // content wrapper
        display: flex;
        margin-bottom: 20px;

        &__cl { // content label
            font-size: 16px;
            text-transform: capitalize;
            width: 200px;
        }

        &__sp { // separator
            width: 50px;
        }

        &__cv { // content value
            font-size: 16px;
            text-align: left;
            word-break: break-word;
        }
    }
}
