.csearch { // Case Search
    &-px-20 {
       padding: 0px 20px;
    }

    &-nowrap {
        white-space: nowrap;
    }

    & .radio-container {
        flex-direction: row !important;
        margin-left: 16px;
    }

    & .form-group {
        margin-top: 15px;
        
        & input {
            margin-left: 16px;
            width:50%;
        }
    }
}
