.lstus { // liveness status
    &-cr { // container
        margin: 40px auto 80px;
        max-width: 900px;
        width: 100%;
    }

    &-tab {
        background-color: #D6FFC2;
        border-radius: 6px 6px 0px 0px;
        display: flex;
        font-size: 16px;
        padding: 8px 12px;
        position: fixed;
        top: 50px;

        &-lb { // label
            font-weight: 400;
        }

        &-cb { // close btn
            cursor: pointer;
            margin-left: 16px;
        }
    }

    &-ec { // expandable card
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &-sc { // search container
        padding: 10px 15px 20px;

        &-cta {
            margin-top: 25px;
            width: 200px;
    
            &--err {
                margin-top: 0;
            }
        }
    }

    &-fc { // form container
        margin-bottom: 20px;

        &--m0 {
            margin: 0;
            margin-bottom: 20px;
        }
    }

    &-ff { // form field
        &-lb { // label
            font-size: 18px;
            font-weight: 400;
            margin-right: 50px !important;
            text-align: left !important;
            width: 200px !important;
        }

        &-ip { // input
            margin-right: 0px !important;
            width: 350px !important;
        }

        &-rfc { // reason form container
            display: flex;
            margin: 20px 0 40px;
    
            // overriding css of higher specificity from common component
            &__lb {
                width: 200px !important;
                margin-right: 50px !important;
                text-align: left !important;
            }
    
            &__ta {
                width: 350px;
            }
        }
    }

    &-el { // err label
        margin-bottom: 10px;
    }

    &-crel { // container err label
        margin-top: 80px;
    }

    &-dd { // dropdown
        margin-left: 0;
        width: 350px;

        &__cc {
            position: relative;
            width: 350px;
            margin-top: 16px;

            &--ac {
                height: 100px;
                overflow: scroll;
            }
        }
    }
}
