.tiering { // tiering css parent class
    margin: 0 auto;

    &-btn { // button
        margin: 10px;
        width: max-content;
    }

    &-modal {  // modal component
        padding: 20px;
        
    }

    &-reward-history {
        border: 1px solid #ccc; /* Add border */
        border-radius: 8px; /* Optional: rounded corners */
        padding: 20px 0 0 20px; /* Optional: spacing inside */
    }

    &-reward-history-container {
        gap: 10px; /* Spacing between items */
        padding: 20px 0; /* Vertical padding for the entire container */
    }

    &-date-picker {
        padding-top: 10px; /* Adjust the value as needed */
    }
    
    &-error-message {
        color: red;
        font-weight: bold;
        margin-top: 10px;
    }
}
