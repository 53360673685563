@import 'react-data-grid/lib/styles.css';

.tableV2{//Table version 2
    &-nsr{ // np search results
        font-size: 20px;
        margin-top: 0px;
        text-align: center;
    }

    &-sr{ //search results
        &__title {
            font-size: 22px;
            font-weight: 400;
            margin-bottom: 10px;
        }
    }

    &-cr{ // container
        margin-right: 50px;
        padding: 0 10px;
    }
}

.rdg{ // Data Grid container class
    @extend .rdg;
    overflow:scroll; // To render scrollbar always
}
