.wladmin {
    &-cr { // container
        margin: 40px auto;
        max-width: 900px;
        width: 100%;
    }

    &-ec { // expandable card
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &-fc { // form container
        margin: 0;
    }

    &-sc { // search container
        padding: 10px 15px 20px;

        &__m20 { // 3rd row
            margin-top: 20px;
        }

        &__cta {
            width: 200px;
        }
    }

    &-src { // search results container
        margin-top: 100px;
    }
}
