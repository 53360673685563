.agent-list {
    &-cr { // container
        margin: 0 auto;
        max-width: 1200px;
        padding: 40px 0 100px;
        width: 100%;
    }

    &-hcr { // header container
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;

        &__hd {
            font-size: 24px;
            font-weight: 400;
            line-height: 120%;
            text-transform: uppercase;
        }

        &__cta {
            width: 200px;
        }
    }

    &-ec { // expandable card
        max-width: 1000px;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &-sc { // search container
        padding: 10px 15px 20px;

        &__2r { // 2nd row
            margin-top: 15px;
        }

        &__cta {
            width: 200px;
        }

        &__cta2 {
            margin-left: 60px;
        }

        &__errlb {
            margin: 5px 0;
        }
    }

    &-table-sr { // table search results container
        margin-top: 84px;
    }

    &-fc { // form container
        margin: 0;

        &__lb { // to override form css of higher specificity
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            text-align: left !important;
            width: 130px !important;
        }

        &__ip {
            margin-right: 80px !important;
            width: 250px !important;
        }

        &__err {
            margin-left: 0px !important;
        }
    }

    &-action-sr { // action separator
        padding: 0 10px;
    }
}
