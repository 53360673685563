.dropdown-content-container {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 25px 0;
    margin-top: 10px;
    // min-height: 100px;
    min-width: 100px;
    position: absolute;
    z-index: 1;
    // right: 18px;
    // width: 200px;

    &-v2 {
        background-color: #ffffff;
        border-radius: 4px;
        box-shadow: rgba(0, 0, 0, 0.1) 0 0 25px 0;
        margin-top: 10px;
        min-width: 100px;
        position: absolute;
        z-index: 4;
    }
}