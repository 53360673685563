.credit-card {
     &-clc { // card limits container
        &-cwr { // content wrapper
            border: 1px solid #cccccc;
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;

            &__hl {
                font-weight: 400;
            }

            &__f1 {
                border: 1px solid #cccccc;
                flex: 1;
                padding: 10px;
                text-align: center;
            }

            &__f2 {
                border: 1px solid #cccccc;
                flex: 2;
                padding: 10px 20px;
                text-align: center;
            }
        }
    }
}
