.app-dd {
    &--btn {
        width: 100%;
        text-align: left;
        cursor: pointer;
        padding: 8px;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
    }
}
