.pal { // pre approved loans
    margin: 0 auto 40px;
    max-width: 1200px;
    width: 100%;

    &-modal { // content container
        &-kvpair {
            margin: 0;
            width: unset;
        }

        &-cta {
            margin: auto;
        }
    }

    &-th {  // table heading
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 16px;
    }

    &-btn {
        width: 250px;
        margin: auto;
    }

    &-action{ // Action Dialog
        padding: 10px 0;
        text-align: left;

        &-label {
            cursor: pointer;
            padding: 5px 15px;
            width: 100%;
            text-align: left;
        }
    }
}
