@import "../../stylesheets/containers";
.salwhitelist { // salary whitelist b2b page
    &-cr { // container
        height: 100%;
        width: 100%;
    }
    &-cc { // content container
        @extend .app-cc;
        left: 230px;
        top: 85.5px;

        /* scrollable container */
        background-color: #F5F5F5;
        flex-wrap: wrap;
    }

    &-ci { // container item
        margin: 40px 10px;
        width: 50%;
    }

    &-tabs {
        padding-left: 230px;
    }
}
