.tc-details { // transaction Case detail 
    &-expandablecard {  // section expandable card
        max-width: 100%;
        min-width: -webkit-fill-available;
    }

    &-kv { // key value pair
        &-cr {
            width: auto;
            display: grid !important;
            grid-template-columns: 1fr 1fr;
            column-gap: 10px;
        }

        &-cl { // content label
            min-width: 200px;
            font-size: 16px;
        }

        &-cv { // content value
            width: fit-content;
            font-size: 16px;
            display: flex;
        }

        &-cw { // content wrapper
            margin-bottom: 8px;
            justify-content: flex-start;
        }

        &-cw:last-child { // content wrapper last sibling
            margin-bottom: 0px;
        }
    }

    .heading3 {
        font-size: 18px;
        font-weight: 400;
    }

    &-pre { // pre content
        text-align: initial;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
    }
}
