.ca-form-wrapper {
    max-width: 600px;

    .form-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        label {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 4px;
            width: fit-content;
        }
    }
}