.disputes {
    margin: 0 auto 100px;
    max-width: 1000px;
    position: relative;
    width: 100%;

    &-hd1 { // heading text
        font-size: 16px;
        font-weight: 400;
        text-align: center;
    }

    &-ec { // expandable card
        margin: 40px 0;
        max-width: 1000px;
        width: 1000px;
    }

    &-src { // search results container
        padding-top: 20px;
    }

    &-sc { // search container
        padding: 10px 15px 20px;

        &__gap {
            row-gap: 20px;
        }

        &__3r { // 3rd row
            margin-top: 20px;
        }

        &__cta {
            width: 200px;
        }
    }

    &-el { // err label
        margin-left: 180px !important;
    }

    &-fc {
        margin: 0;

        &__ip {
            width: 400px !important;
        }
    }

    &-dd { // multi-item dropdown
        margin-right: 30px;
        min-width: 210px;

        &1 { // for update ticket id modal field
            margin-right: 0 !important;
        }

        &__wr { // wrapper style for update ticket id modal field
            justify-content: space-between;
        }

        &__lb { // to override form css of higher specificity
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            // margin-right: 0 !important;
            text-align: left !important;
            width: 180px !important;

            &1 {
                @extend .disputes-dd__lb;
                width: 160px !important;
            }

            &2 { // for view correspondence and escalate to federal modal
                @extend .disputes-dd__lb;
                width: auto !important;
            }
        }
    }

    &-idd { // single item dropdown
        width: 100%;
        padding: 0px;
        margin: 10px 0 10px;

        &__cc {
            width: 436px;
            margin: 0 -1px;
        }

        &__cl {
            width: 100%;
            text-align: left;
        }
    }

    &-modal {
        &-cr { // container
            max-height: 400px;
            overflow: auto;
            padding-bottom: 20px;
        }

        &-hd { // heading
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 12px;
            text-align: left;
        }

        &-cta {
            margin: 20px auto 0;

            &1 {
                @extend .disputes-modal-cta;
                margin: 20px;
            }
        }

        &-wrap {
            white-space: pre-wrap;
            overflow: auto;
        }
    }

    &-ad { // actions table dropdown
        &-label {
            cursor: pointer;
            font-size: 16px;
            font-weight: 400;
        }

        &-cc { // content container
            margin-left: -135px;
            margin-top: 2px;
            width: 180px;

            &-wr {
                padding: 10px 0;
                text-align: left;

                &__label {
                    cursor: pointer;
                    padding: 10px 15px;
                    text-align: left;
                    width: 100%;
    
                    &:hover {
                        background-color: #f5f5f5;
                    }

                    &[disabled] {
                        background-color: #ffffff;
                        cursor: not-allowed;
                        opacity: 0.5;
                    }
                }
            }

            // only needed for SearchResults and not needed for SearchResultsV2 since height is calculated dynamically
            /**
            * Apply !important to height for the active state.
            * This is a quick fix for the issue where the height is not being applied on button click.
            * TODO: Need to check why height is not applied on ellipsis to view details on prod env [ANKIT] [MONORAIL] [https://monorail.pointz.in/p/fi-app/issues/detail?id=53491]
            */
            &--active {
                height: 115px !important;
            }
        }
    }

    &-txtarea { // used in view details modal -> correspondence text
        resize: auto;
        width: 70%;
    }

    &-inp {
        margin: 15px 10px;
    }
}
