.pagination {
    &-container {
        display: flex;
        justify-content: space-around;
        margin: 20px auto 0;
        max-width: 200px;
        width: 100%;
    }

    &-sv { // scroll view
        margin: 20px auto 40px;
    }

    &-cta {
        color: #A590FB;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;

        &--v2 {
            color: #8D8D8D;
        }

        &--disabled {
            cursor: not-allowed;
            opacity: 0.5;
        }
    }
}
