.txns {
    &-container {
        margin: 0 auto 200px;
        max-width: 1100px;
        width: 100%;

        &--ss { // success screen
            max-width: 600px;
            text-align: center;
        }
    }

    &-exp-card { // expandable card
        margin: 40px 0;
        max-width: 1100px;
        width: 100%;

        &--m0 {
            margin: 0;
        }

        &__cr1 { // container 1 - search
            padding: 10px 15px 20px;
        }

        &__cr2 { // container 2 - dispute, first or last set
            display: flex;
            justify-content: space-between;
            padding: 10px 15px 20px;
            max-width: 500px
        }
    }

    &-dispute-table-container {
        padding: 0 10px 10px 10px;
    }

    &-csq-card {
        margin-bottom: 40px;
    }

    &-search-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;

        &--co { // channel input
            margin-right: 60px;
        }
    }

    &-m10 {
        margin-top: 10px;
    }

    &-form-container {
        margin-top: 20px;
        max-width: 1100px;

        &--m0 {
            margin: 0;
        }

        &--ml20 {
            margin-left: 20px;
        }
    }

    &-txnid-label {
        text-align: left !important;
        width: 100px !important;
    }

    &-other-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: left !important;
        width: 100px !important;
    }

    &-vertical-separator {
        margin: 0 0 20px 300px;
    }

    &-horizontal-separator {
        margin-right: 40px;
        margin-top: 12px;
    }

    &-search-cta {
        margin-top: 25px;
        width: 200px;

        &--dp {
            margin-bottom: 25px;
        }

        &--err {
            margin-top: 0;
        }
    }

    &-sc { // search container
        margin-top: 25px;

        &--err {
            margin-top: 0px;
        }

        &__cta {
            width: 200px;
        }

        &__clear {
            margin-left: 60px;
        }
    }

    &-err-label {
        margin-bottom: 10px;
    }

    &-modal { // content container
        &-hwr { // heading wrapper
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 20px;
        }

        &-cwr { // content wrapper
            display: flex;
            margin-bottom: 12px;

            &__cl { // content label
                font-size: 16px;
                text-transform: capitalize;
                width: 250px;
            }
    
            &__sp { // separator
                width: 50px;
            }
    
            &__cv { // content value
                font-size: 16px;
                text-align: left;
                word-break: break-word;
                &-bold {
                    font-weight: 600;
                }
            }
        }

        &-ccr { // content container
            height: 450px !important;
            overflow: scroll;

            &-tccr{ //table content container
                max-height: fit-content;
            }
        }

        &-bwr {
            margin: 20px auto 0;
            width: 100px;
        }

        &-red-highlight {
            color: rgb(252, 88, 88);
            font-style: italic;
            font-weight: 500;
            font-size: large;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
        }
    }

    &-ad { // actions dialog
        position: relative;

        &-label {
            cursor: pointer;
            font-size: 16px;
            font-weight: 400;
        }
    
        &-cc { // content container
            margin-left: -115px;
            margin-top: 2px;
            width: 160px;

            &-wr {
                padding: 10px 0;

                &__label {
                    cursor: pointer;
                    padding: 5px 0;
    
                    &:hover {
                        background-color: #f5f5f5;
                    }
                }    
            }

            /**
            * Apply !important to height for the active state.
            * This is a quick fix for the issue where the height is not being applied on button click.
            * TODO: Need to check why height is not applied on ellipsis to view details on prod env [ANKIT] [MONORAIL] [https://monorail.pointz.in/p/fi-app/issues/detail?id=53491]
            */
            &--active {
                height: 120px !important;
            }
        }
    }

    // raise dispute container
    &-rdc {
        &-hw { // heading wrapper
            display: flex;
            margin-bottom: 40px;

            &__hd {
                font-size: 20px;
                font-weight: 600;
                margin: 0 auto;
                text-transform: uppercase;
            }
        }

        &-ss { // success screen
            &__hd {
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 20px;
            }
        }

        &-cw { // channel wrapper
            padding: 10px 15px 20px;
        }

        &-qc { // question container
            padding: 10px 15px;
        }

        &-qw { // question wrapper
            display: flex;
            margin-bottom: 20px;

            &__qs {
                font-size: 16px;
                max-width: 500px;
                width: 100%;
            }
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }

    &-first-last-set-cr {
        margin: 0px 15px 20px;

        &__lb {
            font-size: 16px;
            font-weight: 400;
            text-decoration: underline;
        }
    }

    &-dd {
        margin-right: 30px;

        &__lb { // to override form css of higher specificity
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            // margin-right: 0 !important;
            text-align: left !important;
            width: 100px !important;
        }

        &__vl { // value wrapper
            display: flex;
            justify-content: space-between;
        }

        &__clear { // clear selection cross icon
            background-color: #dddddd;
            border-radius: 10px;
            color: #666666;
            font-size: 14px;
            width: 19px;
            height: 19px;
            padding-left: 6px;
            text-transform: lowercase;
        }

        &__cta { // button for selected value of dropdown
            width: 100%;
            text-align: left;
        }
    }

    &-fc { // form container
        margin: 20px 0 0;
        max-width: 1100px;

        &--m0 {
            margin: 0;
        }

        &--mb20 {
            margin-bottom: 20px;
        }

        &__lb { // to override form css of higher specificity
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            margin-right: 20px !important;
            text-align: left !important;
            width: 150px !important;
        }

        &__ip {
            margin-right: 80px !important;
            width: 250px !important;
        }

        &__err {
            margin-left: 0px !important;
        }
    }

    &-sp-dd { // search params dropdown
        margin-left: 0;
        width: 250px;

        &__cc {
            width: 250px;
        }
    }
}
