.accountClosure { // account closure
    display: flex;
    align-items: center;
    margin: 0 auto;
    max-width: 800px;

    &-cta {
        width: 300px;
    }

    &-rw { // row
        display: flex;
        font-size: 16px;
        margin-bottom: 24px;

        &__hd { // heading
            font-weight: 400;
            width: 190px;
        }

        &__reasons { // reasons item
            margin-bottom: 4px;
        }
    }
}
