.dfWrapper {
    display: flex;
}

.frWrapper {
    display: flex;
    flex-direction: row;
}

.frcWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.frfsWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.frwpWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.frcsbWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.frcseWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.frcfeWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.frcfsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.frfssbWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
}

.frccWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.frfscWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
}

.fcWrapper {
    display: flex;
    flex-direction: column;
}

.fccWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fcfsWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.flex-justify-center {
    justify-content: center;
}

.f05Wrapper {
    flex: 0.5 !important;
}

.f07Wrapper {
    flex: 0.7 !important; // required to override css specificity
}

.f03Wrapper {
    flex: 0.3 !important; // required to override css specificity
}

.f1Wrapper {
    flex: 1;
}

.f15Wrapper {
    flex: 1.5 !important;
}

.f2Wrapper {
    flex: 2 !important;
}

.f25Wrapper {
    flex: 2.5 !important;
}

.pos__rel {
    position: relative;
}

.ovfl-a {
    overflow: auto;
}

.fgap-col-10 {
    column-gap: 10px;
}
