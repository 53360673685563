.btu-upload-csv { // upload csv screen
    margin: 0 auto;
    max-width: 800px;

    &-fc { // form container
        margin: 0 0 30px;


        &__lb { // to override form css of higher specificity
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            margin-right: 0 !important;
            text-align: left !important;
            width: 300px !important;
        }

        &__ip {
            width: 400px !important;
        }

        &__desc {
            color: #8D8D8D;
            font-size: 14px;
            margin-top: 2px;
        }

        &__err {
            margin-left: 0px !important;
        }
    }

    &-sample-link {
        margin-top: 6px;
    }

    &-cta {
        margin: 60px 0 0 300px;
        width: 400px;
    }

    &-el { // err label
        font-size: 13px;
        font-weight: normal;
        margin-left: 300px;
    }

    &-job-id {
        font-size: 18px;
        font-weight: normal;
        margin: 80px auto 0;
        text-align: center;
        white-space: pre-line;
        width: 500px;
    }
}
