.menu-container {
    background-color: #E5FCE1;
    padding: 10px 0 40px;
    width: 220px;
    /* scrollable menu */
    height: calc(100% - 88px);
    overflow: auto;
    position: fixed;
    top: 88px;

    .menu-wrapper {
        &:hover {
            background-color: rgba(192, 255, 181, 0.4);;
        }
    }

    .menu-wrapper--active {
        background-color: #C0FFB5 !important;
    }

    .sidebar-border {
        background-color: transparent;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        height: 36px;
        width: 3px;
        visibility: hidden;
    }

    .sidebar-border--active {
        background-color: #5E81F3;
    }

    .menu-item {
        align-items: center;
        color: #383838;
        display: flex;
        font-size: 16px;
        justify-content: space-between;
        padding: 10px 20px;
        width: 100%;

        &:hover {
            background-color: rgba(192, 255, 181, 0.4);;
            cursor: pointer;
        }

        &--category {
            padding-left: 20px;
        }
    
        &--sub-category {
            padding-left: 40px;
        }

        &--active {
            background-color: #C0FFB5;
            font-weight: 400;
        }

        &--disabled {
            background-color: #E5FCE1 !important;
            color: #999999;
            cursor: not-allowed !important;
        }

        .arrow-icon {
            height: 12px;
            width: 12px;
        }

        .collapsed-arrow-icon {
            @extend .arrow-icon;
            transform: rotate(180deg);
        }

        .expanded-arrow-icon {
            @extend .arrow-icon;
            transform: rotate(0deg);
        }
    }

    .submenu-item {
        padding-left: 40px;

        &--nested {
            padding-left: 60px;
        }

        &--active {
            background-color: #C0FFB5;
            font-weight: 400;
        }

        &--disabled {
            background-color: #E5FCE1 !important;
            color: #999999;
            cursor: not-allowed !important;
        }
    }
}

.show-lock-symbol {
    position: absolute;
    left : 15px;
    top: 6px;
}

.lock-inner {
    left : 38px !important;
}

.menu-container-v2 {
    background-color: #E5FCE1;
    padding: 10px 0 40px;
    width: 220px;
    overflow: auto;
    top: 88px;

    .menu-wrapper {
        &:hover {
            background-color: rgba(192, 255, 181, 0.4);;
        }
    }

    .menu-wrapper--active {
        background-color: #C0FFB5 !important;
    }

    .sidebar-border {
        background-color: transparent;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
        height: 36px;
        width: 3px;
        visibility: hidden;
    }

    .sidebar-border--active {
        background-color: #5E81F3;
    }

    .menu-item {
        align-items: center;
        color: #383838;
        display: flex;
        font-size: 16px;
        justify-content: space-between;
        padding: 10px 20px;
        width: 100%;

        &:hover {
            background-color: rgba(192, 255, 181, 0.4);;
            cursor: pointer;
        }

        &--category {
            padding-left: 20px;
        }
    
        &--sub-category {
            padding-left: 40px;
        }

        &--active {
            background-color: #C0FFB5;
            font-weight: 400;
        }

        &--disabled {
            background-color: #E5FCE1 !important;
            color: #999999;
            cursor: not-allowed !important;
        }

        .arrow-icon {
            height: 12px;
            width: 12px;
        }

        .collapsed-arrow-icon {
            @extend .arrow-icon;
            transform: rotate(180deg);
        }

        .expanded-arrow-icon {
            @extend .arrow-icon;
            transform: rotate(0deg);
        }
    }

    .submenu-item {
        padding-left: 40px;

        &--nested {
            padding-left: 60px;
        }

        &--active {
            background-color: #C0FFB5;
            font-weight: 400;
        }

        &--disabled {
            background-color: #E5FCE1 !important;
            color: #999999;
            cursor: not-allowed !important;
        }
    }
}