.btn-wrapper {
    align-items: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    font-family: Figtree, sans-serif;
    font-weight: 600;
    height: 36px;
    justify-content: center;
    line-height: 1.4;
    padding: 8px 16px;
    min-width: 100px;
}

.btn-wrapper--primary {
    background-color: #45CEEB;
    color: #ffffff;

    &:hover {
        background-color: #41c8e4;
    }

    &:disabled {
        background-color: #45CEEB;
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.btn-wrapper--primary-v2 {
    background-color: #00B899;
    border-radius: 8px;
    color: #FFFFFF;
    letter-spacing: 0.45px;
    line-height: 110%;
    // height: inherit;
    padding: 10px 20px;
    text-transform: uppercase;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
}

.btn-wrapper--secondary {
    background-color: transparent;
    border: 1px solid #45CEEB;
    color: #45CEEB;

    &:hover {
        background-color: rgba(212, 241, 247, 0.4);
    }

    &:disabled {
        background-color: transparent;
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.btn-wrapper--secondary-v2 {
    background-color: #FFFFFF;
    border: 1px solid #F5F5F5;
    border-radius: 8px;
    color: #00B899;
    letter-spacing: 0.45px;
    line-height: 110%;
    // height: inherit;
    padding: 10px 20px;
    text-transform: uppercase;
    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.btn-wrapper--warning {
    background-color: #FF7B31;
    color: #ffffff;

    &:hover {
        background-color: #f77329;
    }

    &:disabled {
        background-color: #FF7B31;
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.btn-wrapper--link {
    color: #8D8D8D;
    font-weight: 400;
    height: inherit;
    min-width: unset;
    padding: 0px 8px;
    text-decoration: none;

    &:hover {
        color: #00B899;
        cursor: pointer;
    }

    &:disabled {
        color: #8D8D8D !important;
        cursor: not-allowed !important;
        opacity: 0.5;
    }
}

.btn-wrapper--tertiary {
    background-color: #F0F0F0;
    color: #333333;
    border: 1px solid #CCCCCC;
    &:hover {
        background-color: #E0E0E0;
    }
    &:disabled {
        background-color: #F0F0F0;
        cursor: not-allowed;
        opacity: 0.5;
    }
}

