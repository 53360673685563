.agent-info-wrapper {
    padding: 40px;
    margin: 0 auto;
    max-width: 1104px; /* 1024px + 80px */
    width: 100%;

    .header-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }
}
  