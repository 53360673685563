.debitcard {
    &-cr { // container
        margin: 0 auto;
        max-width: 1000px;
        width: 100%;

        &--cs { // confirm screens
            max-width: 600px;
        }
    }

    &-heading {
        font-size: 20px;
        font-weight: 600;
    }

    &-toggle-container {
        display: flex;
        justify-content: flex-start;
        margin-top: 80px;

        .toggle {
            display: flex;
        }

        label {
            margin-right: 10px;
            font-weight: 400;
        }
    }

    // confirm screen container
    &-cc {
        &__heading-wrapper {
            display: flex;
            margin-bottom: 40px;
        }

        &__heading {
            font-size: 20px;
            font-weight: 600;
            margin: 0 auto;
        }

        &__subheading {
            font-size: 16px;
            margin-bottom: 40px;
            text-align: center;

            &--rv2 { // row variant 2
                margin-top: 10px;
            }
        }

        &__ctas-wrapper {
            display: flex;
            justify-content: space-evenly;
        }

        &__ctas {
            width: 200px;
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }

    &-ad { // actions dialog
        &-label {
            cursor: pointer;
            font-size: 16px;
            font-weight: 400;
        }
    
        &-cc { // content container
            margin-left: -135px;
            margin-top: 2px;
            width: 180px;

            &-wr {
                padding: 10px 0;
                text-align: left;

                &__label {
                    cursor: pointer;
                    padding: 5px 15px;
    
                    &:hover {
                        background-color: #f5f5f5;
                    }

                    &[disabled] {
                        background-color: #ffffff;
                        cursor: not-allowed;
                        opacity: 0.5;
                    }
                }
            }

            /**
            * Apply !important to height for the active state.
            * This is a quick fix for the issue where the height is not being applied on button click.
            * TODO: Need to check why height is not applied on ellipsis to view details on prod env [ANKIT] [MONORAIL] [https://monorail.pointz.in/p/fi-app/issues/detail?id=53491]
            */
            &--active-agent {
                height: 150px !important;
            }

            /**
            * Apply !important to height for the active state.
            * This is a quick fix for the issue where the height is not being applied on button click.
            * TODO: Need to check why height is not applied on ellipsis to view details on prod env [ANKIT] [MONORAIL] [https://monorail.pointz.in/p/fi-app/issues/detail?id=53491]
            */
            &--active {
                height: fit-content !important;
            }
        }
    }

    // card limits container
    &-clc {
        &-hwr { // heading wrapper
            display: flex;
            margin-bottom: 40px;
        }

        &-hl { // heading label
            font-size: 20px;
            font-weight: 600;
            margin: 0 auto;
        }

        &-cwr { // content wrapper
            border: 1px solid #cccccc;
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;

            &__hl {
                font-weight: 400;
            }

            &__f1 {
                border: 1px solid #cccccc;
                flex: 1;
                padding: 10px;
                text-align: center;
            }

            &__f2 {
                border: 1px solid #cccccc;
                flex: 2;
                padding: 10px 20px;
                text-align: center;
            }
        }
    }

    // card settings container
    &-csc {
        margin-top: 150px;

        &-wr { // wrapper
            margin-bottom: 20px;
        }

        &__hl { // heading label
            font-size: 16px;
            text-transform: capitalize;
            width: 200px;
        }

        &__sp { // separator
            width: 50px;
        }

        &__cl { // content label
            font-size: 16px;
            text-align: left;
            overflow-wrap: anywhere;
            max-width: 400px;
        }
    }

    &-modal { // content container
        &-hwr { // heading wrapper
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 16px;
        }

        &-cwr { // content wrapper
            display: flex;
            margin-bottom: 12px;

            &__cl { // content label
                font-size: 16px;
                text-transform: capitalize;
                width: 200px;
            }
    
            &__sp { // separator
                width: 50px;
            }
    
            &__cv { // content value
                font-size: 16px;
                text-align: left;
                word-break: break-word;
            }
        }

        &-bwr {
            margin: 20px auto 0;
            width: 100px;
        }

        &-ec { // expandable card
            margin: 20px 0 0;

            &-hd { // heading
                font-size: 16px;
            }

            &-wr { // tracking info wrapper
                padding: 10px 15px 20px;
                max-height: 300px;
                overflow-y: scroll;
            }

            &-tlc { // table label container
                max-height: 200px;
            }
        }
    }

    &-forex-txn-cr {
        margin-top: 60px;
    }
}
