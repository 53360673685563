.auth-factor-form-wrapper {
    margin: 20px 0;
    max-width: 1000px;
}

.user-auth {
    &-container {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 1000px;
        width: 100%;

        &--header {
            flex-direction: row;
            margin-bottom: 30px;
        }
    }

    &-label {
        font-size: 20px;
        font-weight: 600;
        margin: 0 auto;
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }

    &-desc {
        font-size: 16px;
        margin-bottom: 40px;
        text-align: center;
    }

    &-warning-label {
        font-size: 18px;
        font-weight: 400;
        margin: 40px auto;
        max-width: 650px;
        text-align: center;
    }

    &-warning-cta {
        margin: 0 auto 40px;
        width: 300px;
    }
}
