.accesslevel-dd { // access level dropdown
    margin-right: 60px;

    .main-label {
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        margin-right: 6px;
        text-transform: capitalize;
        color: #FFFFFF;
    }

    .arrow-icon {
        cursor: pointer;
        height: 12px;
        width: 12px;
        position: relative;
        top: 2px;
    }

    .collapsed-arrow-icon {
        @extend .arrow-icon;
        transform: rotate(180deg);
    }

    .content-container {
        right: 140px;
        width: 230px;
        word-break: break-word;
    
        .user-action-wrapper {
            padding: 10px 0;
    
            .label {
                cursor: pointer;
                font-size: 16px;
                font-weight: 400;
                padding: 10px 15px;
    
                &:hover {
                    background-color: #f5f5f5;
                }
            }
        }
    }

    &-modal {
        &__lb1 { // label 1
            font-family: Figtree, sans-serif;
            font-size: 24px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
        }

        &__lb2 { // label 2
            color: #8D8D8D;
            font-size: 20px;
            font-weight: 500;
            line-height: 28px;
            margin: 20px 0 40px;
            text-align: center;
        }

        &__cta {
            width: 250px;
        }
    }
}
