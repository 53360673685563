.ctri { // card tracking info
    &-modal { // content container
        &-hwr { // heading wrapper
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 16px;
        }

        &-cwr { // content wrapper
            display: flex;
            margin-bottom: 12px;

            &__cl { // content label
                font-size: 16px;
                text-transform: capitalize;
                width: 200px;
            }
    
            &__sp { // separator
                width: 50px;
            }
    
            &__cv { // content value
                font-size: 16px;
                text-align: left;
                word-break: break-word;
            }
        }

        &-bwr { // button width
            margin: 20px auto 0;
            width: 100px;
        }

        &-ec { // expandable card
            margin: 20px 0 0;

            &-hd { // heading
                font-size: 16px;
            }

            &-wr { // tracking info wrapper
                padding: 10px 15px 20px;
                max-height: 300px;
                overflow-y: scroll;
            }

            &-tlc { // table label container
                max-height: 200px;
            }
        }
    }
}
