.fd-info {
    &-kv {
        &__cr {
            width: initial;
            margin: 0 !important;
            max-height: 600px;
            overflow: auto;
        }

        &__hd {
            margin-bottom: 40px;
        }

        &__vc {
            width: 300px;
        }
    }

    &-sb {
        &-header__hide{
            display: none;
        }

        &-items__hide{
            display: none;
        }
    }
}
