.defr { // ticket default route
    &-cr {
        margin: 60px auto 100px;
        max-width: 1000px;
        width: 100%;
    }

    &-call-pg { // call page
        &-cta { // ticket id cta
            margin: 40px auto 0;
        }

        &-ref-cta { // refresh cta
            margin-left: auto;
            margin-bottom: 20px;
        }
    }

    &-ticket-list-pg { // ticket list page for call
        &-table {
            &-sr {
                margin-top: 40px;
            }
        }

        &-cta { // force refresh cta
            margin-left: 40px;
        }
    }

    &-modal {
        &-lb {
            font-size: 18px;
            font-family: Inter, sans-serif;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            margin-bottom: 32px;
            text-align: left;
        }

        &-cta {
            margin: 0 auto;
            width: 200px;
        }
    }

    &-new-ticket-pg { // new ticket page
        &-icon {
            cursor: pointer;
            height: 24px;
            margin-left: 8px;
            width: 24px;
        }

        &-primary-cta {
            width: 200px;
        }

        &-link-cta {
            font-size: 16px;
            margin-left: 40px;
        }

        &-lb {
            font-size: 24px;
            font-family: Inter, sans-serif;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
            text-align: left;
        }
    }

    &-fc { // form container
        margin: 0;

        &__lb { // to override form css of higher specificity
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            margin-right: 0 !important;
            text-align: left !important;
            width: 250px !important;
        }

        &__ip {
            margin-right: 80px !important;
            width: 250px !important;
        }

        &__err {
            margin-left: 0px !important;
        }
    }

    &-ua-modal { // user authentication modal
        &-hd { // heading
            font-size: 20px;
            margin-bottom: 32px;
            text-align: center;
            text-decoration: underline;
        }

        &-ucwr { // user confirmation wrapper
            align-items: center;
            display: flex;
            margin-top: 32px;

            &__lb { // label
                font-size: 18px;
                font-weight: 400 !important;
                line-height: 20px !important;
                text-align: left !important;
                width: 250px !important;
            }

            &__name {
                font-size: 18px;
                text-transform: capitalize;
            }
        
            &__p-cta { // primary cta
                margin: 0 40px;
            }
        }

        &-cta {
            margin: 60px auto 0;
            width: 250px;
        }
    }

    &-ef-modal { // error flow modal
        &-hd { // heading
            font-size: 20px;
            line-height: 24px;
            margin: 0 auto 32px;
            text-align: center;
            width: 400px;
        }

        &-cta-wr { // cta wrapper
            display: flex;
            justify-content: space-evenly;
        }

        &-cta {
            width: 200px;
        }
    }

    &-sep {
        width: 20%;
    }
}
