@import '../../stylesheets/colors';

.credit-card { // credit card details
    &-cr { // container
        margin: 0 auto;
        max-width: 1000px;
        width: 100%;
        padding: 30px 0px;

        &--cs { // confirm screens
            max-width: 600px;
        }
    }

    &-ad { // actions dialog
        &-cc { // content container
            &-wr {
                padding: 10px 0;
                text-align: left;

                &__label {
                    cursor: pointer;
                    padding: 5px 15px;
                    width: 100%;
                    text-align: left;

                    &:hover {
                        background-color: #f5f5f5;
                    }

                    &[disabled] {
                        background-color: #ffffff;
                        cursor: not-allowed;
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    &-rewards {
        &-container {
            margin-top: 20px;
            display: flex;
            align-items: center;
            margin-bottom: 0;

            &__head {
                margin-right: 20px;
                font-size: 18px;
                font-weight: 400;
            }

            &__button {
                color: $color-portage;
                border: 2px solid $color-biloba-flower;
                padding: 5px;

            }
        }

        &-heading {
            font-size: 24px;
            font-weight: 400;
            line-height: 120%;
            margin: 0 auto;
            margin: 20px 0px;
            width: 700px;
        }
        &-lb {
            color: #8D8D8D;
            font-weight: 400;
            font-size: 18px;
            line-height: 110%;
            letter-spacing: 0.45px;
            margin-right: 50px;
            text-transform: capitalize;
            width: 300px;
            word-break: break-word;
        }
        &-val {
            color: #282828;
            font-family: Inter, sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 140%;
            text-align: left;
            width: 450px;
            word-break: break-word;
        }
        &-table-lb {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 10px;
        }
    }
}
