 .txnrisk { //transaction risk flow styles
    &-modal { // modal
        &__hl { // heading label
            margin-bottom: 30px;
            text-align: center;
        }

        &__ctas { // ctas wrapper
            justify-content: space-evenly;
            width: 100%;
        }
    }
 }

 .transaction-risk-body {
    width: 100%;
 }

 .outlook-info {
    display: flex;
    margin: 0px 55px 0 34px;
 }

 .outlook-info .linked-in-auth-panel-notification-container {
    flex-basis: 100%;
 }
 