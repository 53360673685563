.profile-dropdown {
    .label {
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        margin-right: 6px;
        text-transform: capitalize;
    }

    .name {
        color: #FFFFFF;
    }

    .arrow-icon {
        cursor: pointer;
        height: 12px;
        width: 12px;
        position: relative;
        top: 2px;
    }

    .collapsed-arrow-icon {
        @extend .arrow-icon;
        transform: rotate(180deg);
    }

    .content-container {
        right: 18px;
        width: 220px;

        .user-info-wrapper {
            border-bottom: 1px solid #eeeeee;
            padding: 15px;
    
            .label {
                cursor: default;
                font-size: 14px;
                font-weight: 400;
            }
    
            .sublabel {
                display: flex;
                flex-direction: column;
                font-size: 14px;
                font-weight: 200;
                margin-top: 2px;

                &--role {
                    margin-top: 5px;
                    text-transform: uppercase;
                }
            }
        }
    
        .user-action-wrapper {
            border-bottom: 1px solid #eeeeee;
            padding: 10px 0;
    
            .label {
                cursor: pointer;
                font-size: 14px;
                font-weight: 200;
                padding: 10px 15px;
    
                &:hover {
                    background-color: #f5f5f5;
                }
            }
        }
    
        .user-logout-wrapper {
            padding: 15px;
    
            .label {
                font-size: 14px;
                font-weight: 200;
            }
        }

        &--agent {
            height: 133px;
        }

        &--admin {
            height: 298px;
        }

        &--qa {
            height: 262px;
        }

        &--developer {
            height: 190px;
        }

        &--vlv { // view liveness video
            height: 190px;
        }

        &--superadmin { // super admin
            height: 262px;
        }

        &--wl { // waitlist approver
            height: 190px;
        }

        &--qal { // qa lead
            height: 133px;
        }

        &--fa { // federal agent
            height: 133px;
        }

        &--rops { // risk ops
            height: 133px;
        }

        &--fitadmin { // fit admin
            height: 190px;
        }

        &--adminrestricted { // admin restricted
            height: 190px;
        }
    }
}

