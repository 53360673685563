@import 'colors';

.link {
    color: $color-melrose;
    font-weight: 400;
    text-decoration: none;
}

.link:hover {
    color: $color-portage;
    cursor: pointer;
    text-decoration: underline;
}

.link--disabled {
    color: $color-melrose !important;
    cursor: not-allowed !important;
    opacity: 0.5;
    text-decoration: none !important;
}

// new link design consistent with the link button
.link-v2 {
    color: $color-gray;
    font-weight: 400;
    height: inherit;
    padding: 0px 8px;
    text-decoration: none;
}

.link-v2:hover {
    color: $color-forest-green;
    cursor: pointer;
}

.transform-transition {
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
}

.height-transition {
    height: 0;
    min-height: 0 !important;
    overflow: hidden;
    transition-property: height;
    transition-duration: 0.4s;
    transition-timing-function: ease-in-out;
}

.err-label {
    color: $color-vermilion;
    font-size: 12px;
    line-height: 15px;
    height: 15px;
    width: fit-content;

    &1 {
        @extend .err-label;
        margin: 40px auto 40px;
        font-size: 16px;
        white-space: pre-wrap;
        text-align: center;
        height: auto;
    }

    &2 {
        color: $color-vermilion;
        font-size: 16px;
        margin: 30px auto;
        text-align: center;
        white-space: pre;
    }
}

.container-err-label {
    font-size: 16px;
    margin-top: 40px;
}

.non-selectable-content {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
}


.ad { // dropdown action dialog
    position: relative;
    
    &-dots { // menu 3 dots
        cursor: pointer;
    }

    &-cc { // content container
        overflow: hidden;
        transition-property: transform;
        transition-duration: 0.4s;
        transition-timing-function: ease-in-out;
        transform: scaleY(0);    
        transform-origin: top;
        display: flex;
        flex-direction: column;
        max-width: 50%;
        word-break: break-word;
        min-width: 130px !important;
        top: 0;
        left: 25%;

        &__it { // content items
            cursor: pointer;
            padding: 10px;

            &:hover {
                background-color: #f5f5f5;
            }
        }

        &--active {
            transform: scaleY(1);
        }
    }
}

.expandable-card {
    max-width: 1000px;
    position: absolute;
    width: 100%;
    z-index: 1;
}
