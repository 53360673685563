.link-wrapper{
    &-btn {
        background-color: #00B899;
        border-radius: 8px;
        color: #FFFFFF;
        font-weight: 500;
        letter-spacing: 0.45px;
        line-height: 110%;
        padding: 10px 20px;
        text-transform: uppercase;
        
        &:disabled {
            cursor: not-allowed;
            opacity: 0.6;
        }
    }
}
