.form-group.phone-number {
    .input-wrapper {
        display: flex;

        .country-code {
            width: 80px;
            margin-right: 15px;
        }
        .national-number {
            width: 100%;
        }
    }
}