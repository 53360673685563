.wldtls { // waitlist details
    &-cr { // container
        margin: 40px auto 80px;
        max-width: 800px;
        width: 100%;
        height: 100%;
        overflow: scroll;
    }

    &-cr-v2 { // container
        margin: 40px auto 80px;
        max-width: 800px;
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    &-hwr { // heading wrapper
        display: flex;
        margin-bottom: 40px;

        &__hd {
            font-size: 18px;
            font-weight: 400;
            margin: 0 auto;
            text-transform: uppercase;
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }

    &-cwr { // content wrapper
        display: flex;
        margin-bottom: 20px;

        &__cl { // content label
            font-size: 16px;
            text-transform: capitalize;
            width: 200px;
        }

        &__sp { // separator
            width: 50px;
        }

        &__cv { // content value
            font-size: 16px;
            text-align: left;
            word-break: break-word;
        }
    }

    &-bwr {
        margin: 20px auto 0;
        width: 100px;
    }
}
