.maintenance-mode {
    &-cr { // container
        font-family: Inter, sans-serif;
        margin: 100px auto 0;
        text-align: center;
        width: 450px;
    }

    &-lb1 { // label 1
        font-family: Figtree, sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
    }

    &-lb2 { // label 2
        color: #8D8D8D;
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        margin-top: 24px;
    }
}
