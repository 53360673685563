.cc-es2 {
    &-heading-container {
        display: flex;
        align-items: center;
    }

    &-heading {
        font-size: 20px;
        font-weight: 600;
    }

}