.vkyc-auditor {
    &-call-queue {
        &-screen {
            min-height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-message {
            &-icon {
                margin-right: 10px;
            }
            &-container {
                border: 0.5px solid #B2B5B9;
                border-radius: 50px;
                padding: 15px;
                display: flex;
                align-items: center;
                width: 500px;
            }
        }
    }

    &-container {
        // display: flex;
        width: 100%;
        height: 500px;

        &-title {
            color: #374151;
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 10px;
        }
    }

    &-left-video-container {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-right-qna-container {
        width: 70%;
    }

    &-qna-container {
        height: 100%;
        overflow: scroll;
    }
    
    &-agent {
        &-video {
            position: fixed;
            right: 10px;
            bottom: 10px;
            height: 144px;
            width: 256px;
        }
    }
    
    &-customer {
        &-video {
            height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-bottom {
        &-options {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-top: 5px;
        }
    }

    &-location {
        &-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        &-lat-long {
            &_container {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
            }
            &_key {
                color: #929599;
                font-size: 12px;
                font-weight: 600;
            }
            &_value {
                color: #6A6D70;
                font-size: 12px;
                font-weight: 600;
            }
            
        }
    }

    &-image {
        height: 360px;
        width: 202px;
        border-radius: 20px;
        position: relative;

        &-container {
            display: flex;
            height: 100%;
            align-items: center;
        }
        &-placeholder {
            border: 1px solid #B2B5B9;
            background-color: #EFF2F6;
            height: 360px;
            width: 202px;
            border-radius: 20px;
            overflow: hidden;
        }

        &-btn {
            &__capture {
                text-align: center;
                position: relative;
                bottom: 50px;
                left: 50px;
            }
        }
    }
    
    &-button-end {
        background-color: #AA301F;
        color: #F6F9FD;
    }

    &-step {
        &-container {
            border: 1px solid rgb(162, 162, 162);
            border-radius: 20px;
            padding: 20px;
            margin-bottom: 20px;
        }
        &-option {
            margin: 20px 20px 0 0;
            border-radius: 20px !important;

            &-check {
                height: 25px;
                width: 25px;
                // filter: invert(86%) sepia(12%) saturate(600%) hue-rotate(74deg) brightness(93%) contrast(88%);
                background-color: #86BA6F;
                border-radius: 100%;
                padding: 1px;

                &__icon {
                    filter: invert(96%) sepia(8%) saturate(100%) hue-rotate(70deg) brightness(100%) contrast(100%)
                }
            }

            &-cross {
                height: 25px;
                width: 25px;
                // filter: invert(86%) sepia(12%) saturate(600%) hue-rotate(74deg) brightness(93%) contrast(88%);
                background-color: #AA301F;
                border-radius: 100%;
                padding: 1px;

                &__icon {
                    width: 100%;
                    height: 100%;
                    filter: brightness(3)
                }
            }
        }
    }

    &-kv {
        &-key {
            min-width: 200px;
            font-weight: 600;
            color: #6A6D70;
        }
        &-container {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
        }
        &-value {
            color: #313234;
            font-weight: 600;
        }
    }

    &-match-score {
        &-container {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            align-items: center;
        }
        &-percentage {
            font-weight: 600;
            font-size: 24px;

            &--green {
                color: #86BA6F;
            }
            &--red {
                color: #AA301F;
            }
        }
        &-progress {
            width: 100%;
            margin-left: 20px;
            &--green {
                background-color: #86BA6F;
            }
            &--red {
                background-color: #AA301F;
            }
        }
    }

    &-image-comparison {
        &-container {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            align-items: center;
            border-radius: 10px;
            background-color: #EFF2F6;
            margin: 10px;
        }
        &-title {
            font-weight: 600;
            color: #313234;
            background-color: #E6E9ED;
            width: 100%;
            padding-top: 5px;
            padding-bottom: 5px;
            text-align: center;
        }
        &-img {
            &-container {
                display: flex;
                align-items: center;
                margin-right: 20px;
                padding: 10px;
            }
        }
    }

    &-final-screen-row {
        display: flex;
        align-items: center;
        margin-top: 20px;
        overflow-x: auto;
    }

    &-video-player-container {

        div{
            width: auto !important;
            height: 600px !important
        }
    }

    &-video-container {
        display: flex;
        width: 100%;
        display: flex;
        padding: 20px;
        background: white;
        border: 30px white;
        border-radius: 16px;
        &-user-details {
            flex-basis: 40%;
            &-container {
                margin-top: 100px;
                margin-left: 50px;
                &-text {
                    &-label {
                    font-family: Figtree;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 16px;
                    text-align: left;
                    color: #6A6D70;
                    }
                    &-value {
                        //styleName: Headline L;
                        font-family: Figtree;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 24px;
                        text-align: left;
                    }
                }

                &-action {
                    display: flex;
                    margin-top: 20px;

                    &-btn {
                        background-color: #AA301F;
                        color: white;
                        font: 16px;
                        border-radius: 20px;
                        margin-right: 10px;
                    }

                    .approve {
                        background-color: #00866F;
                    }

                    .reject {
                        background-color: #AA301F;
                    }
                }
            }
            
            
        }
        &-player {
            flex-basis: 60%;
            div{
                width: 100% !important;
                height: auto !important;
            }
        }
    }

    &-case-details {
        width: 100%;
    }

    &-page-container {
        max-height: 100%;
        height: 1200px;
        overflow-y: auto;
        background-color: #e7e9ed;
        padding: 0 50px 0px 50px;
    }

    &-review-success {
        width: 100%;
        height: calc(100vh - 39px);
        background: white;
    }

    &-review-text {
        font-size: 50px;
        text-align: center;
        padding-top: 200px;
    }

    &-details-table {
        margin: 5px;

        &-container {
            background: white;
            border: 10px solid white;
            border-radius: 20px;
            margin-top: 22px;
        }
        &-header {
            background-color: #E6E9ED;
            border-top-right-radius: 20px;
            border-top-left-radius: 20px;
            padding: 15px;
            text-align: left;
        }
        &-content {
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
            text-align: left;
        }

    }

    &-liveness-details {
        &-label {
            color: #374151;
            font-size: 16px;
            font-weight: 700;
            margin: 10px 0;
        }
        &-value {
            color: #4B5563;
            font-size: 16px;
            font-weight: 600;
        }
    }

    &-screen-btn-container {
        text-align: center;
        margin-top: 40px;

        &-initiate {
            display: inline;
            font-size: 16px;
            padding: 12px;
            height: auto !important;
        }
    }

    &-call-id-details {
        width: 100%;
        padding: 10px;
        display: flex;
        justify-content: end;
        font-weight: 500;
        font-size: 16px;
        position: sticky;
        top: 0;
        background-color: #e7e9ed;
        z-index: 3;
    }
}

.reactEasyCrop_Container {
    height: 100%;
    width: 100%;

    .reactEasyCrop_Contain {
        height: 100%;
        width: 100%;
    }

    .reactEasyCrop_CropAreaGrid {
        display: none;
    }
}
