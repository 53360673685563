@import "position";

.app {
    &-cr { // container
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    &-cr-v2 { // container
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        background-color: #282828;
        padding-top: 50px;
    }
    
    &-cc { // content container
        width: calc(100% - 230px); // side navbar width - 230px
        /* scrollable container */
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100% - 48px);
        overflow: auto;
        position: fixed;
        left: 230px;
        top: 48px; // app header height

        &-v1 {
            width: 100%;
            padding: 0px 30px;
        }
    }

    &-cc-v2 { // content container
        width: calc(100% - 230px); // side navbar width - 230px
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        &-v1 {
            margin: 30px auto;
            width: 1100px;
        }
    }

    &-cc-v3 { // content container v3
        width: 100%;
        height: 100%;
        // 230px is the width of the sidebar
        max-width: calc(100vw - 230px);
        @extend .fccWrapper;
    }
    
    &-ci { // container item
        margin: 40px auto;
        width: 1000px;
    }
    
    &-kv { // key value used in view employment details
        &__cr { // container
            margin: 60px auto 20px !important;
            width: 1000px !important;
        }
    
        &__hd { // heading title
            margin: 0 !important;
            font-size: 22px !important;
            font-weight: 400 !important;
            line-height: 120% !important;
            text-transform: uppercase !important;
        }
    
        &__cl { // content label
            width: 450px !important;
        }
    
        &__cv { // content value
            text-align: right !important;
            width: 550px !important;
        }
    }
    
    &-hl { // heading
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 20px;
        word-break: break-word;
    }
}
