.exe-manda{ // executed mandates
    &-expandable{// expandable card
        max-width: 1000px;
        width: 100%;

        &-sc{// search container
            padding: 10px 15px 20px;

            &__lb{ //label
                font-size: 18px;
                font-weight: 400 !important;
                line-height: 20px !important;
                text-align: left !important;
                width: 160px !important;
            }
            &__ip{ //input
                width: 270px !important;
            }
            &__3r{ 
                margin-top: 20px;
            }
            &__cta{
                width: 200px;
            }
            &__clear{
                margin-left: 60px;
            }
        }
        &-form-container{
            margin: 0 15px;
            row-gap: 20px;
        }
        
    }
    &-el { // err label
        margin: 5px 0 10px;
    }
}