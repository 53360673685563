.ii-update-url { // update url screen
    margin: 0 auto;
    max-width: 700px;

    &-fc { // form container
        margin: 0 0 30px;


        &__lb { // to override form css of higher specificity
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            margin-right: 0 !important;
            text-align: left !important;
            width: 300px !important;
            white-space: pre-wrap;
        }

        &__ip {
            margin-right: 0 !important;
            width: 400px !important;
        }

        &__desc {
            color: #8D8D8D;
            font-size: 14px;
            margin-top: 2px;
        }

        &__err {
            margin-left: 0px !important;
        }
    }

    &-cta {
        margin: 60px 0 0 300px;
        width: 400px;
    }

    &-el { // err label
        font-size: 13px;
        font-weight: normal;
        margin-left: 300px;
    }

    &-dd {
        background-color: #FFFFFF;
        margin-left: 0;
        width: 400px;

        &__cc {
            width: 400px;
        }
    }

    &-link {
        margin: auto 0 auto auto;
    }

    &-label {
        font-size: 18px;
        font-weight: 400;
    }

    &-modal {
        &__hl {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 20px;
            word-break: break-word;
        }

        &__cta {
            margin: 20px auto 0;
        }
    }

    &-table-sr { // updated url info table
        margin-top: 40px;

        &__thw {
            background-color: #FFFFFF;
        }

        &__tlc {
            background-color: #FFFFFF;
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }

    &-urlinfo {
        &-hwr { // heading wrapper
            display: flex;
            margin-bottom: 40px;

            &__hd {
                font-size: 18px;
                font-weight: 400;
                margin: 0 auto;
                text-transform: uppercase;
            }
        }
    }
}
