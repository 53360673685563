.fmreviewV2 { // v2 facematch review
    &-hl { // heading label
        &1 {
            font-size: 16px;
            text-transform: capitalize;
            margin-bottom: 10px;
        }
    }

    &-img { // image
        &__cr { // container
            display: flex;
            justify-content: space-between;
            text-align: center;
            margin: 30px 0;
        }

        &__itm { // item
            &1 { // left side img
                margin-right: 20px;
            }

            &2 { // right side img
                margin-left: 20px;
            }
            
        }
    }
}
