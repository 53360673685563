.fdags { // federal agents
    border-top: 1px solid #cccccc;
    display: flex;
    flex-direction: column;
    margin: 0 auto 40px;
    max-height: calc(100% - 88px); // 90px = 48px(header), 40px(margin)
    width: 100%;

    &--tktinfo {
        display: flex;
        flex-direction: column;
        margin: 40px auto 60px;
        overflow: scroll;
        max-height: unset; // 90px = 50px(header), 40px(margin)
    }

    &-nosr { // no search results
        font-size: 20px;
        margin: 80px auto 0;
        width: 200px;
    }

    &-table {
        max-height: calc(100% - 40px); // 40px(pagination)
        overflow-y: scroll;

        &__thw {
            position: fixed;
            width: 100%;
        }

        &__tlc {
            margin-top: 40px;
            max-height: calc(100% - 40px); // 40px(table header)
        }
    }

    &-tktinfo { // ticket info
        height: 100%;
        overflow: scroll;
        width: 100%;

        &-cr {
            display: flex;
            flex-direction: column;
            margin: 40px auto 0;
            max-width: 800px;
            width: 100%;
        }

        &-hw {
            display: flex;
            margin-bottom: 40px;

            &__hd {
                font-size: 18px;
                font-weight: 400;
                margin: 0 auto;
                text-transform: uppercase;
            }
        }

        &-cwr {
            display: flex;
            margin-bottom: 20px;

            &__cl {
                font-size: 16px;
                width: 200px;
            }

            &__sp {
                width: 50px;
            }

            &__cv {
                font-size: 16px;
                text-align: left;
                word-break: break-word;
                width: 450px;

                &--sp {
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &-rfc { // reason form container
            display: flex;
            margin: 20px 0 40px;
    
            // overriding css of higher specificity from common component
            &__lb {
                width: 200px !important;
                margin-right: 50px !important;
                text-align: left !important;
            }
    
            &__ta {
                width: 300px;
            }
        }

        &-flb { // form label
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            width: 200px;
            margin-right: 50px;
        }

        &-cta {
            margin: 100px 0 300px 250px;
            width: 300px;
        }

        &-errlb {
            margin: 5px 0 10px;
        }
    }

    &-dd { // dropdown
        margin-left: 0;
        width: 300px;

        &__cc {
            position: relative;
            width: 300px;
            margin-top: 16px;

            &--ac {
                height: 250px;
                overflow: scroll;
            }
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }
}
