.vnb { // vertical navbar
    &-cr { // container
        background-color: #ffffff;
        padding: 15px 0;
        width: 230px;
        /* scrollable menu */
        height: calc(100% - 92px);
        overflow: auto;
        position: fixed;
        top: 92px; // app header + horizontal id navbar height

        &--without-hz-bar { // without horizontal id navbar
            height: calc(100% - 48px);
            top: 48px; // app header height
        }

        &-v2 { // container
            flex-shrink: 0;
            background-color: #ffffff;
            padding: 15px 0;
            width: 230px;
            height: 100%;
            overflow: auto;
            top: 92px; // app header + horizontal id navbar height
    
            &--without-hz-bar { // without horizontal id navbar
                height: 100%;
                top: 48px; // app header height
            }
        }
    }

    &-wr { // wrapper
        &__itm { // item
            color: #8D8D8D;
            font-family: Figtree;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            letter-spacing: 0.45px;
            text-transform: uppercase;
            padding: 15px 0 15px 20px;
            width: 100%;

            &:hover {
                color: #00B899;
                cursor: pointer;
            }

            &--active {
                color: #00B899;
            }
        
            &--disabled {
                color: #8D8D8D !important;
                cursor: not-allowed !important;
                opacity: 0.6;
            }
        }

        &__sub-itm { // nested item
            padding-left: 40px;
        }
    }

    &-chevron {
        margin-left: 4px;
        height: 16px;
        width: 16px;
    }

    &-collapsed-chevron {
        @extend .vnb-chevron;
        transform: rotate(180deg);
    }

    &-expanded-chevron {
        @extend .vnb-chevron;
        transform: rotate(0deg);
    }
    
    &-expandable { // expandable navigation
        position: relative;
        top: 50%;

        &-container { // expandable navigation container
            position: absolute;
            top: 50%;
            width: 20px;
            height: 100px;
            right: -18px;
            background: #ffffff;
            border-radius: 5px;
            border: 1px solid #00000014;
            border-left: none;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }

        &-image {
            width: 18px;
        }
    }

    &-expand {
        margin-left: 0px;
    }

    &-nexpand {
        margin-left: -230px;
    }
}
