/*
 * LogsInfo Styles
 */
 
@import '../../stylesheets/dropdown.scss';
@import '../../stylesheets/colors';

.logs {
    &-container {
        margin: 0 auto;
        max-width: 1100px;
        width: 100%;
    }

    &-heading {
        font-size: 20px;
        font-weight: 600;
        margin-top: 40px;
    }
}

.logslist {
    &-heading {
        font-size: 20px;
        font-weight: 600;
        margin: 40px 0 20px;
    }

    &-sc { // search container
        padding: 10px 15px 20px;

        &__3r { // 3rd row
            margin-top: 20px;
        }

        &__cta {
            margin-top: 30px;
            width: 200px;
        }

        input {
            width:100%;
            max-width: 210px;
            margin-left: 20px;
        }
    }
    &-wrapper {
        width: 50%;
    }

    &-custom-dd {
        &__cc {
            @extend .custom-dd__cc;
            margin-left: 21px;
            margin-top: 0px;
            
            &--aai { // active action/item
                height: 220px;
                overflow-y: scroll;
            }

            &--as { // active status
                height: 137px;
                overflow-y: scroll;
            }
        }
    }

    &-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: left !important;
        width: 170px !important;
    }

    &-src { // search results container
        margin-top: 100px;
    }

    &-ec { // expandable card
        position: absolute;
        width: 100%;
        max-width: 1100px;
        z-index: 1;
    }

    &-err {
        color: $color-vermilion;
        font-size: 14px;
        width: 100%;
        padding-top: 20px;
    }
}

.logdetails {
    &-hw { // heading wrapper
        margin: 40px 0 20px;

        &__hl { // heading label
            font-size: 20px;
            font-weight: 600;
        } 
    }

    &-cc { // content container
        max-width: 900px;

        &-wr { // wrapper
            margin-bottom: 20px;
        }

        &__hl { // heading label
            font-size: 16px;
            text-transform: capitalize;
            width: 200px;
        }

        &__sp { // separator
            width: 50px;
        }

        &__cl { // content label
            font-size: 16px;
            text-align: left;
            text-transform: capitalize;
        }
    }
}
