.show-info-container{
    .row-container {
        align-items: baseline;
        display: flex;
        margin-bottom: 20px;

        .label {
            font-size: 16px;
            margin-right: 10px;
        }

        .value {
            font-size: 18px;
            font-weight: 400;
        }
    }
}
