@import '~react-toastify/dist/ReactToastify.css';

body {
    .toast-container {
        top: 4rem;
    }

    .toast-notification {
        border-radius: 4px;
        box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
        margin-bottom: 0.7rem;
        min-height: 40px;
        color: white;
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        padding-left: 40px;

        .toast-content {
            word-break: break-word;
            width: 247px;
            display: inline-block;
        }

        i {
            display: inline-block;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            line-height: 20px;
            margin-right: 10px;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
        }

        button.close {
            background-color: transparent;
            border: none;
            color: white;
            cursor: pointer;
            font-size: 20px;
            margin-bottom: 2px;
            padding: 0;
        }

        .clearAll {
            display: none;
            position: absolute;
            top: 110%;
            right: 0;
            background-color: white;
            color: #212529;
            border: 1px solid #ced4da;
            padding: 3px 15px;
            line-height: 14px;
            height: 30px;
            text-align: center;
            cursor: pointer;
            border-radius: 4px;
            font-size: 14px;
            font-weight: bold;
            box-shadow: 0 0 5px 0 #ccccccbf;
        }
        .clearAll:hover {
            background-color: #f8f9fa;
            border-color: #ced4da;
        }
    }

    .toast-success {
        i {
            background-color: #307647;
        }
        background-color: #57a671;
    }

    .toast-error {
        i {
            background-color: #c74747;
        }
        background-color: #f56060;
    }

    .toast-warning {
        i {
            background-color: #c89e21;
        }
        background-color: #ebbd36;
    }

    .toast-notification:nth-last-child(1) {
        overflow: visible;
        .clearAll {
            display: block;
        }
    }

    .toast-notification:nth-child(1) {
        overflow: hidden;
        .clearAll {
            display: none;
        }
    }
}
