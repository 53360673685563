@font-face {
    font-family: 'Figtree';
    font-display: block;
    font-stretch: normal;
    font-style: normal;
    font-weight: 200;

    src: url('../assets/fonts/Figtree-Regular.woff2') format('woff2'),
        url('../assets/fonts/Figtree-Regular.woff') format('woff'),
        url('../assets/fonts/Figtree-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree';
    font-display: block;
    font-stretch: normal;
    font-style: normal;
    // font-weight: 500; // web value
    font-weight: 300;

    src: url('../assets/fonts/Figtree-Medium.woff2') format('woff2'),
        url('../assets/fonts/Figtree-Medium.woff') format('woff'),
        url('../assets/fonts/Figtree-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree';
    font-display: block;
    font-stretch: normal;
    font-style: normal;
    // font-weight: 600; // web value
    font-weight: 400;

    src: url('../assets/fonts/Figtree-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/Figtree-SemiBold.woff') format('woff'),
        url('../assets/fonts/Figtree-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Figtree';
    font-display: block;
    font-stretch: normal;
    font-style: normal;
    // font-weight: 700; // web value
    font-weight: 600;

    src: url('../assets/fonts/Figtree-Bold.woff2') format('woff2'),
        url('../assets/fonts/Figtree-Bold.woff') format('woff'),
        url('../assets/fonts/Figtree-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-display: block;
    font-stretch: normal;
    font-style: normal;
    font-weight: 400;

    src: url('../assets/fonts/Inter-Regular.woff2') format('woff2'),
        url('../assets/fonts/Inter-Regular.woff') format('woff'),
        url('../assets/fonts/Inter-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-display: block;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;

    src: url('../assets/fonts/Inter-Medium.woff2') format('woff2'),
        url('../assets/fonts/Inter-Medium.woff') format('woff'),
        url('../assets/fonts/Inter-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Inter';
    font-display: block;
    font-stretch: normal;
    font-style: normal;
    font-weight: 700;

    src: url('../assets/fonts/Inter-Bold.woff2') format('woff2'),
        url('../assets/fonts/Inter-Bold.woff') format('woff'),
        url('../assets/fonts/Inter-Bold.ttf') format('truetype');
}
