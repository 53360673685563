.uss { // us stocks
    margin: 0 auto;
    max-width: 1000px;
    position: relative;
    width: 100%;

    &-cr { // main container
        margin-bottom: 80px;
        position: relative;
        z-index: 1;
    }

    &-datetime{
        margin-bottom: 200px;
    }

    &-sc { // search container
        padding: 10px 15px 20px;

        &--v1 {
            padding: 25px 15px;
        }

        &__gap {
            row-gap: 20px;
        }

        &__3r { // 3rd row
            margin-top: 20px;
        }

        &__cta {
            width: 200px;
        }

        &__clear {
            margin-left: 60px;
        }
    }

    &-el { // err label
        margin: 5px 0 10px;
    }

    &-fc { // form container
        margin: 0;

        &__lb { // to override form css of higher specificity
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            // margin-right: 0 !important;
            text-align: left !important;
            width: 160px !important;
        }

        &__ip {
            width: 270px !important;
        }

        &__err {
            margin-left: 0px !important;
        }
    }

    &-dd { // dropdown container
        margin-right: 30px;
        width: 270px;

        &__cc { // content container
            width: 270px;
        }

        &__lb { // to override form css of higher specificity
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            // margin-right: 0 !important;
            text-align: left !important;
            width: 160px !important;
        }

        &__at {
            margin-right: 59px;
        }

        &__ip {
            width: 270px !important;
        }

        &__vl { // value wrapper
            display: flex;
            justify-content: space-between;
        }

        &__clear { // clear selection cross icon
            background-color: #dddddd;
            border-radius: 10px;
            color: #666666;
            font-size: 13px;
            width: 19px;
            height: 19px;
            padding-left: 6px;
            text-transform: lowercase;
        }
    }

    &-src { // search results container
        padding-top: 66px;
    }

    &-src1 { // search results container
        padding-top: 30px;
    }

    &-modal {
        &-mfoicr { // mutual fund order info container
            overflow-y: scroll;
        }

        &-cta {
            margin: 20px auto 0;
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }

    &-activities-list {
        padding: 50px 0 0;
    }

    &-userinfo{
        &-hwr { // heading wrapper
            display: flex;

            &__hd {
                font-size: 18px;
                font-weight: 400;
                margin: 0 auto;
                text-transform: uppercase;
            }
        }
    }

    &-hl { // heading label
        &--cr {
            display: flex;
        }

        &-main-heading {
            font-size: 20px;
            font-weight: 400;
            text-transform: capitalize;
        }

        &-image-heading {
            font-size: 16px;
            text-transform: capitalize;
            margin-bottom: 10px;
        }
    }

    &-atdd{
        margin: 0px !important;
    }

    &-fcwsd {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: fit-content;
    }

    &-left{
        text-align: left;
    }

    &-expandable-card {
        max-width: 100%;
    }
}
