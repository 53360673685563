.login {
    background-color: #f5f5f5;
    margin: 100px auto 0;
    height: fit-content;

    &-cr1 { // container - login
        padding: 0 50px;
        width: 800px;
    }

    &-cr2 { // container - new password
        width: 900px;
    }
    
    border-radius: 10px;

    &-header {
        font-size: 24px;
        font-weight: 400;
    }

    &-err {
        font-size: 16px;
        margin: 40px auto;
    }

    &-item {
        margin: 35px auto;

        &--fit {
            margin: 35px auto;
            width: fit-content;
        }
    }

    &-btn-ctnr {
        display: flex;
        justify-content: center;
    }

    &-btn {
        margin: 10px 20px;
    }

    &-backarrow {
        cursor: pointer;
    }

    &-shadow-effect {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
    
    &-or {
        display: flex;
        justify-content: center;
        align-items: center;
        color: grey;
    }
    
    &-or:after,
    &-or:before {
        content: "";
        display: block;
        background: grey;
        width: 30%;
        height: 1px;
        margin: 0 10px;
    }

    &-ff { // form fields
        &-cr { // container
            margin: 25px auto;
            width: 70%;
        }

        &-lb { // label - login
            margin-right: 50px !important;
            width: 250px !important;
        }

        &-lb2 { // label - new password
            margin-right: 50px !important;
            width: 450px !important;
            max-width: 450px !important;
        }

        &-errlb { // err label
            margin-left: 300px !important;
        }

        &-errlb2 { // err label
            margin-left: 502px !important;
            margin-top: 7px !important;
        }

        &-ip1 { // input - login
            width: 250px !important;
        }

        &-ip { // input - new password
            width: 330px !important;
        }
    }

    &-btn__center {
        text-align: center;
        width: 100%;
    }

    &-secondary {
        color: dimgrey;
        text-align: center;
        width: 100%;
        margin-top: 20px;
    }
}
