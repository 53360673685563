.loader {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #fc5847;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;

    &-text {
        font-weight: 800;
        text-align: center;
    }
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.custom-loader {
    width: 100%;
    height: 100%;
    background: #0000004d;
    position: absolute;
    z-index: 1040;

    &-label {
        top: 50%;
        left: 50%;
        position: absolute;
    }

    &-text {
        font-size: 15px;
        color: #fff;
        text-align: center;
    }
}
