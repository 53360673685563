.linked-in-auth-panel {
    &-notification-container {
        position: relative;
        color: #eae5d3;
        background-color: #474746;
        border: 1px solid #997404;
        padding: 5px;
        font-family: sans-serif;
        font-weight: 600;
        margin-bottom: 10px;
        font-size: 11px;
        margin-right: 20px;
        margin-left: 20px;

        &-remove-btn{
            float: right;
            cursor: pointer;
        }
    }
    &-container {
        position: relative;
        color: #eae5d3;
        background-color: #474746;
        border: 1px solid #997404;
        padding: 17px;
        font-family: sans-serif;
        font-weight: 600;
    }
    &-link {
        border-bottom: 1px solid #0b0b30;
        color: #0b0b30;
        cursor: pointer;
    }
    &-copy-section {
        display: inline-block;
        margin: 0px;
        padding: 4px;
        background: #271616;
        border-radius: 11px;
        padding-left: 14px;
        padding-right: 14px;
    }

    &-copy-section button {
        min-width: auto !important;
    }


    &-list li {
        margin-top: 10px;
        margin-bottom: 9px;
    }

    &-expand-card {
        min-width: 95%;
        min-height: auto;
        margin: 0px;
        border-radius: 10px;
    }

    &-expand-card button{
        min-width: 100%;
    }
    

    &-expand-card button .expandablecard-heading {
        width: 100%;
        display: flex;
        font-size: 14px;
        font-weight: 400;
        justify-content: space-between;
        padding: 7px 11px;
        cursor: pointer;
        background: #87876f;
        border-radius: 0px;
        color: white;
        font-family: sans-serif;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: 600;
    }
 }



 .linked-in-auth-panel-copy-section button .copy-text-icon {
    cursor: pointer;
    height: 15px;
    margin-left: 8px;
    width: 15px;
    vertical-align: bottom;
 }
 