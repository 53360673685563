.kvpair-info {
    &-cr { // container
        &-v1 {
            margin: 40px auto 100px;
            width: 700px;
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }

    &-hwr { // heading wrapper
        &__hd {
            font-size: 24px;
            font-weight: 400;
            line-height: 120%;
            margin: 0 auto;
            text-transform: uppercase;
        }

        &-v1 {
            align-items: center;
            display: flex;
            margin-bottom: 40px;
            &__hd {
                @extend .kvpair-info-hwr__hd;
            }
        }

        &-v2 {
            margin: 40px 0px;
            width: 700px;
            &__hd {
                @extend .kvpair-info-hwr__hd;
                text-align: center;
                width: 400px;
            }
        }
    }

    &-cwr { // content wrapper
        align-items: center;
        display: flex;
        margin-bottom: 24px;
        width: 100%;
        justify-content: center;

        &__cl { // content label            
            color: #8D8D8D;
            font-weight: 400;
            font-size: 18px;
            line-height: 110%;
            letter-spacing: 0.45px;
            margin-right: 50px;
            text-transform: capitalize;
            width: 200px;
            word-break: break-word;
        }

        &__cv { // content value
            color: #282828;
            font-family: Inter, sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 140%;
            text-align: left;
            width: 450px;
            word-break: break-word;

            > a { // text color of a tags should be 
                color: #00B899;
            }
        }
    }

    &-cwrl {
        justify-content: left;
    } 
}
