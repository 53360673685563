.user-details {
    margin: 40px auto 80px;
    max-width: 900px;
    width: 100%;

    &-fc { // form input container

        &1 { // for dropdown input
            @extend .user-details-fc;
            display: flex;
            flex-direction: row;
            align-items: center;

            &_1 {
                width: 90px;
            }
        }

        &2 { // for text input
            @extend .user-details-fc;
            margin: 0;
        }

        &__lb { // form input label
            width: 200px !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            margin-right: 20px !important;
            text-align: left !important;
        }

        &__ip { // form input field
            width: 300px !important;
            margin: 0 !important;
        }
    }

    &-elb { // error label
        margin: 10px 0;
        font-size: 14px;
    }
}
