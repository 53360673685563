.upi {
    &-container {
        margin: 0 auto;
        max-width: 1000px;
        width: 100%;
    }

    &-heading {
        font-size: 20px;
        font-weight: 600;
    }

    &-no-search-results {
        font-size: 20px;
        margin-top: 40px;
        text-align: center;
    }

    &-search-results {
        margin-top: 40px;

        &__label {
            font-size: 16px;
            margin-bottom: 10px;
        }

        &__theader-wrapper {
            background-color: #f5f5f5;
            border-bottom: 1px solid #eee;
            display: flex;
            padding: 10px;
            text-align: center;
        }

        &__theader {
            flex: 1;
            font-size: 16px;
            font-weight: 400;

            &--big {
                flex: 2;
            }
        }

        &__tlabel-container {
            background-color: #fafafa;
            display: flex;
            flex-direction: column;
            max-height: 270px;
            overflow: scroll;
            padding: 0 10px;
        }
    
        &__tlabel-wrapper {
            display: flex;
            padding: 10px 0 10px;
            text-align: center;
        }

        &__tlabel {
            flex: 1;
            font-size: 14px;
            word-break: break-word;

            &--big {
                flex: 2;
            }
        }
    }

    // confirm screen container
    &-cc {
        &__heading-wrapper {
            display: flex;
            margin-bottom: 40px;
        }

        &__heading {
            font-size: 20px;
            font-weight: 600;
            margin: 0 auto;
        }

        &__subheading {
            font-size: 16px;
            margin-bottom: 40px;
            text-align: center;
        }

        &__ctas-wrapper {
            display: flex;
            justify-content: space-evenly;
        }

        &__ctas {
            width: 200px;
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }

    &-modal {
        &-hwr {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 20px;
        }

        &-bwr {
            margin: 32px auto 0;
            width: 100px;
        }
    }

    &-ad { // actions dialog
        &-label {
            cursor: pointer;
            font-size: 16px;
            font-weight: 400;
        }
    
        &-cc { // content container
            margin-left: -135px;
            margin-top: 2px;
            width: 180px;

            &-wr {
                padding: 10px 0;
                text-align: left;

                &__label {
                    cursor: pointer;
                    padding: 5px 15px;
    
                    &:hover {
                        background-color: #f5f5f5;
                    }
                }
            }

            /**
            * Apply !important to height for the active state.
            * This is a quick fix for the issue where the height is not being applied on button click.
            * TODO: Need to check why height is not applied on ellipsis to view details on prod env [ANKIT] [MONORAIL] [https://monorail.pointz.in/p/fi-app/issues/detail?id=53491]
            */
            &--active {
                height: 72px !important;
            }
        }
    }
}
