.kycdoc-ip { // kyc docket filter input
    &-cr {
        margin: 20px 0;
    }

    &-fc { // form input container
        width: 100% !important;

        &1 { // for dropdown input
            @extend .kycdoc-ip-fc;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &2 { // for text input
            @extend .kycdoc-ip-fc;
            margin: 0;
        }

        &__lb { // form input label
            width: 200px !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            margin-right: 20px !important;
            text-align: left !important;
        }

        &__ip { // form input field
            width: 400px !important;
            margin: 0 !important;
        }
    }

    &-elb { // error label
        margin: 10px 0;
        font-size: 14px;
    }
}
