@import '../../stylesheets/colors';

.tableV1 {
    &-column_one {
        border: 1px solid $color-grey;
        flex: 1 1;
        padding: 10px;
    }
    &-column_two {
        border: 1px solid $color-grey;
        flex: 2 1;
        padding: 10px 20px;
    }
}
