.accops { // account ops
    &-cr { // container
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    &-cc { // content container
        width: calc(100% - 230px); // side navbar width - 230px
        /* scrollable container */
        display: flex;
        flex-direction: column;
        height: calc(100% - 92px);
        overflow: auto;
        position: fixed;
        left: 230px;
        top: 92px;
    }
}
