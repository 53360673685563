.scripts {
    min-height: 100%;
    background-color: #E6E9ED;

    .line {
        border-bottom: 1px solid #E6E9ED;
    }

    &-box {
        height: 178px;
        box-shadow: 0px 4px 0px 0px #0000001A;
        &__v2 {
            @extend .scripts-box;
            height: 360px;
            margin-right: 10px;
        }
    }

    &-cr { // container
        height: 100%;
        padding: 20px;
    }

    &-arrow {
        width: 16px;
        transform: rotate(180deg);
    }

    &-cont { // content
        margin: 30px;
        padding: 20px 40px;
        border-radius: 20px;
        background-color: #F6F9FD;

        &__v2 {
            @extend .scripts-cont;
            background-color: unset;
            padding: unset;
        }
    }

    &-title {
        padding: 20px 0;
        font-size: 1.75rem;
        font-weight: 600;
    }

    &-des {
        font-style: italic;
        font-weight: 200;
        font-size: 14px;
    }

    &-heading {
        margin: 12px 0;
        font-weight: 400;
        font-size: 1rem;
    }

    &-cc { // content container
        border: 1px solid #E6E9ED;
        background-color: #FFFFFF;
        border-radius: 20px;
    }

    &-text {
        margin: 40px 0 20px;
        font-size: 1.25rem;
        font-weight: 400;

        &-left {
            text-align: left !important;
        }
    }

    &-card {
        border-radius: 20px;
        background-color: #F6F9FD;
        padding: 20px;
        margin: 10px;
        width: 164px;
        box-shadow: 0px 4px 0px 0px #0000001A;
    }


    &-pcs {
        margin: 20px 0 40px;

        &-title {
            width: 200px;
        }

        &-values {
            width: 100%;
        }

        &-cell {
            padding: 20px;
            flex: 33%;
            // border-bottom: 1px solid #E6E9ED;

            &-title {
                color: #6B7280;
                font-weight: 600;
                padding: 5px 0;
            }

            &-value {
                color: #1F2937;
                font-weight: 600;
                font-size: 1rem;
                padding: 1px 0;
            }
        }
    }

    &-guides {
        margin: 20px 0;

        &-title {
            padding: 20px;
            font-size: 1.25rem;
            font-weight: 500;
        }

        &-cc {
            padding: 20px 0;
            width: 100%;
        }

        &-cont { // content
            border-radius: 0 16px 16px 0;
            &__active {
                background-color: #F6F9FD !important;
            }

            &:hover {
                background-color: #fbfbfd;
            }
        }

        &-text {
            color: #4B5563;
            padding: 20px;
            font-size: 1.12rem;

            & > * {
                margin: 0 !important;
            }
        }

        &-img {
            border: 2px solid #E6E9ED;
            padding: 10px;
            margin: 10px 30px ;
            min-width: 30%;
            max-width: 30%;
        }

        &-tip {
            visibility: hidden;
            position: relative;
            display: inline-block;

            &-text {
                right: 20%;
                visibility: hidden;
                position: absolute;
                width: max-content;
                background-color: #555;
                color: #fff;
                text-align: center;
                padding: 5px;
                border-radius: 6px;
                z-index: 1;
                opacity: 0;
                transition: opacity .6s;

                &::after {
                    content: "";
                    position: absolute;
                    bottom: 100%;
                    right: 5%;
                    margin-left: -5px;
                    border-width: 5px;
                    border-style: solid;
                    border-color: transparent transparent #555 transparent;
                }
            }
        }
    }

    &-search {
        &-heading {
            flex: 1;
        }

        &-dd {
            flex: 2;
            margin: 0 10px
        }

        &-tbl { //table
            margin: 20px 0 !important;
            overflow: hidden;

            &-header {
                padding: 20px 30px !important;
                color: #9CA3AF;
            }

            &-cont {
                padding: 20px !important;
                max-height: 700px !important;
            }

            &-row {
                padding: 0 10px !important;
                font-weight: 500;
                justify-content: center;

                &:hover {
                    background-color: #ebeef2;
                    border-radius: 10px;
                }
            }
        }

        &-val {
            background-color: #F3F4F6;
            padding: 10px;
            margin: 0 5px;
            border-radius: 10px;
            word-wrap: break-word;
        }
    }
}
.scripts-guides-cont__active .scripts-guides-tip {
    visibility: visible;
}

.scripts-guides-tip:hover .scripts-guides-tip-text {
    visibility: visible;
    opacity: 1;
}
