.user-cb {
    &-container {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 600px;
        width: 100%;
    }

    &-heading {
        font-size: 18px;
        font-weight: 400;
        margin: 0 auto 40px;
        text-align: center;
        width: 385px;
    }

    &-pcta {
        display: flex;
        justify-content: space-around;
        margin-bottom: 80px;
    }

    &-subheading {
        margin: 0 auto 30px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        width: 350px;
    }

    &-scta {
        margin: 0 auto 40px;
    }

    &-link {
        margin-top: 10px;
        text-align: center;
    }
}
