.form-group.name {
    .input-wrapper {
        display: flex;

        .form-control {
            margin-right: 15px;
            width: 190px;

            &.last-name {
                margin-right: 0px;
            }
        }
    }
}