.payouts {
    &-container {
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    &-src { // search results container
        max-width: 1300px;
        width: 100%;
        margin: 20px auto 110px;
    }

    &-heading {
        font-size: 20px;
        font-weight: 600;
        margin-top: 40px;
    }

    &-mr40 {
        margin-right: 40px;
    }

    &-modal {
        &__hl {
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 60px;
            text-align: center;
        }

        &__ctas { // ctas wrapper
            margin-top: 40px;
            justify-content: space-around;
            width: 100%;
        }

        &-ff { // form fields
            &__cr { // container
                display: flex;
                width: 100%;
            }

            &__lb { // label
                text-align: left !important;
                width: 200px !important;
            }

            &__ta { // text-area
                width: 300px;
            }
        }
    }
}
