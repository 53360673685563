.kob {
    margin: 0 auto 40px;
    max-width: 1000px;
    width: 100%;

    &-wrapper {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-bottom: 20px;

        &__label {
            font-size: 16px;
            width: 280px;
        }

        &__label--v2 {
            @extend .kob-wrapper__label;
            font-weight: 400;
            width:fit-content;
            margin-right: 5px;
        }

        &__value {
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;
            width: 200px;

            &--v1 {
                text-transform: none;
            }
            &--v2 {
                @extend .kob-wrapper__value;
                text-transform: none;
                width: 700px;
                ol {
                    padding-left: 1em;
                }
            }

            &--v3 {
                @extend .kob-wrapper__value;
                font-weight: unset;
                text-transform: none;
                width: max-content;
                line-height: 25px;
            }
        }

        &__cta {
            margin-left: 40px;
            width: fit-content;
        }

        &--v2 {
            @extend .kob-wrapper;
            align-items: start;
        }

        &--v3 {
            @extend .kob-wrapper;
            flex-wrap: wrap;
        }

        &_li {
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;
            width: 100%;
        }
    }

    &-err {
        font-size: 16px;
        margin-top: 40px;
    }

    &-modal { // content container
        &-hwr { // heading wrapper
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 20px;

            &--kyc {
                margin-bottom: 0;
            }
        }

        &-ccr { // content container
            max-height: 580px;
            overflow: scroll;
        }

        &-ccr2 { // content container
            max-height: 450px;
            overflow: scroll;
            white-space: pre-wrap;
        }

        &-cwr { // content wrapper
            border-bottom: 1px solid #eee;
            display: flex;
            align-items: center;
            padding: 6px 0;

            &--curr-stage {
                border-bottom: 0;
                padding: 8px 0;
            }

            &__cl { // content label
                font-size: 16px;
                text-transform: capitalize;
                width: 300px;

                &--v2 {
                    width: 150px;
                }
            }
    
            &__sp { // separator
                width: 50px;
            }
    
            &__cv { // content value
                font-size: 16px;
                text-align: left;
                word-break: break-word;
                width: 470px;
                white-space: pre-wrap;

                &--v2 {
                    width: 400px;
                }

                &--r2 { // row 2
                    margin-top: 4px;
                }

                &--ml20 {
                    margin-left: 20px;
                }
            }
        }

        &-bwr {
            margin: 30px auto 0;
            width: 100px;
        }

        &-sicr {
            align-items: center;
            border: 1px solid #333333;
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-siwr {
            border-right: 1px solid #333333;
            flex: 1;
            padding: 6px 0;

            &--v1 {
                border-right: 0;
                flex: 2;
            }
        }

        &-tlw {
            align-items: center;
        }

        &-siwr2 { // sub item wrapper
            border-bottom: 1px solid #ccc;
            padding: 5px 0;

            &:last-child {
                border-bottom: 0;
            }
        }

        &-amiwr { // array meta info wrapper
            margin: 8px 0;

            &__sp { // separator
                margin: 0 12px 0 8px;
            }
        }

        &-curr-stage {
            max-height: 450px;
            overflow: scroll;
        }

        &-link {
            a {
                color: blue;
                text-decoration: underline;
            }
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
        position: absolute;
    }

    &-hw { // heading wrapper
        display: flex;
        margin-bottom: 24px;

        &__hd {
            font-size: 18px;
            font-weight: 400;
            margin: 0 auto;
            text-transform: capitalize;
        }
    }

    &-onbtable { // onb table
        max-height: 250px;
    }

    &-onbtr { // onb troubleshoot
        margin-bottom: 100px;

        &__desc {
            font-size: 16px;
            font-weight: 400;
            margin: 40px auto 20px;
            width: 700px;
        }

        &__tlc {
            max-height: 400px;
        }
    }

    &-kycattempt {
        &__cta {
            margin: 40px auto 60px;
            width: 200px;
        }
    }

    &-vkyc {
        &-table-sr {
            margin-top: 40px;
        }
    }
}
