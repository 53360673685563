.custom-dd {
    border: 1px solid #AFB0B9;
    border-radius: 6px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 200;
    height: auto;
    margin-left: 20px;
    padding: 8px;
    text-transform: capitalize;
    width: 210px;

    &__cc { // content container
        margin-left: -8px;
        width: 210px;

        &--ac { // active
            height: 98px;
            overflow-y: auto;
        }

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 10px;
            height: 2px;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            border: 2px solid white; /* should match background, can't be transparent */
            background-color: #cccccc;
        }
    }

    &__cl { // content label
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        padding: 10px;
        word-break: break-word;
        width: 100%;
        text-align: left;

        &:hover {
            background-color: #f5f5f5;
        }

        &:first-of-type {
            margin-top: 10px;
        }

        &:last-of-type {
            margin-bottom: 10px;
        }
    }
}
