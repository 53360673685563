.forex-txn {
    &-heading-container {
        display: flex;
        align-items: center;
    }

    &-heading {
        font-size: 20px;
        font-weight: 600;
        width: 170px;
    }

    &-fc {

        margin-top: 40px;

        &--m0 {
            margin: 0 auto;
            max-width: 1100px;
        }

        &__lb {
            max-width: 100px !important;
            text-align: left !important;
        }
    }

    &-table-container {
        margin: 20px 0 100px;

        .table-sr__thw--v2 {
            background-color: #F5F5F5;
        }
    
        .table-sr__tlc--v2 {
            background-color: #FAFAFA;
        }

    }

}
