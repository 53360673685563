.adminactions { // admin actions
    &-cr { // container
        margin: 40px auto 80px;
        max-width: 900px;
        width: 100%;
    }

    &-tab {
        // align-items: center;
        background-color: #D6FFC2;
        border-radius: 6px 6px 0px 0px;
        display: flex;
        font-size: 16px;
        padding: 8px 12px;
        position: fixed;
        top: 50px;

        &-lb { // label
            font-weight: 400;
        }

        &-cb { // close btn
            cursor: pointer;
            margin-left: 16px;
        }
    }

    &-ec { // expandable card
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &-sc { // search container
        padding: 10px 15px 20px;

        &-rw1 { // row 1
            margin-bottom: 20px;
        }

        &-ocr { // options container
            display: flex;
            flex-direction: column;

            &__hl {
                border-bottom: 1px solid;
                font-size: 16px;
                padding-bottom: 2px;
                width: fit-content;
            }
        }

        &-owr { // options wrapper
            align-items: center;
            display: flex;
            margin: 10px 0;

            &__ol { // option label
                font-size: 16px;
                width: 300px;
            }

            &__ov { // option value
                // min-width: 210px;
            }
        }

        &-cta {
            margin-top: 25px;
            width: 200px;
    
            &--err {
                margin-top: 0;
            }
        }
    }

    &-fc { // form container
        &--m0 {
            margin: 0;
        }

        // overriding css of higher specificity from common component
        &__ip {
            width: 400px !important;
        }
    }

    &-ffl { // form field label
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;

        &--el {
            margin-left: 60px;
        }
    }

    &-el { // err label
        margin-bottom: 10px;
    }

    &-fuel { // file upload err label
        margin-top: 4px;
    }

    &-crel { // container err label
        margin-top: 80px;
    }

    &-src { // search results container
        font-size: 16px;
        margin-top: 80px;
        white-space: pre;
    }

    &-modal {
        &__hl {
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 30px;
            text-align: center;
        }

        &__sbhl {
            font-size: 16px;
            margin-bottom: 60px;
            text-align: center;
        }

        &__ctas { // ctas wrapper
            justify-content: space-around;
            width: 100%;
        }
    }

    &-dd {
        width: 400px;

        &--ml0 {
            margin-left: 0;
        }

        &__cc {
            width: 400px;
        }
    }
}
