.trv2 { // transction view v2
    &-c{ // container
        height:100%;
        width: 100%;
        padding: 0px 32px;
        max-height: 100vh;
        overflow-y: auto;
        height: 2400px;
    }

    &-body {
        background-color: white;
        border-radius: 8px;
    }

    &-pdc{ // period dropdown container
        width:240px;
    }

    &-expand-card { // expandable card
        min-width: 100%;
    }

    &-dpc { // date picker container
        & > .form-container {
            margin: 0px;
        }
    }

    &-cs { // spacing
        & > * {
            margin-right: 16px;
        }
    }
    
    &-ktt{ // key transaction trends
        &-c{ // container
            height: 100%;
            width: 100%;
            display: grid;
            grid-template-columns: auto;
            row-gap: 20px;
        }
    }

    &-dcc { // debit Credit chart
        &-c{  // container
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
            justify-content: center;
        }
    }

    &-opt-box {
        padding-top: 2px;
        margin-bottom: 15px;
    }

    &-chart {
        &-container {
            margin-top: 20px;
        }
    }
}

.chart_container {
    width: 100%;
    position: relative;
}

#annotation-txn-form {
    position: relative;
}
