/*
 * LogsFilter style
 */
 
@import "../../stylesheets/colors";

.log-filter { // Log filter
    margin: 30px;

    &-cont {    // container
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;

        * {
            margin: 10px;
        }
    }

    &-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        white-space: break-spaces;
    }

    &-dd {
        background-color: #FFFFFF;
        padding: 0px;
        min-height: 39px;
        &__cc {
            width: 210px;
        }
    }
    
    &-store {
        color: $color-forest-green;
        font-size: 16px;
        font-weight: 300;

        div {
            margin: 10px
        }
    }
}
