.navbar {
    &-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    max-width: 900px;
    // Optional: Set full height to center vertically
    height: 20vh;

        &-label {
            font-size: 18px;
            font-weight: bold;

            &-wrapper {
                font-weight: 400;
                line-height: 20px;
                margin-right: 20px;
                max-width: 300px;
                width: 100%;
                text-align: right;
            }
        }

        &-button {
            &-wrapper{
                align-items: center;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                display: flex;
                font-family: Figtree, sans-serif;
                font-weight: 600;
                height: 36px;
                justify-content: center;
                line-height: 1.4;
                padding: 8px 16px;
                min-width: 100px;

                &-primary {
                    background-color: #45CEEB;
                    color: #ffffff;
                }
            }
        }
    }
}

.input {
    border: 1px solid #AFB0B9;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 200;
    height: 36px;
    padding: 4px 8px;

    &-label {
        margin-right: 30px;
    }
}

.crossvalidation {
    &-data {
        &-container {
            display: flex;
            flex-direction: column;
            max-width: 900px;
            align-items: center;
            justify-content: center;
            
            &-json {
                margin: auto;
                white-space: pre-wrap;
            }
        }
    }
}

.docImageName {
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;
}

.infoDetails {
    font-size: 20px;
    padding-top: 20px;
    padding-bottom: 10px;
    font-weight: bold;
    margin-right: 250px;
}

.devActions {
    max-width: 900px;
    display: flex;
    justify-content: center;
}