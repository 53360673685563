.expandablecard {
    background-color: #ffffff;
    max-width: 900px;
    border-radius: 8px;
    border: 1px solid #eee;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 20px;
    min-width: 500px;
    min-height: 44px;

    &-heading {
        &-button {
            width: 100%;
            border-radius: inherit;
        }

        width: 100%;
        display: flex;
        font-size: 20px;
        font-weight: 400;
        justify-content: space-between;
        padding: 10px 15px;
        cursor: pointer;

        &__arrowicon {
            transform: rotate(180deg);

            &--expanded {
                transform: rotate(0deg);
            }
        }
    }

    &-vis-hidden {
        display: none;
        height: 0;
    }
}
