@import '../../stylesheets/colors';

.table {
    &-nsr { // no search results
        font-size: 20px;
        margin-top: 40px;
        text-align: center;

        &--mt0 {
            margin-top: 0;
        }

        &--mt20 {
            margin-top: 20px;
        }
    }

    &-sl {
        &__tl {
            white-space: normal;
            display: block;
            overflow-x: scroll;
            word-break: break-word;
        }
    }

    &-sr { // search results
        margin-top: 40px;

        &--mt0 {
            margin-top: 0;
        }

        &--mt20 {
            margin-top: 20px;
        }

        &--mb20 {
            margin-bottom: 20px;
        }

        &__label {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        &__thw { // header wrapper
            align-items: center;
            background-color: #F5F5F5;
            border-bottom: 1px solid #EEEEEE;
            display: flex;
            padding: 10px;
            text-align: center;

            &--aic {
                align-items: center;
            }

            &--v2 {
                background-color: #FFFFFF;
            }
        }

        &__th { // header
            flex: 1;
            font-size: 16px;
            font-weight: 400;
            white-space: pre-wrap;

            &--big {
                flex: 2;
            }

            &--actions {
                flex: 0.4;
            }

            &--checkbox {
                flex: 0.5;
            }
        }

        &__tlc { // label container
            background-color: #FAFAFA;
            display: flex;
            flex-direction: column;
            max-height: 450px;
            overflow: auto;
            padding: 0 10px;
            text-align: center;

            &--v2 {
                background-color: #FFFFFF;
            }
        }
    
        &__tlw { // label wrapper
            display: flex;
            padding: 10px 0 10px;

            &--aic {
                align-items: center;
            }
        }

        &__tl { // label
            flex: 1;
            font-size: 14px;
            word-break: break-word;

            &--big {
                flex: 2;
            }

            &--cc {
                text-transform: capitalize;
            }

            &--uc {
                text-transform: uppercase;
            }

            &--lc {
                text-transform: lowercase;
            }

            &--nc {
                text-transform: none;
            }

            &--actions {
                flex: 0.4;
            }

            &--pwr { // pre wrap
                white-space: pre-wrap;
            }
        }

        &__sicr { // nested table, sub iten container
            align-items: center;
            border: 1px solid #333333;
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
    
            &:last-child {
                margin-bottom: 0;
            }
        }
    
        &__siwr { // sub item wrapper
            border-right: 1px solid #333333;
            flex: 1;
            padding: 6px 0;
    
            &--v1 {
                border-right: 0;
                flex: 2;
            }

            &:last-child {
                border-right: 0;
            }
        }

        &__ml20 {
            margin-left: 20px;
        }
    }

    &__sorting {
        display: flex;
        align-items: center;
    }

    &__chevron {
        height: 16px;
        width: 16px;
        cursor: pointer;
    }

    &__rotated-chevron {
        @extend .table__chevron;
        transform: rotate(180deg);
    }

    &__arrow-alignment {
        display: flex;
        flex-direction: column;
        position: relative;
        left: 5px;
    }

    &-wrapper { // table wrapper
        max-width: 1100px;
        margin: 20px 0px 0px;
        overflow-y: scroll;
        background-color: #FFFFFF;
    }

    &-sv { // scroll view
        min-width: 1100px;

        &__th {
            min-width: 150px;
        }

        &__tlc {
            overflow: unset;
        }

        &__tl {
            min-width: 150px; 
        }
        
        &__thw {
            min-width: 1100px;
            width: fit-content;
        }
        
    }
    &-lvm {
        &__lb {
            color: #6B7280;
        }
    }

    &-col-filter-lbl {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
    }

    &-button {
        width: 50%;
        &> * {
            width: 100%;
        }
    }

    &-filter {
        &-icon {
            cursor: pointer;
        }

        &-dropdown {
            position: relative;
            display: inline-block;

            &-content {
                display: block;
                position: absolute;
                background-color: #f1f1f1;
                min-width: 160px;
                box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
                z-index: 1;
            }

            &-input {
                min-width: 100%;

                &-cont {
                    width: max-content !important;
                    margin: unset !important;
            
                    & > * {
                        width: max-content !important;
            
                        & > * {
                            width: max-content !important;
                        }
                    }
                }
            }
            
        }
    }
}

// render link tags inside the table component with green color
.table-sr__tl > div > a {
    color: $color-forest-green;
    font-weight: bold;
}
