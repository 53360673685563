.pagination__v2 {
    &-container {
        display: flex;
        justify-content: space-around;
        margin: 20px auto 0;
        max-width: 200px;
        width: 100%;
        align-items: center;
    }

    &-sv { // scroll view
        margin: 20px auto 40px;
    }

    &-cta {
        color: #8D8D8D;
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;
        border-radius: 20px;
        padding: 10px;
        background-color: #FFFFFF;

        &-prev {
            transform: rotate(-90deg);
        }

        &-next {
            transform: rotate(90deg);
        }
    }
}
