.mnda{ // mandates
    &-note { // Scroll Note
        color:green;
        font-size: 12px;
        height: 15px;
        margin-right: 50px;
        padding: 0 10px;
        &-img{
            height:13px;
            transform:rotate(180deg);
        }
    }

}
