.fittt {
    margin: 0 auto 40px;
    max-width: 1000px;
    position: relative;
    width: 100%;

    &-cr {
        margin-bottom: 60px;
    }

    &-src { // search results container
        padding-top: 20px;
    }

    &-modal {
        &-cr { // container
            overflow-y: scroll;
        }

        &-lb {
            font-size: 16px;
            margin-bottom: 12px;

            &--sbd { // semi-bold
                font-weight: 500;
            }
        }

        &-cta {
            margin: 30px auto 0;
        }

        &-table {
            &-sr {
                &__tlc {
                    max-height: 400px;
                }
            }
        }
    }
}
