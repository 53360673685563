.credit-card {
    &-cb { // card banner
        &-hwr { // heading wrapper
            display: flex;
            margin-bottom: 40px;
        }

        &-backarrow {
            cursor: pointer;
            height: 20px;
        }

        &-hl { // heading label
            margin: 0 auto;
        }
   }
}
