.profile {
    margin: 0 auto 40px;
    max-width: 800px;
    width: 100%;

    &-sec {
        margin-top: 40px;  
        
        &__label {
            font-size: 20px;
        }
    }

    &-wrapper {
        display: flex;
        align-items: center;
        font-size: 16px;
        // justify-content: space-between;
        margin-bottom: 20px;

        &__label {
            font-size: 16px;
            width: 250px;
            text-transform: capitalize;
        }

        &__value {
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;

            &--v1 {
                text-transform: none;
            }
        }
    }

    &-err {
        font-size: 16px;
        margin-top: 40px;
    }

    &-acc-closure-wr { // account closure wrapper
        background-color: #cd2e2ef8;
        border-radius: 4px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 400;
        margin: -20px auto 40px;
        padding: 4px 16px;
        text-align: center;
        width: fit-content;
    }

    &-uii { // user issue information
        &-cr { // container
            @extend .profile-acc-closure-wr;
            font-family: 'Inter', sans-serif;
            font-weight: 500;
            text-align: initial;
            margin: 32px auto 32px;
            padding: 20px !important;
            width: 800px;
        }

        &-kv { // key value pair
            &-cr {
                margin: 0;
                width: auto;
            }

            &-cl { // content label
                color: #ffffff;
                text-transform: none;
                min-width: 200px;
            }

            &-cv { // content value
                color: #ffffff;
            }

            &-title {
                width: 100%;
            }
        }
    }
}
