.issue-config {
    &-dd {
        &-container {
            display: flex;
            align-items: center;
            width: 25%;
        }
        &-lb {
            width: 25%;
            margin-right: 2px;
        }
        &-input {
            width: 55%;
            margin-right: 30px;
        }
    }

    &-err-label {
        width: 100%;
        text-align: center;
        margin: 0;
        margin-top: 20px;
    }
}