.table-container {
    table {
        width: 100%;

        thead {
            background-color: #d3d3d3;

            tr {
                th {
                    text-align: center;
                    padding: 10px;
                    font-size: 16px;
                    font-weight: 400;    
                }
            }
        }

        tbody {
            tr {
                &:nth-child(2n) {
                    background: #f5f5f5;
                }

                td {
                    padding: 10px;
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
    }
}