.btu-history { // history screen
    &-ec { // expandable card
        max-width: 1000px;
        position: absolute;
        width: 100%;
        z-index: 1;

        &__scr { // search container in expandable card
            padding: 10px 15px 20px;
        }

        &__cta { // search cta in expandable card
            margin-top: 20px;
            width: 200px;
        }

        &__el { // err label in expandable card
            font-size: 13px;
            font-weight: normal;
        }
    }

    &-fc { // form container
        margin: 0;

        &--var1 { // variant 1
            margin-left: 100px;
        }

        &__lb { // label
            text-align: left !important;
            width: 170px !important;
        }
    }

    &-table-sr { // table search results
        margin-top: 70px;

        &__cta {
            margin-bottom: 4px;
        }
    }

    &-modal {
        &__cta {
            margin: 30px auto 0;
            width: 200px;
        }
    }
}
