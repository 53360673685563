.home {
    padding: 20px 50px;
    height: 100%;
    min-height: 100%;
    background-color: #E6E9ED;

    & > div {
        width: 100%;
        margin-bottom: 15px;
    }

    &-button {
        border-radius: 20px;
        font-weight: 600;
    }

    &-table {
        &> div:first-child {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            border-top: 1px solid #e0e0e0;
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            z-index: 1;
        }
        
        &> div:last-child {
            border-bottom-left-radius: 20px;
            border-bottom-right-radius: 20px;
            border-bottom: 1px solid #e0e0e0;
            border-left: 1px solid #e0e0e0;
            border-right: 1px solid #e0e0e0;
            z-index: 1;
        }

        &-column {
            &-short {
                flex: 0.75 0.75;
            }

            &-large {
                flex: 1.5 1.5;
            }
        }
    }

    &-modal {
        &-text {
            color: #6B7280;
            font-size: 18px;
            margin-bottom: 10px;

            &-bold {
                padding: 20px;
                font-weight: 700;
                font-size: 28px;
            }

            &-small {
                color: #6B7280;
                font-size: 14px;
            }
        }
        &-button {
            width: 50%;
            &> * {
                width: 100%;
            }
        }
    }
}