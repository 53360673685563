.referrals {
    margin: 0 auto 100px;
    max-width: 1000px;
    position: relative;
    width: 100%;

    &-ec { // expandable card
        margin: 40px 0;
        max-width: 1000px;
        width: 1000px;
    }

    &-ccr { // content container
        padding: 10px 15px 20px;

        &-hd1 {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
        }

        &-hd2 {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 12px;
            width: fit-content;
        }

        &-hd3 {
            font-size: 16px;
            font-weight: 400;
            text-align: left;
        }
    }

    &-cwr { // content-wrapper
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 16px;

        &__label {
            font-size: 16px;
            width: 300px;
        }

        &__value {
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;
        }
    }

    &-src { // search results container
        padding-top: 20px;
    }

    &-modal {
        &-cr { // container
            max-height: 400px;
            overflow: auto;
            padding-bottom: 20px;
        }

        &-hd { // heading
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 12px;
        }

        &-cta {
            margin: 20px auto 0;
        }
    }
}
