/**
 * These are the classes that can be overridden for custom styling
 * Ref - https://codesandbox.io/s/style-react-tabs-vertically-forked-ultzjk?file=/src/styles.css
*/

.react-tabs {
    width: 100%;
}

.react-tabs__tab-list {
    border-bottom: 1px solid #AFB0B9;
    margin-bottom: 10px;
    padding: 0;
}

.react-tabs__tab {
    display: inline-block;
    position: static;
    list-style: none;
    padding: 6px 20px;
    border: none;
    font-size: 18px;
    font-family: Figtree, sans-serif;
    color: #8D8D8D;
    text-transform: uppercase;
    font-weight: 400;
    cursor: pointer;
}

.react-tabs__tab--selected {
    color: #00B899;
    font-weight: 700;
    border-bottom: 3px solid #00B899;
    background: transparent;
}

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
    margin-top: 40px;
}
