// TODO: follow BEM for styles name convention
.notification-banners {
    margin-right: 60px;

    .notification-top-bar {
        position: fixed;
        top: 0;
        left: 0;
        height: 40px;
        line-height: 40px;
        width: 100%;
        background: #1ABC9C;
        text-align: center;
        color: #FFFFFF;
        font-family: sans-serif;
        font-weight: lighter;
        font-size: 14px;
        // display: flex;
        align-items: center;
        justify-content: center;

        .notification-text {
            width: 99%;
        }

        .notification-close {
            margin-left: auto;
            padding-right: 0.5rem;
        }
    }

    .content-container {
        right: 270px;
        width: 400px;
    
        .user-action-wrapper {
            padding: 10px 0;
    
            .label {
                border-bottom: 1px solid #cccccc;
                font-size: 16px;
                font-weight: 400;
                padding: 10px 15px;

                &:last-child {
                    border-bottom: 0;
                }

                &:hover {
                    background-color: #F5F5F5;
                }

                &__title {
                    color: #8D8D8D;
                    font-size: 18px;
                    text-transform: capitalize;
                    margin-bottom: 4px;
                }

                &__body {
                    color: #282828;
                    font-family: Inter, sans-serif;
                    font-size: 16px;
                }
            }
        }
    }

    .icon-wrapper {
        cursor: pointer;
        display: flex;

        &__image-icon {
            height: 20px;
            width: 20px;
        }
    
        &__notification-icon {
            background-color: #FF0000CC;
            border-radius: 8px;
            color: #FFFFFF;
            font-size: 10px;
            font-weight: 400;
            height: 16px;
            padding-left: 5px;
            padding-top: 2px;
            position: relative;
            top: -7px;
            right: 5px;
            width: 16px;
        }
    }
}
