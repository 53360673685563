.mergetkt {
    margin: 0 auto 40px;
    max-width: 900px;
    width: 100%;

    &-notecr {
        display: flex;
        margin-top: 40px;

        &__lb {
            text-align: left !important;
        }

        &__ta {
            max-width: 350px;
            margin-top: 12px;
        }
    }

    &-errlb {
        margin: 10px auto 40px;
    }

    &-cta {
        margin: 0 auto;
    } 
}
