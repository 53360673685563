$shapeDim: 12px;

.d3 { // d3
    &-legend { // lengend
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;

        & .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
        }

        & .shape {
            height:$shapeDim;
            width:$shapeDim;
            border-radius: 50%;
            margin-right: 4px;
        }
    }
    &-bc { // bar chart
        &-hwrapper { // heading wrapper
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-heading{ // heading
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-gtd { // graph dropdowm
            margin-left: 16px;
        }
    }
    &-bts { // bar tooltip data summary
        &-container{ // container
            text-align: left;
            padding-left:16px;
            margin-bottom: 16px;
        }
    }

    &-annotation-container{
        display: flex;
        flex-direction: row;
    }
}
