.err-view {
    &-cr {
        gap: 20px;
    }
    
    &-icon {
        padding: 20px;
        border-radius: 40px;
        background-color: #FFF8CE;
    }

    &-button {
        &-cr {
            width: 100%;
            justify-content: space-evenly;
        }
    }
}
