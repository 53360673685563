/**
 * @file RecentActivity/constants.js - Contains all the styles used for recent activity
 */

 .risk-recent-activity {
    max-width: 80%;
 }

.risk-recent-activity {
    margin: 0 auto 40px;
    width: 100%;

    &-outer-wrapper {
        width: 100%;
    }

    &-search-panel {
        position: relative;
    }

    &-field-container {
        margin: 15px 0 15px 0;
    }

    &-cr1 {
        padding-bottom: 60px;
    }

    &-cr2 {
        margin-bottom: 60px;
    }

    &-ec { // expandable card
        max-width: 1000px;
        width: 100%;
    }

    &-sc { // search container
        padding: 10px 15px 20px;

        &__gap {
            row-gap: 20px;
        }

        &__3r { // 3rd row
            margin-top: 20px;
        }

        &__cta {
            width: 200px;
        }

        &__clear {
            margin-left: 60px;
        }
    }

    &-el { // err label
        margin: 5px 0 10px;
    }

    &-fc {
        margin: 0;
    }

    &-dd {
        margin-right: 30px;
        width: 270px;

        &__cc { // content container
            width: 270px;
        }

        &__fcc { // flex content container
            margin-right: 30px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__lb { // to override form css of higher specificity
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            // margin-right: 0 !important;
            text-align: left !important;
            width: 160px !important;

            &-d {
                margin-right: 20px;
            }
        }

        &__ip {
            width: 270px !important;
        }

        &__clear { // clear selection cross icon
            background-color: #dddddd;
            border-radius: 10px;
            color: #666666;
            font-size: 13px;
            width: 19px;
            height: 19px;
            padding-left: 6px;
            text-transform: lowercase;
        }

        &__label-large {
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            text-align: left !important;
            margin-right: 20px;
        }
    }

    &-src1 { // search results container
        padding-top: 20px;
    }

    &-src2 { // search results container
        padding-top: 20px;
    }

    &-src3 { // search results container
        padding-top: 66px;
    }

    &-modal {
        &-cr { // container
            max-height: 400px;
            overflow: auto;
            padding-bottom: 20px;
        }

        &-hd { // heading
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 12px;
        }

        &-ctwr {
            margin-bottom: 30px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-ctwr2 {
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-ct {
            margin-bottom: 4px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-cta {
            margin: 20px auto 0;
        }

        &-wrap {
            white-space: pre-wrap;
        }

        &-lb { // label
            font-size: 16px;
            margin-bottom: 8px;
        }

        &-clrwcr { // claimed rewards container
            overflow-y: scroll;
        }

        &-rwwr { // reward info wrapper
            padding-top: 20px;
        }
    }

    &__text-input {
        &--no-error-label {
            display: none;
        }

        &--form-max {
            max-width: 100%;
            margin: auto;
        }
    }

    &-mat-cta {
        display: flex;
        justify-content: right;
        margin-bottom: 20px;
    }

    &-kv { // key value used in view employment details
        &__cl { // content label
            width: 450px;
            padding-left: 8px;
        }

        &__cv { // content value
            text-align: right;
            width: 550px;
        }
    }

    &-form {
        &-container {
            margin: 0 15px;
        }
    }

    &-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: left !important;
        width: 150px !important;
        margin-right: 20px;
    }

    &__errlb { // error label
        color: #FA3B11;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-weight: 400;
        height: 14px;
        margin: 20px 20px 1px 10px;
    }

    &_to {
        &-date {
            margin-left: 50px;
        }
    }

    &-section {
        &__title {
            font-size: large;
            font-weight: 500;
            margin-bottom: 15px;
        }
        &__container {
            margin-bottom: 20px;
        }
    }
    &-modal {
        &__title {
            font-size: larger;
            font-weight: 500;
            margin-bottom: 15px;
            text-align: center;
        }
    }
    &-table {
        &__container {
            margin-bottom: 30px;
            width: 100%;
            padding: 20px;
            padding-top: 0px;
            position: relative;
        }
        &-option {
            &__active {
                max-height: 500px !important;
            }

            &__height-transition {
                max-height: 0;
                overflow: hidden;
                transition-property: max-height;
                transition-duration: 0.4s;
                transition-timing-function: ease-in-out;
            }
        }
    }
}

