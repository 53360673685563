.prompt {
    &-label-value-cont {
        align-items: center;
        display: flex;
        margin-bottom: 24px;
        width: 100%;
        justify-content: center;
    }

    &-label {
        color: #8D8D8D;
        font-weight: 400;
        font-size: 18px;
        line-height: 110%;
        letter-spacing: 0.45px;
        margin-right: 50px;
        text-transform: capitalize;
        width: 200px;
        word-break: break-word;
    }

    &-value {
        color: #282828;
        font-family: Inter, sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 140%;
        text-align: left;
        width: 450px;
        word-break: break-word;
    }

    &-container {
        margin: 35px 0px;
    }

    &-info {
        margin: auto;
        color: #00B899;
        font-weight: 500;
        font-size: 20px;
        margin-top: 20px;
    }

    &-err{
        &-label {
            margin: auto;
        }
    }
}
