$RiskPopupCloseButtonHeight : 40px;
.urr { // user risk review
    &-cr { // container
        margin: 20px auto 40px;
        max-width: 1100px;
        width: 100%;

        &__vid { // video container
            width: 480px;
        }

        &__image { // image container
            width: 480px;
        }
    }

    &-kv { // key value pair
        &-cr {
            width: auto;
            display: grid !important;;
            grid-template-columns: 1fr 1fr;
        }
        &-mh { // max height
            max-height: 490px;
            overflow: auto;
        }
        &-ca { // conatiner
            width: auto;
            display: grid !important;;
            grid-template-columns: 1fr;
        }

        &-cl { // content label
            min-width: 200px;
            font-size: 16px;
        }

        &-cv { // content value
            width: fit-content;
            font-size: 16px;
            white-space: pre-line;
        }

        &-cw { // content wrapper
            margin-bottom: 8px;
            justify-content: flex-start;
        }

        &-cw:last-child { // content wrapper last sibling
            margin-bottom: 0px;
        }
    }

    &-tab-list {  // media tab list
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &-tab-list > li { 
        font-size: 16px;
    }

    &-sec {  // user review section
        margin: 20px auto 20px;
        max-width: 1100px;
    }

    &-expandablecard {  // user review section expandable card
        max-width: 1100px;
    }

    &-dev-action-cr { // user review section dev action container
        max-width: 1100px;
    }

    &-dev-action-cr > div { 
        max-width: 1100px;
    }

    &-tab-container { // tab container
        display: flex;
        column-gap: 50px;
        justify-content: center;
    }

    &-video { // video
        &__cr { // container
            height: 540px; // set height so that the container does not collapse while switching through videos
            margin: 30px 0;
        }
    }

    &-rmV2 { // liveness and facematch - renderMediaV2
        &-container { // conatiner
            align-items: start !important;

            &  .urr-tab-container {  // tab container
                flex:1;
            }

            & .urr-video__cr { // video container
                flex:1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &-hc { //history card
            &-container { //conatiner
                border-radius: 8px;
                border: 1px solid #eee;
                box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 20px;
                display: grid;
                grid-template-columns:auto auto;
                column-gap: 8px;
                row-gap: 8px;
                overflow: auto;
                max-height: 70vh;
                padding:10px;
            } 

            &-card { // card
                width:120px;
                height:50px;
                border:1px solid #000;
            }

            &-card-selected { //card selected
                background-color: #00B899;
                color:#fff !important;
            }
           
        }

        &-expandable_card_wrapper { 
            display: grid;
            grid-template-columns: auto;
            column-gap: 8px;
            row-gap: 16px;
        }

        &-expandable_card {
            width: 100%;
            max-width: 100%;

            & .urr-kv-ca {
                margin-right:15px;
                margin-bottom: 15px;
            }
        }
    }

    &-imgWrapper { //facemetach image wrapper
        width:350px;

        &-h{ //height
            height:400px;
        }

        & img {
            height:100%;
            width: 100%;
            object-fit: contain;
        }
    }

    &-table { // table
        &-header { //header
            color:#8D8D8D;
        }

        &-content { //content
            & .table-sv__tl { // cell
                color: #282828;
                line-height: 140%;
                font-size:16px;
                font-weight: 500;
                font-family: Inter, sans-serif;
            }
        }
    }
    &-rpopup { // risk popup modal
        &-table-content { // table content
            & .table-sr__tl { // cell
                color: #282828;
                line-height: 140%;
                font-size:16px;
                font-weight: 500;
                font-family: Inter, sans-serif;
            }
        } 

        &-container { // container
            width: 100%;
            overflow: auto;

            & .body { // modal body
                height:calc(100% - $RiskPopupCloseButtonHeight - 16px);
                margin-bottom:16px;
            }


            & .footer { // modal footer
                height: $RiskPopupCloseButtonHeight;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
    }

}

@media screen and (max-width: 1100px) {
    .urr { // user risk review
        &-rmV2 { // liveness and facematch - renderMediaV2
            &-container{
                flex-direction: column !important;
                
                & .urr-video__cr{ // video container
                    width: 100%;
                }

                & .urr-tab-container{ // tab container
                    width: 100%;
                    margin-top: 15px;
                }
            }
            &-hc { // history card
                &-container{ // container
                    grid-template-rows: auto auto;
                    grid-auto-flow: column;
                    max-height: 128px;
                    width: 100%;
                }
            }
        }
    }
}

.linked-in-url {
    text-align: right;
    margin-bottom: 10px;
    span {
        cursor: pointer;
        text-decoration: underline;
        color: #8f83b4;
        font-size: 15px;
        font-family: sans-serif;
        font-weight: 600;
    }
    &-loading {
        text-align: right;
        text-decoration: none !important;
        color: #989595 !important;
    }
}

