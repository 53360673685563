.pl { // parameter list
    &-ec { // expandable card
        width: 100%;
        max-width: 1000px;
        z-index: 1;
    }

    &-sc { // search container
        padding: 10px 15px 20px;

        &-ocr { // options container
            display: flex;
            flex-direction: column;

            &__hl {
                border-bottom: 1px solid;
                font-size: 16px;
                padding-bottom: 2px;
                width: fit-content;
            }
        }

        &-owr { // options wrapper
            align-items: center;
            display: flex;
            margin: 10px 0;

            &__ol { // option label
                font-size: 16px;
                flex-basis: 300px;
            }

            &__ov { // option value
                // min-width: 210px;
                flex-basis: calc(100% - 300px);
            }
        }

        &-cta { // button
            margin-top: 25px;
            width: 200px;
    
            &--err { // error
                margin-top: 0;
            }
        }
    }

    &-fc { // form container
        &--m0 { // margin 0
            margin: 0;
        }

        // overriding css of higher specificity from common component
        &__ip { // input
            width: 400px !important;
        }

        &__rsv { // resize vertical allow
            resize: vertical;
        }
    }

    &-el { // err label
        margin-bottom: 10px;
    }

    &-fuel { // file upload err label
        margin-top: 4px;
    }

    &-modal { // modal
        &__hl { // heading label
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 30px;
            text-align: center;
        }

        &__sbhl { // sub heading label
            font-size: 16px;
            margin-bottom: 60px;
            text-align: center;
        }

        &__ctas { // ctas wrapper
            justify-content: space-evenly;
            width: 100%;
        }
    }

    &-dd { // dropdown
        width: 400px;

        &--ml0 { // margin left 0
            margin-left: 0;
        }

        &__cc { // content container
            width: 400px;
        }
        
        &__ph { // placeholder
            font-size: 16px;
            font-weight: 200;
        }
    }
}
