.app {
    .layout-container {
        display: flex;
        /* fixed ticket tabs */
        top: 50px;
        position: fixed;
        width: 100%;
        height: 100%;

        &--mt-48 {
            top: 48px; // 48px(app header)
        }

        &--mt-92 {
            top: 92px; // 48px(app header) + 44px(horizontal id navbar)
        }

        &--clr-wild-sand {
            background-color: #F5F5F5;
        }

        &--var1 {
            @extend .layout-container--mt-48;
            display: block;
        }

        &--var2 {
            @extend .layout-container--clr-wild-sand;
            @extend .layout-container--mt-92;
        }

        &--var3 {
            @extend .layout-container--clr-wild-sand;
            @extend .layout-container--mt-48;
        }

        .main-content {
            width: 100%;
            padding: 20px;
        }

        .err-content {
            font-size: 20px;
            font-weight: 400;
            margin: 80px auto 0;
        }
    }

    .layout-container-v2 {
        display: flex;
        /* fixed ticket tabs */
        top: 50px;
        width: 100%;
        height: 100%;

        .main-content {
            width: 100%;
            padding: 20px;
        }

        .err-content {
            font-size: 20px;
            font-weight: 400;
            margin: 80px auto 0;
        }
    }
}
