.af {   // agent feedback parent
    &-btn { //  button
        margin: 0 20px;
        font-size: 14px;
        width: max-content;
    }

    &-input {   // input field
        width: 600px !important;
    }

    &-rbcont {  // radio button container
        flex-direction: row !important;
        justify-content: start;
        width: 600px;
    }

    &-mtcont {  // manual ticket container - for overriding default style
        margin-top: 30px !important;
    }
}
