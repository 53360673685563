// use https://chir.ag/projects/name-that-color to name the color
$color-black: #000000;
$color-black-opaticy1: #00000080;
$color-black-opaticy2: #000000d9;
$color-white: #FFFFFF;
$color-wild-sand: #F5F5F5;
$color-forest-green: #00B899;
$color-gray: #8D8D8D;
$color-melrose: #A590FB;
$color-portage: #937DEF;
$color-bombay: #AFB0B9;
$color-vermilion: #FA3B11;
$color-grey: #cccccc;
$color-indigo-opaticy1: #0000001a;
$color-purple:  #A020F0;
$color-stale-blue:  #7558EE;
$color-cornflowerblue: #6495ed;
$color-biloba-flower: #b8aaf0;
