.heading {
    &1 {
        font-family: 'Figtree', sans-serif;
        font-weight: 500;
        font-size: 24px;
    }

    &2 {
        font-family: 'Figtree', sans-serif;
        font-weight: 400;
        font-size: 22px;
        text-transform: uppercase;
    }

    &3 {
        font-family: 'Figtree', sans-serif;
        font-size: 18px;
        font-weight: 400;
    }

    &4 {
        font-family: 'Figtree', sans-serif;
        font-size: 16px;
        font-weight: 400;
    }

    &5 {
        font-family: 'Figtree', sans-serif;
        font-size: 14px;
    }

    &6 {
        font-family: 'Figtree', sans-serif;
        font-size: 16px;
    }
}

/*
    General text on the screen
    Use convention like xxs, xs, s, m, l, xl, xxl so that sizes can be added on either side
*/
.text {
    // medium
    &-m {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
    }
}

.label {
    &1 {
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        font-weight: 400;
    }
}

.wspace-pre-wrap {
    white-space: pre-wrap;
}

.text {
    &-center {
        text-align: center;
    }
}

.error-label {
    color: #FA3B11;
    font-size: 12px;
    line-height: 15px;
    height: 15px;
    margin-top: 10px;
    width: fit-content;

    &1 {
        color: #FA3B11;
        font-size: 15px;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        text-align: center;
        white-space: pre-wrap; // so that \n strings render correctly
    }
}
