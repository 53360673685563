@import 'font';
@import 'colors';
@import 'typography';
@import 'spacing';
@import 'position';
@import 'components';
@import 'containers';

body {
    background-color: $color-white;
    color: $color-black;
    font-family: 'Figtree', sans-serif;
    font-weight: 200;
    font-size: 14px;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

* {
    box-sizing: border-box;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    margin: 0;
}

/* reset button css */
button {
    border: 0;
    padding: 0;
    background: inherit;
    cursor: pointer;
}

h2 {
    font-size: 20px;
    margin: 0;
}

a {
    color: $color-black;
    text-decoration: none;
}

a[disabled] {
    pointer-events: none;
}

:focus:not(.s-rdg-cell) { // except for transactionViewV2 table cells
    outline: none !important;
}

label {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 0;
}

input {
    border: 1px solid $color-bombay;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 200;
    height: 36px;
    padding: 4px 8px;
}

input[type='checkbox'] {
    height: 14px;
    width: 14px;
}

/* to remove the arrow when input type is number */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

input::-webkit-input-placeholder {
    color: $color-gray;
}

textarea {
    border: 1px solid $color-bombay;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 200;
    padding: 4px 8px;
    resize: none;
}

audio {
    width: 500px;
}

#root {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100vh;
    min-height: 100vh;
}

/* 
    Default font family for pre events is monospace
    so defaulted to our font family
*/
pre {
    font-family: inherit;
}
