.step {
    &-title {
        font-weight: 500;
        font-size: 20px;
    }
    
    &-traversal {
        position: relative;
        min-height: 400px;
    }

    &-cc{
        border: 1px solid #E6E9ED;
        background-color: #FFFFFF;
        border-radius: 20px !important;
    }
}
