.vkyc {
    background-color: #E6E9ED;

    &-call-queue {
        &-screen {
            min-height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &-message {
            &-icon {
                margin-right: 10px;
            }
            &-container {
                border: 0.5px solid #B2B5B9;
                border-radius: 50px;
                padding: 15px;
                display: flex;
                align-items: center;
                width: 500px;
            }
        }
    }

    &-step {
        display: flex;
        flex-direction: column;
        gap: 30px;
    }

    &-final-banner {
        &-screen {
            min-height: 400px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }

        &-title {
            font-size: 10px;
            padding: 20px;
        }
    }

    &-container {
        display: flex;
        width: 100%;
        height: 500px;

        &-title {
            color: #374151;
            font-weight: 700;
            font-size: 24px;
            margin-bottom: 10px;
        }
    }

    &-left-video-container {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-right-qna-container {
        width: 70%;
        scrollbar-color: transparent transparent;
        &:hover {
            scrollbar-color: rgb(178, 181, 185) transparent;
        }
    }

    &-qna-container {
        height: 100%;
        overflow: auto;
    }
    
    &-agent {
        &-video {
            position: fixed;
            right: 10px;
            top: 10px !important;
            height: 144px;
            width: 256px;
        }
    }
    
    &-customer {
        &-video {
            width: min(100%, 275px);
            margin: auto;
            background-color: #4A4A4AE5;
            border-radius: 23px;
            position: relative;
            border: 4px solid gray;

            &-player {
                min-height: 350px;
                position: relative;
            }

            &-player-parent-common {
                &-overlay {
                    border-radius: 10px;
                    border: white 2px solid;
                    position: absolute;
                    z-index: 2;
                    margin: auto;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    height: 50%;
                    width: 91.6%;
                } 
                height: 100%;
                width: 100%;
                position: relative;

                &-customer video {
                    border-radius: 19px 19px 0 0;
                }
                &-agent video {
                    border-radius: 10px;
                }
            }

            &-loader {
                position: absolute;
                width: 100%;
                top: 40%;
                z-index: 1022;
                text-align: center;

                &-text {
                    color: white;
                    font-weight: 600;
                    font-size: 15px;
                    text-align: center;
                }
                

                .custom-loader {
                    position: relative;
      
                    &-label {
                        text-align: center;
                        display: inline-block;
                        position: static;
                    }
                }

            }
        }
        &-text {
            position: absolute;
            color: white;
            font-weight: 600;
            font-size: 24px;
        }

        &-consumer-title {
            text-align: center;
            background-color: #EFF2F6;
            height: 43px;
            border-bottom-right-radius: 19px;
            border-bottom-left-radius: 19px;

            &-text {
                padding-top: 13px;
                display: inline-block;
                font-family: Figtree;
                font-size: 12px;
                font-weight: 600;
                line-height: 16px;
                color: #6A6D70;
            }
        }

    }

    &-bottom {
        &-options {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 5px;
            gap: 60px;
            &> button {
                border-radius: 20px !important;
                box-shadow: 0px 3px 5px #313234;
                border: unset;
            }
        }
    }

    &-location {
        &-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
        }
        &-lat-long {
            &_container {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
            }
            &_key {
                color: #929599;
                font-size: 12px;
                font-weight: 600;
            }
            &_value {
                color: #6A6D70;
                font-size: 12px;
                font-weight: 600;
            }
            
        }
    }

    &-image {
        height: 360px;
        width: 202px;
        border-radius: 20px;
        position: relative;

        &-array {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            width: 360px;
            margin: auto 0;
        }

        &-container {
            display: flex;
            height: 100%;
            align-items: center;
        }
        &-placeholder {
            border: 1px solid #B2B5B9;
            background-color: #EFF2F6;
            height: 360px;
            width: 202px;
            border-radius: 20px;
            overflow: hidden;
            flex-shrink: 0;

            &-horizontal {
                border: 1px solid #B2B5B9;
                background-color: #EFF2F6;
                height: 242px;
                width: 360px;
                border-radius: 20px;
                overflow: hidden;
                display: flex;
                align-items: center;
                flex-direction: column;
            }

            &-hori-button-height {
                height: 280px;
            }
        }

        &-text-placeholder {
            text-align: center;
            padding: 21px;
            word-break: auto-phrase;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            font-size: 15px;
        }

        &-btn {
            &__capture {
                text-align: center;
                position: relative;
                bottom: 50px;
                left: 50px;

                &-horizontal {
                    position: inherit;
                    text-align: center;
                }
            }
        }

        &-horizontal {
            height: 242px;
            width: 360px;
            border-radius: 20px;
            position: relative;
        }
    }

    &-image-report {
        height: 360px;
        width: 190px;
        border-radius: 20px;
        position: relative;

        &-container {
            display: flex;
            height: 100%;
            align-items: center;
        }
        &-placeholder {
            border: 1px solid #B2B5B9;
            background-color: #EFF2F6;
            height: 360px;
            width: 190px;
            border-radius: 20px;
            overflow: hidden;
            flex-shrink: 0;
        }

        &-btn {
            &__capture {
                text-align: center;
                position: relative;
                bottom: 50px;
                left: 50px;
            }
        }
    }
    
    &-button-end {
        background-color: #AA301F;
        color: #F6F9FD;
    }

    &-step {
        &-container {
            border: 1px solid rgb(162, 162, 162);
            border-radius: 20px;
            padding: 20px;
            margin-bottom: 20px;
        }
        &-option {
            margin: 20px 20px 0 0;
            border-radius: 20px !important;

            &-inactive {
                background-color: #F6F9FD;
                color: #6A6D70;
            }

            &--yes {
                background-color: #00B899;
                color: white;
            }

            &--no {
                background-color: #AA301F;
                color: white;
            }

            &-check {
                height: 25px;
                width: 25px;
                // filter: invert(86%) sepia(12%) saturate(600%) hue-rotate(74deg) brightness(93%) contrast(88%);
                background-color: #86BA6F;
                border-radius: 100%;
                padding: 1px;

                &__icon {
                    filter: invert(96%) sepia(8%) saturate(100%) hue-rotate(70deg) brightness(100%) contrast(100%)
                }
            }

            &-cross {
                height: 25px;
                width: 25px;
                // filter: invert(86%) sepia(12%) saturate(600%) hue-rotate(74deg) brightness(93%) contrast(88%);
                background-color: #AA301F;
                border-radius: 100%;
                padding: 1px;

                &__icon {
                    width: 100%;
                    height: 100%;
                    filter: brightness(3)
                }
            }
        }
    }

    &-kv {
        &-key {
            min-width: 200px;
            font-weight: 600;
            color: #6A6D70;
        }
        &-container {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
        }
        &-value {
            color: #313234;
            font-weight: 600;
        }
    }

    &-match-score {
        &-container {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            align-items: center;
        }
        &-percentage {
            font-weight: 600;
            font-size: 24px;

            &--green {
                color: #86BA6F;
            }
            &--red {
                color: #AA301F;
            }
        }
        &-progress {
            width: 100%;
            margin-left: 20px;
            &--green {
                background-color: #86BA6F;
            }
            &--red {
                background-color: #AA301F;
            }
        }

        &-text {
            height: 94%;
        }
    }

    &-image-comparison {
        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 20px;
            background-color: #EFF2F6;
            flex-basis: 50%;
            margin: 5px;
            margin-top: 10px;
            &-100 {
                display: flex;
                flex-direction: column;
                margin-top: 10px;
                align-items: center;
                background-color: #EFF2F6;
                flex-basis: 100%;
                border: 10px solid white;
            }
        }

        &-container-landscape {
            display: flex;
            flex-direction: column;
            margin-top: 10px;
            align-items: center;
            border-radius: 10px;
            background-color: #EFF2F6;
            flex-basis: 48%;
            border: 5px solid white;
        }


        &-title {
            font-weight: 600;
            color: #313234;
            background-color: #E6E9ED;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 18px;
            text-align: center;
            border-radius: 10px 10px 0 0;
        }
        &-img {
            &-container {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                gap: 25px;
                flex-wrap: wrap;
            }
        }
        &-match-score {
            font-weight: 600;
            color: #313234;
            background-color: #E6E9ED;
            width: 100%;
            padding: 10px;
            text-align: center;
            border-bottom-right-radius: 20px;
            border-bottom-left-radius: 20px;
        }
    }

    &-final-screen-row {
        display: flex;
        align-items: center;
        margin-top: 20px;
        flex-wrap: wrap;
        align-items: stretch;
    }

    &-w {
        &-100 {
            width: 100%;
        }
    }

    &-qna {
    
        &-line {
            border: 1px solid #E6E9ED;
            margin: 10px 0;
        }
    
        &-container {
            padding: 20px;
            width: 100%;
            height: calc(100% - 53px);
        }
    }

    &-button {
        &-active {
            background-color: #00B899;
            border: 1px solid #F5F5F5;
            border-radius: 8px;
            color: white;
            letter-spacing: 0.45px;
            line-height: 110%;
            padding: 10px 20px;
            text-transform: uppercase;
            margin: 20px 20px 0 0;
            border-radius: 20px !important;
        }
        &-inactive {
            background-color: #FFFFFF;
            border: 1px solid #F5F5F5;
            border-radius: 8px;
            color: #00B899;
            letter-spacing: 0.45px;
            line-height: 110%;
            padding: 10px 20px;
            text-transform: uppercase;
            margin: 20px 20px 0 0;
            border-radius: 20px !important;
        }

        &-non-interacted {
            background-color: white !important;
            color: #00B899;
        }

        &-container {
            display: flex;
            gap: 100px;
            margin-top: 20px;
            justify-content: center;
            & > button {
                border-radius: 20px !important;
                box-shadow: 0px 3px 5px #313234;
                border: unset;
            }
        }
    }

    &-match-text {
        font-weight: 600;
        text-align: center;
        padding: 9px;
        white-space: normal;
    }

    &-remarks {
        &-containers {
            position: relative;
            overflow-x: hidden;
            &-title {
                font-size: 19px;
                font-weight: 600;
            }

            &-close-modal-icon {
                position: absolute;
                right: 10px;
                top: 10px;
                cursor: pointer;
                font-size: 20px;
                background: white;
                border-radius: 50%;
                border: 5px solid white;
            }

        }
        &-text-container {
            width: 100%;
            background-color: white;
            border-radius: 19px;
            padding: 15px;

            &-textarea {
                width: 100%;
                min-height: 100px;
                color: #333333;
                font-weight: 400;
                border: 1px solid #CED2D6;
                padding: 10px;
                font-family: Figtree;
                font-size: 20px;
                font-weight: 600;
                line-height: 24px
            }
        }

        &-field {
            &-label {
                font-weight: 600;
                color: #6A6D70;
                margin-top: 10px;
            }
        }
        
        &-action {
            display: flex;
            flex-direction: row-reverse;

            &-btn {
                // background-color: #AA301F;
                color: #B2B5B9;
                font-size: 16px;
                border-radius: 19px;
                margin-right: 10px;
                border: 1px solid white;
                background-color: white;
            }

            &-btn:disabled {
                background-color: white !important;
                cursor: not-allowed;
                color: #B2B5B9 !important;
            }

            .approve {
                background-color: #00866F;
            }

            .reject {
                background-color: #AA301F;
                color: white;
            }
        }
        
        &-accordion {
            min-height: 70px;
            width: 98%;
            background-color: white;
            border-radius: 19px;
            padding: 15px;
            margin: 15px;

            &-modal {
                padding: 10px;

                &-title {
                    text-align: center;
                    color: #333333;
                    font-family: Figtree;
                    font-size: 32px;
                    font-weight: 600;
                    line-height: 32px;
                }

                &-sub-title {
                    text-align: center;
                    color: #8D8D8D;
                    font-family: Inter;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 50px;
                }

            }
            
            &-header {
                font-size: 20px;
                font-weight: 600;
                cursor: pointer;
                color: #646464;
                font-family: Figtree;
                font-weight: 600;
                line-height: 24px;

                &-arrow-icon {
                    float: right;
                    font-size: 20px;
                    color: #646464;
                    margin-right: 10px;
                }
            }

            &-body {
                display: flex;
                flex-direction: row;
                width: 100%;
                flex-wrap: wrap;

            }

            &-item {
                flex-basis: 50%;
                padding: 10px;
            }

            &-radio {
                height: auto;
                margin-right: 10px;

                &-label {
                    font-family: Figtree;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 24px;
                    color: #333333;
                }
            }

        }
    }

    &-final-scr {
        &-report {
            height: calc(100vh - 247.516px);
            overflow-y: scroll;
            &-cr {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(600px, 1fr));
            }
        }

        &-cr {
            height: 100%;
            border-radius: 20px;
            max-height: 100%;
            overflow-y: auto;
            background-color: #ffffff;
            padding: 0 20px 20px 20px;
        }
    }

    &-err {
        &-button {
            border-radius: 30px;
        }
    }

    &-pdf {
        &-container {
            overflow: hidden;
            position: relative;
            height: 100%;
            width: 100%;

            &-iframe {
                border: none;
                height: 100%;
                transform-origin: center center;
            }

            &-btn {
                position: absolute;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &-modal {
        &__content {
          background-color: #ffffff;
      
          &-iframe {
            width: 100%;
            height: 60vh;
            transform-origin: center center;
            scrollbar-color: transparent transparent;
            border: 0;
          }
      
          &-image {
            width: 100%;
            height: 60vh;
            object-fit: contain;
            transform-origin: center center;
          }
        }
      
        &__footer {
          display: flex;
          justify-content: center;
          margin-top: 16px;
        }
    }

    &-approval {
        &-remarks {
            display: flex
            ;
                flex-direction: row-reverse;
                justify-content: center;
                align-items: center;
                gap: 10px;
                padding-top: 10px;
        }
    }

    &-meet-id {
        width: 100%;
        display: flex;
        justify-content: end;
        gap: 10px;
        padding-bottom: 10px;
        padding-top: 10px;
        font-size: 16px;
        font-weight: 500;
    }
} 
