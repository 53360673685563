.user-escalation-review-container {
    display: block;
    width: 100%;
    height: 100%;
    margin: 10px;
    background-color: white;
    padding: 20px;

    .case-container {
        margin: 50px 10px 10px 10px;
        padding: 10px;

        &.-header {
            color: gray;

            &.-time-stamp {
                font-size: 30px;
                font-family: Figtree;
                font-weight: 600;
            }

            &.-casewithid {
                font-size: 30px;
                font-family: Figtree;
                font-weight: 600;
            }
        }
    }
}

.user-case-review {

    &-expand-card {
        background-color: #ffffff;
        max-width: 98%;
        border-radius: 2px;
        border: 1px solid #eee;
        box-shadow: none;
        min-width: 500px;
        min-height: 44px;

        &-parent {
            background-color: #ffffff;
            max-width: 98%;
            border-radius: 2px;
            border: 1px solid #eee;
            box-shadow: none;
            min-width: 500px;
            min-height: 44px;
            // height: 100%;
        }

        .expandablecard-heading-button {
            width: 100%;

            .expandablecard-heading {
                width: 100%;

                div {
                    flex-basis: 90%;
                }
            }
        }
    }

    &-header {
        font-weight: 400;
        font-family: Figtree;
        font-size: 14px;
        display: flex;
        width: 100%;

        &-col {
            flex-basis: 25%;
        }

        &-title {
            display: block;
            margin: 10px;
        }

        .title-case-id {
            margin-left: 50px;
        }
    }


    &-outer-container {
        padding-right: 30px;
        padding-top: 30px;
        padding-left: 17px;
        height: 100%;
        overflow-y: auto;
    }

    &-question-container {
        margin-bottom: 50px;

        &-title {
            font-size: 20px;
            font-family: Figtree;
            font-weight: 500;
        }

        &-response {
            text-align: justify;
            line-height: 36px;
        }

        &-response-file {
            margin-top: 20px;
            margin-bottom: 20px;

            button {
                font-size: 15px;
                font-weight: 500;
                border: 2px solid #7c7cda;
                color: #7c7cda;
                padding: 5px 21px 5px 21px;
                cursor: pointer;
                border-radius: 4px;
            }
        }
    }

    &-modal-close-btn {
        position: absolute;
        right: 19px;
        top: 31px;
        background-color: #5d373742;
        z-index: 10;
        cursor: pointer;

        img {
            width: 50px;
            height: 50px;
        }
    }

    &-pdf-container {
        height: 500px;
        width: 100%;
        padding: 100px;
        overflow-y: scroll;
        text-align: center;
        height: 85vh;
        overflow: auto;
    }

    &-image-viewer {
        position: relative;
        img {
            height: auto;
            max-width: 100%;
        }
    }

    &-outer-container {
        position: relative;
        height: 100%;
    }

    &-zoom-slider {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        height: 10px;
        background-color: #ddd;
        border-radius: 5px;
        cursor: pointer;
        transition: left 0.1s ease-out;
    }

    &-zoom-handle {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 20px;
        height: 20px;
        background-color: #007bff;
        border-radius: 50%;
        cursor: grab;
        transition: left 0.1s ease-out;
    }
}
