.ussfm { // Us stocks facematch review
    &-cr { // container
        margin: 40px auto 20px;
        max-width: 900px;
        width: 100%;
    }

    &-hl { // heading label
        &--cr {
            display: flex;
        }

        &-main-heading {
            font-size: 20px;
            font-weight: 400;
            text-transform: capitalize;
        }

        &-image-heading {
            font-size: 16px;
            text-transform: capitalize;
            margin-bottom: 10px;
        }
    }

    &-text{
        display: flex;
        margin: 30px 0;
    }

    &-img { // image
        &--cr { // container
            display: flex;
            text-align: center;
            margin: 30px 0;
        }

        &--itm { // item

            &left { // left side img
                margin-right: 20px;
            }

            &right { // right side img
                margin-left: 20px;
            }
            
        }
    }

    &-backarrow { //backarrow
        cursor: pointer;
        height: 20px;
        margin-right: 20px;

        &--cr {
            display: flex;
            align-items: center;
        }
    }

    &-wrapper-label{ //label
        font-size: 16px;
        width: 160px;
        text-transform: capitalize;
    }

    &-wrapper-value{ //value
        font-size: 16px;
        font-weight: 400;
        text-transform: capitalize;
    }

    &-action{ //action dropdown
        margin-right: 29px;
    }

    &-payload{
        width: fit-content !important;
    }

    &-table-rw{
        text-align: center;
    }

    &-fcwsd { // form container with search dropdown
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 460px;

        &__lb {
            font-size: 18px;
            font-weight: 400;
            line-height: 20px;
            margin-right: 20px;
            text-align: left;
        }
    }

    &-crel {
        margin: auto;
        font-size: 16px;
    }
}

.kvpair-info-cr{
    margin: 40px 0px 70px;
}
