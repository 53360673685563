$menuWapperMargin: 8px;
$menuHeight: 100px;
.rdgrid { // transaction table
    &-tt { // main conatiner
        &-c { // container
            height: 90vh; 
            padding-inline: 8px;

            &-fs { // full screen mode
                background-color: #fff;
                height:100vh;
                width: 100vw;
                padding-top: 8px;
                position: fixed;
                top:0;
                left: 0;
                z-index: 1;
            }
        }

        &-gt { //grid table
            block-size: calc(100% - 50px);
        }

        &-mw { // menu wrapper
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: $menuWapperMargin;
            &c { // menu 
                flex:1;
                min-height:$menuHeight;
                overflow: auto;
            }
        }
    }
    &-fc {  // container
        block-size: calc(100% - $menuHeight - $menuWapperMargin);

        & .filter-cell { // filter cell
            line-height: 35px;
            padding: 0;
        
            & > div {
              padding-block: 0;
              padding-inline: 8px;
        
              &:first-child {
                border-block-end: 1px solid var(--rdg-border-color);
              }
            }
          }
        
        & .rdg-cell { // cell 
            font-weight: 300;
        }

        & .select-cell { // selection input cell
            & input[type=checkbox] {
                height: auto; 
                width: auto; 
            }

            & input {
                 border: 0px;
                 height: auto; 
                padding: 0px;
            }
        }
        
        & .rdg-cell-dragged-over { // dragged cell
            background-color: #ccccff !important;
            & .rdgrid-cf {
                background-color: #ccccff !important;
            }
          }
    }

    &-f { // filter input
        inline-size: 100%;
        padding: 4px;
        font-size: 14px;
        height: auto;
    }

    &-arrow { // arrow icon
        &-container { // container
            margin-left: 10px;
            display: inline-flex;
            align-items: center;
        }
        &-up { // up arrow
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            
            border-bottom: 5px solid black;
        }

        &-down { // down arrow
            width: 0; 
            height: 0; 
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            
            border-top: 5px solid black;
        }
    }

    &-cf { // color format cell
        margin-inline: -8px;
        padding-inline: 8px;
        white-space: nowrap;
        overflow: clip;
        text-overflow: ellipsis;
    }
    &-cd { // clear dropdown
        position: relative;
        
        &-label { // label
            display: flex;
            align-items: center;
            justify-content: center;

            &-img { // dropdown arrow
                margin-left: 8px;
                width: 14px;
                height: 10px;
                transform: rotate(0deg);

                &-open { // open 
                    transform: rotate(180deg);
                }
            }
        }

        &-oc { // options container
            width: 180px;
            transform: translate(-50%, 0px);
            left: 50%;
        }
    }
    
    &-loader-wrapper { // loader wrapper
        display: flex;
        justify-content: center;
        align-items: center;
        display: inline-flex;
        
        & .loader {
            height: 20px;
            width: 20px;
        }
    }

    &-cm { // context menu
        position: absolute;
        background-color: #ffffff;
        border: 1px solid black;
        padding: 0px 8px;
        list-style: none;
      
        > li {
          margin: 4px 0px;
          border: 1px solid black;
        }
    }

    &-cm { // Context Menu
        &-wrapper{ // wrapper
            position: absolute;
            width: 250px;
            border-radius: 10px;
            background: #fff;
            box-shadow: 0 12px 35px rgba(0,0,0,0.1);
            z-index: 10;
            
            & .menu {
                padding: 8px 12px;
                margin: 0;
            }
        }
        
        &-content { // content
            & .item {
                list-style: none;
                color: #000000;
                font-size: 16px;
                font-weight: 300;
                font-family: "Figtree", sans-serif;
                height: 40px;
                display: flex;
                width: 100%;
                cursor: pointer;
                align-items: center;
                border-radius: 5px;
                margin-bottom: 2px;
                padding: 0 5px 0 10px;

                &:hover {
                    background-color: #eae8fd;
                }

                & span {
                    margin-left: 8px;
                }
            }
        }
    }

    &-signal-list {
        &-container {
            margin-bottom: 10px;
        }

        &-item {
            margin-right: 4px;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 300;
            background-color: #ffdea2;
            color: #a05e00;
            cursor: pointer;

            &-x {
                background-color: #f8d7da;
                color: #842029;
                padding: 4px 4px;
            }
        }
    }
}

.row-self-transfer-debit {
    background-color: #EEE9D7;
}

.row-self-transfer-credit {
    background-color: #D7DCEE;
}
