.af-details { // agent feedback details
    margin: 30px auto;

    &-heading { // heading
        font-size: 24px;
        font-weight: 500;
        text-transform: uppercase;
        margin: 20px 0;
    }

    &-cont {    // container
        display: flex;
        justify-content: center;

        &-date {    // date-selector
            margin: 0 30px;
        }
    }

    &-table {   // table
        margin: 40px 0;
        width: 1570px;
    }

    &-input {   //input field
        width: 400px;
        margin: 0 30px;
    }
}