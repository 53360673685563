.create-banner {
    &-cr { // container
        margin: 0 auto;
        width: 700px;
    }

    &-hwr { // heading wrapper
        align-items: center;
        display: flex;
        margin-bottom: 40px;

        &__backarrow {
            cursor: pointer;
            height: 20px;
        }

        &__hd { // heading
            font-size: 24px;
            font-weight: 400;
            line-height: 120%;
            margin: 0 auto;
            text-transform: uppercase;
        }
    }

    &-fc { // form container
        display: flex;
        flex-direction: row;
        align-items: center;

        &__lb { // to override form css of higher specificity
            color: #8D8D8D;
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            letter-spacing: 0.45px;
            margin-right: 0px !important;
            text-align: left !important;
            text-transform: capitalize;
            width: 250px !important;
        }

        &__ip { // input field
            margin-right: 0px !important;
            width: 450px !important;
        }

        &__errlb { // error label
            color: #FA3B11;
            font-family: Inter, sans-serif;
            font-size: 12px;
            font-weight: 400;
            height: 14px;
            margin: 4px 0 32px 250px;
        }

        &__ip-name { // name input field
            width: 140px !important;
            margin-right: 15px !important;

            &:last-child {
                margin-right: 0px !important;
            }
        }

        &__desc {
            font-size: 14px;
            text-transform: initial;
        }
    }
}
