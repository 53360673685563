.ticket-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.ticket-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    // need this for expandable sidebar component to work properly
    position: relative;
    overflow: hidden;
}

.ticket-feedback {
    // background-color: #F5F5F5;
}

.ticket-tabs {
    display: flex;
    justify-content: space-between;
    padding-left: 220px;

    &-group {
        display: flex;
    }
}

.ticket-tab {
    align-items: center;
    color: #8D8D8D;
    display: flex;
    font-weight: 600;
    font-size: 18px;
    justify-content: space-between;
    min-width: 140px;
    padding: 8px 12px;
    width: fit-content;
    border-radius: 6px 6px 0px 0px;
    margin-right: 4px;

    &:hover {
        color: #FFFFFF;
        cursor: pointer;
    }

    &--active {
        background-color:#FFFFFF;
        color: #333333;
    
        &:hover {
            color: #333333;
            cursor: default;
        }
    }

    &__cb { // close btn
        cursor: pointer;
        font-size: 16px;
        font-weight: 400;
    }
}

.ticket-tab__border {
    border-bottom: 4px solid transparent;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin-top: 4px;
    width: 100%;
    visibility: hidden;
}

.ticket-tab__border--active {
    border-bottom-color:#fc5847;
}

.ticket-content-container {
    width: calc(100% - 220px); // side navbar width - 220px
    /* scrollable container */
    display: flex;
    flex-direction: column;
    height: calc(100% - 88px);
    overflow: auto;
    position: fixed;
    left: 220px;
    top: 88px;

    .ticket-form-container {
        margin-top: 60px;

        &__label {
            width: 80px;
        }

        &__err-label {
            margin-left: 100px;
        }
    }
}

.ticket-content-container-v2 {
    width: calc(100% - 220px); // side navbar width - 220px
    /* scrollable container */
    display: flex;
    flex-direction: column;
    height: calc(100% - 88px);
    left: 220px;
    top: 88px;

    .ticket-form-container {
        margin-top: 60px;

        &__label {
            width: 80px;
        }

        &__err-label {
            margin-left: 100px;
        }
    }
}

.ticket {
    &-modal {
        &__heading {
            line-height: 1.4;
            margin-bottom: 30px;
            text-align: center;
        }

        &__subheading {
            font-size: 14px;
            margin-bottom: 30px;
        }

        &__ctw { // close ticket wrapper
            margin: 0 0 40px;
        }

        &__ct-ctas { // close ticket ctas wrapper
            width: 300px;
        }
    }
}

