.form-group.toggle {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: start;

    label {
        margin-bottom: 0;
        margin-right: 10px;
    }

    .switch {
        position: relative;
        display: block;
        width: 54px;
        height: 26px;

        input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 26px;

            &::before {
                position: absolute;
                content: "";
                height: 20px;
                width: 20px;
                left: 4px;
                bottom: 3px;
                background-color: white;
                -webkit-transition: .4s;
                transition: .4s;
                border-radius: 50%;
            }
        }

        input:checked + .slider {
            background-color: #45CEEB;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px #45CEEB;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }
    }  
}
