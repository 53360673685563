.segmented-tab {
    &-container {
        text-align: center;
    }

    &-tabs-container {
        display: flex;
        justify-content: flex-start; // Align tabs to the left
        margin: 0 auto;
    }

    &-tab {
        padding: 24px 20px 10px 20px;
        margin: 0 5px;
        cursor: pointer;

        &.segmented-tab-active {
            color: #00b899;
            border-color: #007bff;
        }

        &.segmented-tab-inactive {
            color: #4b5563;
        }
    }

    &-line {
        border-bottom: 1px solid #e5e7eb;
        margin-top: 10px;
    }

    &-content {
        margin-top: 20px;
    }
}
