.fmkyc { // facematch kyc
    &-cr { // container
        margin: 40px auto 80px;
        max-width: 900px;
        width: 100%;
    }

    &-tab {
        background-color: #D6FFC2;
        border-radius: 6px 6px 0px 0px;
        display: flex;
        font-size: 16px;
        padding: 8px 12px;
        position: fixed;
        top: 50px;

        &-lb { // label
            font-weight: 400;
        }

        &-cb { // close btn
            cursor: pointer;
            margin-left: 16px;
        }
    }

    &-ec { // expandable card
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &-sc { // search container
        padding: 10px 15px 20px;

        &-cta {
            margin-top: 25px;
            width: 200px;
    
            &--err {
                margin-top: 0;
            }
        }
    }

    &-fc { // form container
        margin-bottom: 20px;

        &--m0 {
            margin: 0;
            margin-bottom: 20px;
        }

        &__lb { // label
            text-align: left !important;
            width: 200px !important;
        }

        &__ip { // input field 
            width: 400px !important;
        }
    }

    &-el { // err label
        margin-bottom: 10px;
    }

    &-crel { // container err label
        margin-top: 80px;
    }

    &-ic { // image container
        margin-top: 80px;
        text-align: center;
    }
}
