.form-container {
    margin: 0 auto;
    max-width: 900px;

    .form-wrapper {
        display: flex;
        align-items: center;
    
        &-label {
            font-weight: 400;
            line-height: 20px;
            margin-right: 20px;
            max-width: 300px;
            width: 100%;
            text-align: right;
        }
    
        .input-label {
            width: 210px;
            margin-right: 30px;
        }

        .input-label--smaller {
            width: 100px;
            margin-right: 10px;

            &:last-of-type {
                margin-right: 30px;
            }
        }
    
        .secondary-btn {
            margin-left: 20px;
        }

        .status-label {
            font-size: 14px;
            font-weight: 400;
        }
    }

    .error-label {
        color: #FA3B11;
        font-size: 12px;
        line-height: 15px;
        height: 15px;
        margin-top: 2px;
        margin-left: 320px;
        width: fit-content;
    }
}