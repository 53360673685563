.annotations {
    &-button-container {
        height: 60px;
        width: 100%;
        position: relative;
    }

    &-button {
        display: inline;
        margin-top: 25px;
        bottom: 20px;
        right: 20px;
        position: absolute;
    }

    &-modal-toggle-button {
        margin-left: 10px;
    }

    &-modal-body {
        height: 100%;
        // position: relative;
        background: gray;
        margin-top: 10px;
        padding-top: 15px;
    }

    &-modal-headers {
        // background: gray;
        padding-top: 15px;
        padding-left: 11px;
        padding-bottom: 10px;
        position: relative;
    }

    &-modal { // modal
        &__cr { // container 
            height: 100%;
            overflow: auto;
            position: relative;
        }

        &__hl { // heading label
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 30px;
            text-align: center;
        }

        &__sbhl { // sub heading label
            font-size: 16px;
            margin-bottom: 60px;
            text-align: center;
        }

        &__cta { // button
            justify-content: space-around;
            width: 100%;
        }

        &__cl { // close button
            background-color: transparent;
            border: none;
            cursor: pointer;
            font-size: 20px;
            margin-bottom: 2px;
            padding: 0;
            display: inline;
            padding-right: 19px;
            position: absolute;
            right: 2px;
            top: 6px;
          }

        &__cl span {
            font-size: 40px;
            vertical-align: middle;
        }
        &-heading {
            font-size: 14px;
            font-weight: 400;
        }

        &-container {
            min-height: 250px;
        }
        &-form-container {
            max-height: 500px;
            overflow-y: auto;
        }

        &-field {
            margin: 20px;
            margin-top: 0px;

            &-label {
                color: #fff2f2;
                font-size: 13px;
                font-weight: 600;
                margin-left: 22px;
            }
        }

        &-mark-mandatory {
            color: red;
            font-size: 25px;
        }
    }
}

.annotations-modal-container {
    max-height: 500px;
    overflow-y: auto;
}

.annotations-form-transaction-page-bottom {
    margin-top: 10px;
    width: 70%;
    position: relative;
}

.annotations-form-transaction-page-bottom .annotations-modal-field div .annotations-modal-field-label {
    color: #444242 !important;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 7px;
    margin-left: 25px;
    position: relative;
}

.annotation__menu-list {
    max-height: 100px !important;
    overflow-y: auto !important;
    font-size: 12px;
  }

.annotations-form-transaction-page-bottom .annotations-button-container .annotations-button {
    margin-right: 10px;
}
