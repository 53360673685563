.table {
    &-ad { // actions dialog
        &-label {
            cursor: pointer;
            font-size: 16px;
            font-weight: 400;
        }
    
        &-cc { // content container
            margin-top: 2px;
            width: 180px;

            /**
            * Apply !important to height for the active state.
            * This is a quick fix for the issue where the height is not being applied on button click.
            * TODO: Need to check why height is not applied on ellipsis to view details on prod env [ANKIT] [MONORAIL] [https://monorail.pointz.in/p/fi-app/issues/detail?id=53491]
            */
            &--active {
                height: auto !important;
            }
        }
    }
}
