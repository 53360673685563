.lreview { // liveness review
    &-cr { // container
        margin: 40px auto 20px;
        max-width: 900px;
        width: 100%;

        &1 { // container for showing dequeue button
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &2 { // container for liveness summary component
            height: 100%;
            overflow: scroll;
            position: relative;
        }

        &el { // err label
            margin: auto;
            font-size: 16px;
        }
    }

    &-hl { // heading label
        &--cr {
            display: flex;
        }

        &1 {
            font-size: 20px;
            font-weight: 400;
            text-transform: capitalize;
        }

        &2 {
            font-size: 16px;
        }
    }

    &-src { // search result container
        padding-top: 20px;
    }

    &-video {
        text-align: center;
        width: 543px;
        height: 600px;
        margin: 40px auto;
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
        margin-right: 20px;

        &--cr {
            display: flex;
            align-items: center;
        }
    }

    &-idd { // single item dropdown
        width: 300px;
        padding: 0px;

        &__cc {
            width: 300px;
            margin: 0 -1px;
        }

        &__cl {
            width: 100%;
            text-align: left;
        }

        &__lb { // label
            font-weight: 400;
            line-height: 20px;
            font-size: 18px;
        }
    }

    &-fc {
        margin: 0 0 15px;

        &__ip {
            width: 250px !important;
        }
    }
}
