.loans-out-call {
    &-cont {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #f5f5f5;
    }

    &-table {
        background-color: #fff;
        width: 100%;
        max-width: 1000px;
        border-radius: 10px;
        padding: 10px;
    }
}
