.cxrisk {
    margin: 40px auto;
    width: 1000px;

    &-cr1 {
        margin: 0 auto;
        width: 900px;
    }

    &-hd { // heading
        font-size: 18px;
        font-weight: 400;
    }

    &-err { // error
        font-family: 'Inter', sans-serif;
        margin: 30px auto 30px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        white-space: pre-wrap;
        text-align: center;
    }

    &-table {
        &-sr {
            margin-bottom: 40px;
        }
    }

    &-action-sr { // action separator
        padding: 0 10px;
    }

    &-fc { // form input container
        width: 500px !important;

        &1 { // for dropdown input
            @extend .cxrisk-fc;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__lb { // form input label
            width: 200px !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            margin-right: 20px !important;
            text-align: left !important;
        }

        &__ip { // form input field
            width: 300px !important;
            margin: 0 !important;
        }
    }
}

.cxrisk-v2 {
    margin: 0px auto;
    width: 1000px;
}