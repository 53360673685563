.winv { // wealth investment
    margin: 0 auto 40px;
    max-width: 1000px;
    width: 100%;

    &-content-wr {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-bottom: 20px;

        &__label {
            font-size: 16px;
            width: 200px;
        }

        &__value {
            font-size: 16px;
            font-weight: 400;
            text-transform: capitalize;
            width: 500px;

            &--v1 {
                text-transform: none;
            }
        }

        &__cta {
            margin-left: 40px;
            width: fit-content;
        }
    }

    &-errlb { // error label
        font-size: 20px;
        font-weight: 400;
        margin-top: 40px;
        text-align: center;
    }

    &-modal { // content container
        &-hwr { // heading wrapper
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 20px;

            &--kyc {
                margin-bottom: 0;
            }
        }

        &-ccr { // content container
            max-height: 450px;
            overflow: scroll;
            white-space: pre-wrap;
        }

        &-cwr { // content wrapper
            border-bottom: 1px solid #eee;
            display: flex;
            align-items: center;
            padding: 6px 0;

            &--curr-stage {
                border-bottom: 0;
                padding: 8px 0;
            }

            &__cl { // content label
                font-size: 16px;
                text-transform: capitalize;
                width: 300px;

                &--v2 {
                    width: 150px;
                }
            }
    
            &__sp { // separator
                width: 50px;
            }
    
            &__cv { // content value
                font-size: 16px;
                text-align: left;
                word-break: break-word;
                width: 470px;
                white-space: pre-wrap;

                &--v2 {
                    width: 400px;
                }

                &--r2 { // row 2
                    margin-top: 4px;
                }

                &--ml20 {
                    margin-left: 20px;
                }
            }
        }

        &-bwr {
            margin: 30px auto 0;
            width: 100px;
        }

        &-amiwr { // array meta info wrapper
            margin: 8px 0;

            &__sp { // separator
                margin: 0 12px 0 8px;
            }
        }

        &-curr-stage {
            max-height: 450px;
            overflow: scroll;
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
        position: absolute;
    }

    &-hw { // heading wrapper
        display: flex;
        margin-bottom: 24px;

        &__hd {
            font-size: 18px;
            font-weight: 400;
            margin: 0 auto;
            text-transform: capitalize;
        }
    }

    &-onbtable { // onb table
        max-height: 250px;
    }

    &-onbtr { // onb troubleshoot
        &__desc {
            font-size: 16px;
            font-weight: 400;
            margin: 40px auto 20px;
            width: 700px;
        }

        &__tlc {
            max-height: 400px;
        }
    }
}
