.user-wrapper {
    margin: 60px auto 0;
    max-width: 600px;

    .user-info-wrapper {
        margin-bottom: 40px;

        .label {
            font-size: 16px;
            margin-right: 20px;
        }

        .name {
            font-size: 16px;
            font-weight: 400;
        }
    }

    .user-info-label {
        font-size: 16px;
        line-height: 1.5;
    }

    .user-ctas-container {
        margin-top: 20px;

        &--wl {
            margin-top: 80px;
        }

        .user-ctas-wrapper {
            width: 240px;

            .label {
                font-size: 16px;
                margin-bottom: 15px;
                text-align: center;
            }
        }
    }

    .user-auth-label {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 40px;
        text-align: center;
        text-decoration: underline;
    }
}

.modal-action-label {
    line-height: 1.4;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
}

.user-confirm-wrapper {
    align-items: center;
    display: flex;
    max-width: 900px;
    margin: 20px auto 0;

    .label {
        font-size: 16px;
        margin-right: 20px;
    }

    .name {
        font-size: 18px;
        font-weight: 400;
        text-transform: capitalize;
        color : #000;
    }

    .primary-cta {
        margin: 0 40px;
    }
}

.user-not-registered-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 430px;
    margin: 0 auto;
    text-align: center;

    .label {
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 40px;
    }
}

.wlwr { // waitlist user wrapper
    display: flex;
    flex-direction: column;
    max-width: 600px;
    margin: 0 auto;

    &-hw {
        display: flex;
        margin-bottom: 40px;

        &__hd {
            font-size: 18px;
            font-weight: 400;
            margin: 0 auto;
            text-transform: uppercase;
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }

    &-cwr { // content wrapper
        display: flex;
        margin-bottom: 12px;

        &__cl { // content label
            font-size: 16px;
            text-transform: capitalize;
            width: 200px;
        }

        &__sp { // separator
            width: 50px;
        }

        &__cv { // content value
            font-size: 16px;
            text-align: left;
            word-break: break-word;
        }
    }

    &-bwr {
        margin: 20px auto 0;
        width: 100px;
    }
}
