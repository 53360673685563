.account-statement {
    margin: 0 auto;
    max-width: 900px;
    width: 100%;
    
    &-cr {
        margin: 0 auto;
        max-width: 1000px;
        width: 100%;
        position: relative;
    }

    &-field-container {
        padding: 10px 15px 20px;
    }

    &-dd {
        margin-bottom: 20px;
    }

    &-other-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: left !important;
        width: 170px !important;
    }

    &-fc { // form container
        &--m0 {
            margin: 4px 0  0;
        }

        &__lb { // label
            text-align: left !important;
            width: 170px !important;
        }
    }

    &-info {
        &--container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 5px 0 20px;
        }

        &--label {
            font-size: 18px;
            font-weight: 500;
            width: 185px;
        }

        &--value {
            font-size: 18px;
            font-weight: 500;
        }
    }

    &-custom-dd {
        &__cc {
            &--ac {
                height: 137px;
                overflow-y: auto;
            }
        }
    }

    &-ec { // expandable card
        position: absolute;
        width: 100%;
        z-index: 1;

        &__cta { // search cta in expandable card
            margin-top: 20px;
            width: 200px;
        }
    }

    &-button {
        display: flex;
        justify-content: center;
    }

    &-error-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
