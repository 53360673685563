.exporthd { // export health data
    margin: 0 auto;
    max-width: 600px;

    &-cta {
        width: 220px;
    }

    &-lb {
        font-size: 16px;
        margin-left: 30px;
        width: 350px;
    }

    &-ueap { // user exported app logs
        margin-top: 60px !important;
        width: 100%;
    }
}
