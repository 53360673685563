.comms {
    &-container {
        margin: 0 auto;
        max-width: 900px;
        width: 100%;
    }

    &-sc { // search card
        padding: 10px 15px 20px;

        &__errlb { // err label
            margin-bottom: 10px;
        }
    
        &__cta { // search cta
            margin-top: 25px;
            width: 200px;
    
            &--err {
                margin-top: 0;
            }
        }
    }

    &-form-container {
        margin-top: 20px;

        &--m0 {
            margin: 0;
        }
    }

    &-other-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: left !important;
        width: 100px !important;
    }

    &-modal { // content container
        &-hwr { // heading wrapper
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 20px;
        }

        &-cwr { // content wrapper
            display: flex;
            margin-bottom: 12px;

            &__cl { // content label
                font-size: 16px;
                text-transform: capitalize;
                width: 200px;
            }
    
            &__sp { // separator
                width: 50px;
            }
    
            &__cv { // content value
                font-size: 16px;
                text-align: left;
                word-break: break-word;
            }
        }

        &-bwr {
            margin: 20px auto 0;
            width: 100px;
        }

        &-miwr { // meta info
            border: 1px solid #ccc;
            margin: 20px 0;
            max-height: 300px;
            overflow: scroll;
            padding: 20px;

            &__cl { // content label
                font-weight: 500;
            }
    
            &__cv { // content value
                margin-top: 12px;
                white-space: pre-wrap;
            }
        }
    }
}
