.salops { // salary account ops page
    &-cr { // container
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    &-cr__v2 {
        margin: 0 auto 400px;
        max-width: 1000px;
        width: 100%;
    }

    &-cc { // content container
        width: calc(100% - 230px); // side navbar width - 230px
        /* scrollable container */
        display: flex;
        flex-direction: column;
        align-items: center;
        height: calc(100% - 48px);
        overflow: auto;
        position: fixed;
        left: 230px;
        top: 85.5px; // app header height
    }

    &-ci { // container item
        margin: 40px auto;
        width: 1000px;
    }

    &-expandable {
        &-heading {
            margin: 10px 0px;
            font-size: 22px;
            font-weight: 500;
            text-transform: capitalize;
        }

        &__cont {
            max-width: none;
            margin-top: 80px;
        }
    }

    &-kv { // key value used in view employment details
        &__cr { // container
            margin: 60px auto 20px;
            width: 1000px;
        }

        &__cr-v2 {
            background-color: rgb(249, 249, 249);
            padding: 30px;
            // border-radius: 40px;
            margin-top: 20px;
            width: 100%;
        }

        &__con-wr {
            margin-bottom: 10px;
        }

        &__hd { // heading title
            margin: 0;
            font-size: 22px;
            font-weight: 400;
            line-height: 120%;
            text-transform: uppercase;
        }

        &__hd2 { // heading for general health insurance details
            margin: 0;
            font-size: 18px;
            font-weight: 400;
            text-transform: capitalize;
        }

        &__hd3 { // heading for txn details
            margin-top: 10px;
            margin-bottom: 10px;
            font-size: 18px;
            font-weight: 400;
            text-transform: capitalize;
        }

        &__cl { // content label
            width: 450px;

            &1 { // used for search and find remitter name label
                width: unset;
                margin-right: 10px;
            }
        }

        &__cv { // content value
            text-align: right;
            width: 550px;

            &1 { // used for search and find remitter name value
                width: unset;
            }
        }
    }

    &-uefc { // update employer search input
        width: 700px !important;

        &__lb { // form input label
            width: 180px !important;
            font-size: 18px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            margin: 0 !important;
            text-align: left !important;
        }

        &__ip { // form input field
            width: 500px !important;
            margin: 0 30px !important;
        }
    }

    &-uetb { // update employer table
        &__cr {
            width: 1000px;
            margin-bottom: 30px;
        }
    }

    &-fc { // form input container
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        &__lb { // form input label
            font-size: 18px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            margin-right: 20px !important;
            text-align: left !important;
        }

        &__ip { // form input field
            width: 300px !important;
            margin: 0 !important;
        }
    }

    &-fs { // from single input container
        margin-top: 30px;
        margin-bottom: 40px;
        max-width: unset;

        &__fw {// single input wrapper
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__lb { // form input label
            font-size: 18px !important;
            font-weight: 400 !important;
            line-height: 20px !important;
            margin-right: 20px !important;
            text-align: left !important;
            color: #8d8d8d;
        }
    }

    &-err { // error
        font-family: 'Inter', sans-serif;
        margin: 30px auto 30px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        white-space: pre-wrap;
        text-align: center;
    }

    &-btnWrapper {
        display: flex;
        gap: 20px;
        
    }

    // single date picker for account details screen on agent flow
    &-date {
        &__ctn {
            margin: 0 !important;
            max-width: unset !important;
        }

        &__label {
            text-align: left !important;
        }
    }

}
