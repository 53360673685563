.credit-card {
    &-csc { // card settings container
        margin-top: 150px;

        &-wr { // wrapper
            margin-bottom: 20px;
            justify-content: space-between;
        }

        &__hl { // heading label
            font-size: 16px;
            text-transform: capitalize;
            width: 200px;
        }

        &__sp { // separator
            width: 50px;
        }

        &__cl { // content label
            font-size: 16px;
            text-align: left;
            overflow-wrap: anywhere;
            max-width: 400px;
        }
    }
}
