.calldtls {
    margin: 0 auto 40px;
    max-width: 900px;
    text-align: center;
    width: 100%;

    &--v1 {
        margin: 0 auto 40px;
        width: 80%;
    }

    &-recwr {
        margin-top: 20px;
    }

    &-trwr {
        border: 2px solid #ccc;
        margin-top: 20px;
        max-height: 600px;
        overflow: scroll;
        padding: 20px;
        white-space: pre-wrap;
        word-break: break-all;

        &--modal {
            font-size: 18px;
            margin-top: 0;
            max-height: 100%;
        }

        &__pg {
            margin: 0 auto 12px;
            width: fit-content;
        }

        &__desc {
            text-align: left;
        }
    }
}
