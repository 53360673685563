.app-header {
    width: 100%;
    height: 48px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* fixed header */
    position: fixed;
    z-index: 1;

    &-v2 {
        width: 100%;
        height: 48px;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-label {
        color: #00B899;
        font-family: Figtree;
        font-style: normal;
        font-weight: 800;
        font-size: 24px; // design specs 36px
        line-height: 30px; // design specs 44px
    }

    &-access-level {
        color: #FFFFFF;
    }

    &-profile {
        color: #FFFFFF;
        font-family: Figtree, sans-serif;
        font-style: normal;
        // overriding with important as we have 2 different versions of profile dropdown
        font-weight: 600 !important;
        font-size: 18px !important; // design specs 30px
        line-height: 24px; // design specs 36px

        // to show initials with background color
        background-color: #00b899;
        border-radius: 14px;
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // overriding with important as we have 2 different versions of profile dropdown
    &-arrow {
        height: 16px !important; // design specs 24px
        width: 16px !important; // design specs 36px
    }
}

.bg { 
    background-color: rgb(83, 30, 41);
}

//header background non prod
.bg-np {
    background-color: #282828;
}
