/**
 * @file RecentActivity/constants.js - Contains all the styles used for recent activity
 */

.recent-activity {
    &-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: left !important;
        width: 150px !important;
        margin-right: 20px;
    }

    &__errlb { // error label
        color: #FA3B11;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-weight: 400;
        height: 14px;
        margin: 20px 20px 1px 10px;
    }

    &_to {
        &-date {
            margin-left: 50px;
        }
    }

    &-section {
        &__title {
            font-size: large;
            font-weight: 500;
            margin-bottom: 15px;
        }
        &__container {
            margin-bottom: 20px;
        }
    }
    &-modal {
        &__title {
            font-size: larger;
            font-weight: 500;
            margin-bottom: 15px;
            text-align: center;
        }
    }
    &-table {
        &__container {
            margin-bottom: 30px;
            width: 75%;
        }
        &-option {
            &__active {
                max-height: 500px !important;
            }

            &__height-transition {
                max-height: 0;
                overflow: hidden;
                transition-property: max-height;
                transition-duration: 0.4s;
                transition-timing-function: ease-in-out;
            }
        }
    }
}
