@import '../../stylesheets/colors';

.rr { //risk rule 
    &-modal { // modal
        &__cr { // container 
            height: 100%;
            overflow: auto;
            position: relative;
        }

        &__hl { // heading label
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 30px;
            text-align: center;
        }

        &__sbhl { // sub heading label
            font-size: 16px;
            margin-bottom: 60px;
            text-align: center;
        }

        &__cta { // button
            justify-content: space-around;
            width: 100%;
        }

        &__cl { // close button
            background-color: transparent;
            border: none;
            cursor: pointer;
            font-size: 30px;
            margin-bottom: 2px;
            padding: 0;
            
            &-wrapper {
                display: flex;
                justify-content: flex-end;
            }
          }
    }

    &-row-action-btn { // row action button
        color: $color-melrose;
    }
} 

.risk-rule-wrapper {
    width: 100%;
    height: 100%;
    padding: 35px;
}

.risk-rule {
    .table-wrapper {
        width: 100%;
        max-width: 100%;
        box-shadow: 7px 1px 7px 11px #c3d7f71c;
        height: calc(100% - 120px);
        display: grid;

        .table-sr .table-sr__thw {
            height: 66px !important;
        }

        .table-sr .table-sr__thw .table-sr__th {
            border: 1px solid #c9b3b3;
            height: 66px !important;
            padding-top: 10px !important;
        }

        .table-sr .table-sr__tlc .table-sr__tlw {
            padding: 0px !important;
        }

        .table-sr__tlc .table-sr__tlw .table-sv__tl {
            min-width: 150px;
            border: 1px solid #f6e9e9;
            padding-left: 3px;
            padding-right: 3px;
        }

        .table-sr .table-sr__tlc .table-sr__tlw .table-sv__tl div {
            padding: 3px;
        }

        .table-sr .table-sr__tlc .table-sr__tlw--aic {
            align-items: stretch;
        }
    }

    &-form-container {
        max-width: 100%;
        min-width: 100%;
        position: relative;

        .form-container .form-wrapper .pl-fc__ip {
            width: 70%;
        }
    }
}

.risk-rule-from-field-type-5 {
    width: 70%;
    margin-left: 0px;
    position: relative;
    .custom-dd__cc {
        width: 100%;
    }
    .pl-dd__ph {
        width: 100%;
        text-align: left;
    }
}
