.hinb { // horizontal id navbar
    &-cr { // container
        display: flex;
        padding-top: 8px;
        padding-left: 20px;

        &-disable {
            display: none !important;
        }

        &--fixed {
            position: fixed;
            top: 48px; // similar to app header height
            left: 0px;
            width: 100%;
        }

        &-v2 {
            display: flex;
            padding-left: 20px;

            &--fixed {
                position: relative;
                top: -36px; // similar to app header height
                left: 0px;
                width: 100%;
            }
        }
    }

    &-wr { // wrapper
        &__itm { // item
            background-color: #555555;
            border-radius: 10px 10px 0 0;
            color: #FFFFFF;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            font-family: Figtree;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            padding: 8px 12px 8px 16px;
            margin-right: 8px;
            width: fit-content;

            &--new { // new item
                color: #A4A4A4;
            }

            &--active { // active item
                background-color: #F5F5F5;
                color: #282828;
                font-weight: 600;
            }
        }
    }

    &-close-icon {
        cursor: pointer;
        margin-top: 2px;
        margin-left: 20px;
        height: 16px;
        width: 16px;
    }

    &-empty-cr {
        height: 36px; // height of fixed horizontal navbar = 44px (36px + 8px top padding)
    }

    &-ba { // backarrow icon
        display: flex;
    }
}

