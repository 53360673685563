.m-0 {
    margin: 0px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-60 {
    margin-top: 60px;
}

.ml-15 {
    margin-left: 15px;
}

.ml-30 {
    margin-left: 30px;
}

.mr-16 {
    margin-right: 16px;
}

.mr-30 {
    margin-right: 30px;
}

.mr-60 {
    margin-right: 60px;
}

.ml-60 {
    margin-left: 60px;
}

.mr-60 {
    margin-right: 60px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px;
}

.mb-60 {
    margin-bottom: 60px;
}



.m-40-0-80 {
    margin: 40px 0 80px !important;
}

.m-40-auto-80 {
    margin: 40px auto 80px !important;
}

.m-0-auto-40 {
    margin: 0px auto 40px !important;
}

.m-40-auto-0 {
    margin: 40px auto 0 !important;
}

.m-auto {
    margin: 0 auto;
}

.mx-30 {
    margin: 0 30px !important;
}

.my-60 {
    margin: 60px 0 !important;
}

.my-30 {
    margin: 30px 0 !important;
}

.my-15 {
    margin: 15px 0;
}

.py-30 {
    padding: 30px 0;
}

.p-0 {
    padding: 0px !important;
}

.p-30 {
    padding: 30px !important;
}

.p-20 {
    padding: 20px !important;
}

.p-5 {
    padding: 5px !important;
}

.p-0-20-20 {
    padding: 0px 20px 20px !important
}

.pb-20 {
    padding-bottom: 20px;
}

.pt-30 {
    padding-top: 30px;
}
