.bud { // bulk user details
    margin: 40px auto 80px;
    max-width: 800px;
    width: 100%;

    &-csv-cr {
        width: 230px;
    }

    &-fc {
        display: flex;
        margin-bottom: 30px;

        &__lb {
            // margin-right: 20px;
            width: 200px !important;
            text-align: left !important;
        }

        // overriding css of higher specificity from common component
        &__ip {
            width: 400px !important;
        }
    }

    &-dd {
        background-color: #FFFFFF;
        margin-left: 0;
        width: 400px;

        &__cc {
            width: 400px;
        }
    }

    &-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        margin-right: 20px;
        white-space: break-spaces;
    }

    &-errlb {
        margin: 0 0 20px 220px;
    }

    &-cta {
        margin-left: 220px;
        width: 200px;
    }

    &-link {
        font-size: 18px;
    }

    &-table-sr {
        margin-top: 40px;

        &__thw {
            background-color: #FFFFFF;
        }

        &__tlc {
            background-color: #FFFFFF;
        }
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
    }

    &-userinfo{
        &-hwr { // heading wrapper
            display: flex;
            margin-bottom: 40px;

            &__hd {
                font-size: 18px;
                font-weight: 400;
                margin: 0 auto;
                text-transform: uppercase;
            }
        }
    }

    &-download-link {
        margin-top: 10px;
    }

    &-view-link {
        margin-left: 180px;
    }

    &-modal {
        &__hl {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 20px;
        }

        &__cta {
            margin: 20px auto 0;
        }
    }
}
