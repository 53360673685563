.agent-create-form {
    padding: 40px;
    margin: 0 auto;
    max-width: 1104px; /* 1024px + 80px */
    width: 100%;

    .header-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
    }

    .status-container {
        display: flex;
        flex-direction: column;

        .heading {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .status-row {
            display: flex;
            min-width: 500px;
            margin-bottom: 5px;

            .label {
                font-size: 16px;
                font-weight: 600;
                width: 30%;
                max-width: 200px;
            }

            .message {
                font-size: 14px;
                width: 70%;
                max-width: 500px;

                &.success {
                    color: #00C851;
                }

                &.error {
                    color: #ff4444;
                }
            }
        }
    }

    .cta--primary {
        margin-top: 40px;
        max-width: 600px;
        width: 100%;
    }
}