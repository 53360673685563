.tv-details { // transaction Queue 
    &-idd__cc {
        width: 400px;
        margin: 0 -1px;
    }

    &-idd {
        padding: 0px;
        width: 400px;
    }

    .table-nsr--mt0 {
        margin-top: 20px;
    }
}
