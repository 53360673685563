.doc-redac { // document redaction
    &-cr { // container
        min-width: 500px;
        padding: 30px 50px;
        background-color: #E6E9ED;

        &__el { // err label
            margin: 50px auto;
            font-size: 16px;
        }
    }

    &-ec { // expandable
        width: 100%;
        max-width: 1000px;
    }

    &-hl { // heading label
        &__cr {
            display: flex;
            max-width: 1000px;
            margin: auto;        
        }

        &1 {
            font-size: 20px;
            font-weight: 400;
            text-transform: capitalize;
        }

        &2 {
            font-size: 16px;
        }
    }

    &-video {
        text-align: center;
        width: 543px;
        margin: 40px auto 0;
    }

    &-img {
        display: flex;
        height: 400px;
        width: 100%;
        overflow: auto;
    }

    &-pdf {
        @extend .doc-redac-img;
        height: 800px;
    }

    &-backarrow {
        cursor: pointer;
        height: 20px;
        margin-right: 20px;

        &__cr {
            display: flex;
            align-items: center;
        }
    }

    &-idd { // single item dropdown
        width: 387px;
        padding: 0px;

        &1 { // queue size dd
            @extend .doc-redac-idd;
            width: 70px;
            min-width: 70px;
        }

        &__cr { // item container
            width: 387px;
            margin: 0 -1px;
        }

        &__cr1 { // item container for queue size dd
            @extend .doc-redac-idd1;
            margin: 0 -1px;
        }

        &__con { // item content
            width: 100%;
            text-align: left;
        }

        &__lb { // label
            font-weight: 400;
            line-height: 20px;
            font-size: 18px;
        }
    }

    &-src { // search result container
        margin-top: 20px;
    }

    &-modal {
        &__hl {
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: 30px;
            text-align: center;
        }

        &__sbhl {
            font-size: 16px;
            margin-bottom: 60px;
            text-align: center;
        }

        &__ctas { // ctas wrapper
            justify-content: space-around;
            width: 100%;
        }
    }

    &-btn {
        background-color: #ece9e9;

        &__cr {
            gap: 10px;
            max-width: 100px;
            overflow-wrap: break-word;
            word-break: break-word;
            text-align: center;
        }
    }

    &-dd {
        min-width: 200px;
    }
}
