.barw { // back arrow
    &-cr { // container
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 20px;
    }

    &-img { // image
        height: 20px;
    }
}
