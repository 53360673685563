.table {
    &-nsr { // no search results
        font-size: 20px;
        margin-top: 40px;
        text-align: center;

        &--mt0 {
            margin-top: 0;
        }

        &--mt20 {
            margin-top: 20px;
        }
    }

    &-sr { // search results
        margin-top: 40px;

        &--mt0 {
            margin-top: 0;
        }

        &--mt20 {
            margin-top: 20px;
        }

        &--mb20 {
            margin-bottom: 20px;
        }

        &__label {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 10px;
        }

        &__thw { // header wrapper
            align-items: center;
            background-color: #f5f5f5;
            border-bottom: 1px solid #eee;
            display: flex;
            padding: 10px;
            text-align: center;

            &--aic {
                align-items: center;
            }
        }

        &__th { // header
            flex: 1;
            font-size: 16px;
            font-weight: 400;
            white-space: pre-wrap;

            &--big {
                flex: 2;
            }

            &--actions {
                flex: 0.4;
            }
        }

        &__tlc { // label container
            background-color: #fafafa;
            display: flex;
            flex-direction: column;
            max-height: 450px;
            overflow: scroll;
            padding: 0 10px;
            text-align: center;
        }
    
        &__tlw { // label wrapper
            display: flex;
            padding: 10px 0 10px;

            &--aic {
                align-items: center;
            }
        }

        &__tl { // label
            flex: 1;
            font-size: 14px;
            word-break: break-word;

            &--big {
                flex: 2;
            }

            &--cc {
                text-transform: capitalize;
            }

            &--uc {
                text-transform: uppercase;
            }

            &--lc {
                text-transform: lowercase;
            }

            &--nc {
                text-transform: none;
            }

            &--actions {
                flex: 0.4;
            }

            &--pwr { // pre wrap
                white-space: pre-wrap;
            }
        }

        &__sicr { // nested table, sub iten container
            align-items: center;
            border: 1px solid #333333;
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
    
            &:last-child {
                margin-bottom: 0;
            }
        }
    
        &__siwr { // sub item wrapper
            border-right: 1px solid #333333;
            flex: 1;
            padding: 6px 0;
    
            &--v1 {
                border-right: 0;
                flex: 2;
            }

            &:last-child {
                border-right: 0;
            }
        }

        &__ml20 {
            margin-left: 20px;
        }
    }
}
