.wp2p { // wealth p2p
    margin: 0 auto 40px;
    max-width: 1000px;
    width: 100%;

    &-errlb { // error label
        font-size: 20px;
        margin-bottom: 40px;
        text-align: center;
    }

    &-modal { // content container
        &-kvpair {
            margin: 0;
            width: unset;
        }

        &-cta {
            margin: auto;
        }
    }
}
