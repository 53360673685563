.devs { // developer
    &-cr { // container
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    &-tabs-ph {
        background-color: #F5F5F5;
        display: flex;
        height: 35px;
    }

    &-cc { // content container
        width: calc(100% - 230px); // side navbar width - 230px
        /* scrollable container */
        display: flex;
        flex-direction: column;
        height: calc(100% - 92px); // 48px(header) + 44px(horizontal navbar)
        overflow: auto;
        position: fixed;
        left: 230px;
        top: 92px;
    }
}

.devs-v2 { // developer
    &-cr { // container
        display: flex;
        flex-direction: row;
        height: 100%;
        width: 100%;
        padding-top: 50px;
    }

    &-cc { // content container
        width: 100%; // side navbar width - 230px
        /* scrollable container */
        display: flex;
        flex-direction: column;
        height: 100%; // 48px(header) + 44px(horizontal navbar)
        background-color: #F5F5F5;
        left: 230px;
        top: 92px;
    }

    &-tabs {
        position: relative;
        top: -36px;
        left: 0;
        padding-left: 0 !important; ;
    }
}
