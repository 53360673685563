.sai-upload-csv { // upload csv screen
    margin: 0 auto;
    max-width: 800px;

    &-fc { // form container
        margin: 0 0 30px;

        &__lb { // to override form css of higher specificity
            font-size: 18px;
            font-weight: 400 !important;
            line-height: 20px !important;
            margin-right: 0 !important;
            text-align: left !important;
            width: 300px !important;
        }

        &__ip {
            width: 400px !important;
        }
    }

    &-sample-link {
        margin-top: 6px;
    }

    &-cta {
        margin: 60px 0 0 300px;
        width: 400px;
    }

    &-el { // err label
        font-size: 13px;
        font-weight: normal;
        margin-left: 300px;
    }

    &-success-screen {
        align-items: center;
        display: flex;
        margin-bottom: 40px;

        &__backarrow {
            cursor: pointer;
            height: 20px;
        }

        &__hd {
            font-size: 24px;
            font-weight: 400;
            line-height: 120%;
            margin: 0 auto;
            text-transform: uppercase;
        }

        &__desc {
            font-size: 20px;
            line-height: 140%;
            text-align: center;
            white-space: pre-wrap;
        }
    }
}
