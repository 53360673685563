.sop {
    min-height: 100%;
    background-color: #E6E9ED;

    .w-100 {
        width: 100%;
    }

    &-text-info {
        font-style: italic;
        font-size: 10px;
    }

    .line {
        border-bottom: 1px solid #E6E9ED;

        &-thick {
            @extend .line;
            border-bottom-width: 7px;
        }
    }

    &-title {
        font-size: 18px;

        &-sub {
            color: #6B7280;
        }
    }

    &-cr {
        margin: 20px;
    }

    &-heading {
        font-weight: 500;
        font-size: 20px;
    }

    &-cc { // content container
        border: 1px solid #E6E9ED;
        background-color: #FFFFFF;
        margin: 20px 0 40px;
        border-radius: 20px !important;

        &-v2 {
            @extend .sop-cc;
            max-height: 792px;
            overflow: auto;
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &-input-box {
        width: 100% !important;
        margin-right: unset !important;

        & > * {
            width: 100% !important;

            & > * {
                width: 100% !important;
            }
        }
    }

    &-array-item {
        background-color: #d7e4f5 !important;
        border-radius: 15px;
    }

    &-box {
        background-color: #F6F9FD !important;

        &-container {
            align-items: center;
        }

        &-grid {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));

            &-input {
                display: grid;
                grid-template-columns: repeat(2, minmax(0, 1fr));
            }

            &-v2 {
                @extend .sop-box-grid;
                grid-template-columns: 200px 200px 200px;
            }
        }

        &-title {
            width: 200px;
        }

        &-values {
            width: 100%;
        }

        &-cell {
            padding: 20px;
            overflow-wrap: anywhere;

            &-title {
                color: #6B7280;
                font-weight: 600;
                padding: 5px 0;
            }

            &-value {
                color: #1F2937;
                font-weight: 600;
                font-size: 1rem;
                padding: 1px 0;
            }

            &__danger {
                color: rgb(228, 61, 61);
                font-size: 1rem;
            }
        }
    }

    &-table {
        padding: 15px 0;

        &-wrapper {
            gap: 30px;
        }

        &-header {
            padding: 20px 30px !important;
            color: #9CA3AF;
        }

        &-cont {
            padding: 20px !important;
            max-height: 700px !important;
        }

        &-row {
            margin:10px !important;
            font-weight: 500;

            &:hover {
                background-color: #ebeef2;
                border-radius: 10px;
            }
        }

        &-cta {
            border-radius: 20px;

            &-dropdown {
                position: relative;
            }

            &-link {
                @extend .sop-table-cta;
                color: white;
                padding: 10px 40px;
                background-color: #00B899;
            }
        }

        &-column {
            &-short {
                flex: 0.75 0.55;
            }

            &-large {
                flex: 1.25 1.25;
            }
        }
    }

    &-traversal {
        position: relative;

        &-loading .ReactModalPortal {
            overflow: hidden;
            border-radius: 20px;
            top: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            backdrop-filter: blur(5px);
        }
    }

    &-step {

        &-no { //step number
            color: #9CA3AF;
            font-weight: 600;
            font-size: 14px;
        }

        &-table {
            &-title {
                color: #6B7280;
                font-weight: 600;
            }
            
            &-label {
                text-align: left !important;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                background-color: #EFF2F6 !important;
            }

            &-content {
                text-align: left !important;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                background-color: #EFF2F6 !important;
            }
        }

        &-title {
            color: #374151;
            font-size: 24px;
            font-weight: 700;
            margin: 10px 0;
        }

        &-des {
            color: #4B5563;
            font-size: 18px;
            font-weight: 600;
        }
         
        &-option {
            margin: 20px 20px 20px 0;
            border-radius: 20px !important;
        }

        &-back-img {
            transform: rotate(-135deg);
            width: 25px;
        }

        &-faq {
            border-radius: 20px;
            padding: 30px;
            width: 272px;
            background: #FEF3C7;
        }

        &-status {
            border-radius: 10px;
            padding: 10px;
            font-weight: 500;
            width: fit-content;
            background-color: #DBEAFE !important;
            color: #1E40AF !important;

            &-open {
                background-color: #fbfcdc !important;
                color: #5a6516 !important;
            }

            &-closed {
                background-color: #F3F4F6 !important;
                color: #6B7280 !important;
            }

            &-resolved {
                background-color: #defedb !important;
                color: #1eaf1e !important;
            }

            &-wai-on-cus {
                background-color: #FEF3C7 !important;
                color: #854D0E !important;
            }

            &-esc-to-ven {
                background-color: #EAE8F1B2 !important;
                color: #453C86 !important;
            }
        }

        &-details {
            &-lbl {
                color: #6B7280;
                font-weight: 600;
                padding: 5px 0;
            }

            &-val {
                color: #1F2937;
                font-weight: 600;
                font-size: 1rem;
                padding: 1px 0;
            }
        }

        &-hist {
            &-title {
                color: #4B5563;
                font-weight: 500;
            }

            &-step {
                color: #374151;
                font-size: 18px;
                font-weight: 600;
            }

            &-res {
                padding: 10px;
                margin: 10px;
                border-radius: 15px;
                width: fit-content;
                border: 1px solid #D1D5DB;
            }
        }
    }

    &-err {
        font-weight: 500;
        font-size: 16px;

        &-cont {
            padding: 30px;
            gap: 15px;
            justify-content: space-between;
        }

        &-button {
            border-radius: 20px;
        }
    }
}
