
.notification-top-bar {
    // position: fixed;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    width: 100%;
    padding: 0 10px;
    background: #1ABC9C;
    text-align: center;
    color: #FFFFFF;
    font-family: sans-serif;
    font-weight: lighter;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #F5F5F5;
    // border-radius: 5px;
    // margin: 2px 5px;

    .notification-text {
        width: 99%;
        font-size: 18px;    
        font-weight: 400;
        cursor: pointer;
    }

    .notification-close {
        margin-left: auto;
        padding-right: 0.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

