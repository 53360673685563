.header {
    width: 100%;
    height: 50px;
    font-size: 20px;
    font-weight: 400;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* fixed header */
    position: fixed;
    z-index: 1;

    &-v2 {
        width: 100%;
        height: 50px;
        font-size: 20px;
        font-weight: 400;
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.htitle {
    color:#00B899;
    font-weight: 800;
    font-size: 24px;
}
