.carousel {
    position: relative;

    &-cont {
        overflow: auto;

        /* To hide scrollbar */
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    &-arrow {
        display: block;

        &__hide {
            display: none;
        }
    }
}
