.deposits {
    margin: 0 auto;
    max-width: 900px;
    width: 100%;

    &-cr {
        margin: 0 auto;
        max-width: 1000px;
        width: 100%;
        position: relative;
    }

    &-ec { // expandable card
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &-fc {
        margin: 20px 0 0;

        &--m0 {
            margin: 0;
        }
    }

    &-sbwr { // search by wrapper
        padding: 10px 15px 20px;
    }

    &-sc { // search container
        margin-top: 30px;

        &--err {
            margin-top: 0px;
        }

        &__cta {
            width: 200px;
        }

        &__clear {
            margin-left: 60px;
        }
    }

    &-errlb { // error label
        margin: 5px 0 10px;
    }

    &-src { // search results container
        margin-top: 70px;
    }

    &-custom-dd {
        &__cc {
            &--ac {
                height: 137px;
                overflow-y: auto;
            }
        }
    }

    &-other-label {
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: left !important;
        width: 170px !important;
    }

    &-ad { // actions dialog
        position: relative;

        &-label {
            cursor: pointer;
            font-size: 16px;
            font-weight: 400;
        }
    
        &-cc { // content container
            margin-left: -150px;
            margin-top: 2px;
            width: 180px;

            &-wr {
                padding: 10px 0;
                text-align: left;

                &__label {
                    cursor: pointer;
                    padding: 5px 15px;
    
                    &:hover {
                        background-color: #f5f5f5;
                    }

                    &[disabled] {
                        background-color: #ffffff;
                        cursor: not-allowed;
                        opacity: 0.5;
                    }
                }
            }

            &--ac {
                height: 72px;
            }

            &--acrq { // active request list
                height: 46px;
            }
        }
    }

    &-aecta { // attach entity cta
        margin: 40px 0;
    }

    &-modal {
        &-extradata {
            display: flex;
            padding-bottom: 10px;
            border-bottom: 1px solid #ccc;
            margin-bottom: 10px;
        }

        &-col1 {
            width: 100px;
            text-align: center;
        }

        &-col2 {
            width: 370px;
            text-align: center;
        }

        &-hwr { // heading wrapper
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 20px;
        }

        &-sr { // search results
            margin-top: 0;
        }

        &-cwr { // content wrapper
            display: flex;
            margin-bottom: 12px;

            &__cl { // content label
                font-size: 16px;
                text-transform: capitalize;
                width: 200px;
                white-space: pre-wrap;
            }
    
            &__sp { // separator
                width: 50px;
            }
    
            &__cv { // content value
                font-size: 16px;
                text-align: left;
                text-transform: capitalize;
                word-break: break-word;
            }
        }

        &-bwr {
            margin: 20px auto 0;
            width: 100px;
        }

        &-tlw {
            align-items: center;
        }
    }
}
