.app__v2 {
    height: 100%;

    &-dot {
        height: 10px;
        width: 10px;
        margin-right: 5px;
        background-color: #86BA6F;
        border-radius: 50%;
        display: inline-block;
    }

    &-header {
        padding: 0 30px;

        &-box {
            background-color: #EDF5EB;
            padding: 15px;
            border-radius: 10px;
            font-size: 16px;
            font-weight: 600;
        }

        &-img {
            width: 40px;
        }

        &-title {
            font-weight: 700;
            font-size: 20px;
        }
    }

    &-nav {
        &-img { // Image
            padding: 5px;
            
            &-logo {
                width: 35px;
                height: 35px;
                padding: 5px;
            }

            &__active {
                background-color: #374151;
                border-radius: 20px;
            }

            &__hide {
                padding: 10px;
            }
        }

        &-text {
            padding-left: 10px;
            color: #E5E7EB;
            
            &__hide {
                display: none;
            }
        }

        &-hr { // Header
            display: flex;
            align-items: center;
            color: #E5E7EB;

            &-arrow {
                display: block;
                filter: brightness(400%);

                &__hide {
                    display: none;
                }
            }
        }

        &-cr { // container
            background-color: #1F2937;
            color: #E5E7EB;
            position: fixed;

            &__hide {
                @extend .app__v2-nav-cr;
                padding: 6px !important;
            }
        }

        &-items-cont {
            padding: 0px;
        }

        &-item {
            display: flex;
            padding: 10px;
            align-items: center;

            &__active {
                background-color: #374151;
                border-radius: 20px;

                &__hide {
                    background-color: #1F2937;
                }
            }

            &__hide {
                justify-content: flex-end;
                padding: 0px;
            }
        }
    }

    &-cr { // container
        height: 100%;
        margin-left: 300px;
        transition: margin 0.7s;
        background-color: #E6E9ED;

        &-full {
            @extend .app__v2-cr;
            margin-left: 50px;
        }
    }
}

.app__v2-nav-item__active .app__v2-nav-text {
    color: #4ADBC3;
}

.app__v2-nav-item__active .app__v2-nav-img {
    background-color: #b79d9d;
    border-radius: 10px;
}

.scripts-search-row-data {
    min-height: 50px;
    display: flex;
    align-items: center;
}