.arrow {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 70px;
    border-radius: 26px;
    background-color: #555555;
    cursor: pointer;
    position: absolute;
    top: 30%;
    z-index: 1;

    &__left {
        @extend .arrow;
        left: 0;
    }

    &__right {
        @extend .arrow;
        right: 0;
    }

    &-icon {
        display: block;
        width: 13px;
        height: 22px;
    }
}
