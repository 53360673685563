.tktsummary { // ticket summary
    &-cr { // container
        margin: 0 auto;
        max-width: 1000px;
        width: 100%;
    }

    &-ec { // expandable card
        margin-top: 40px;
        position: absolute;
        width: 100%;
        z-index: 1;
    }

    &-sc { // search container
        padding: 10px 15px 20px;
    }

    &-fc { // form container
        &--m0 {
            margin: 0;
        }
    }

    &-ffl { // form field label
        font-size: 18px;
        font-weight: 400;
        line-height: 20px;
        text-align: left !important;
        width: 300px !important;
    }

    &-src { // search results container
        margin-top: 150px;

        &-wr { // wrapper
            margin-bottom: 20px;
        }

        &__hl { // heading label
            font-size: 16px;
            text-transform: capitalize;
            width: 200px;
        }

        &__sp { // separator
            width: 50px;
        }

        &__cl { // content label
            font-size: 16px;
            text-align: left;
        }

        &-nwr { // notes wrapper
            display: flex;
            flex-wrap: wrap;
            width: 750px;

            &__cw { // content wrapper
                margin-bottom: 20px;
                margin-right: 20px;
            }
        }
    }
}
