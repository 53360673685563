.sbar {
    &-cr {
        position: absolute;
        top: 0;
        left: calc(100% - 300px);
        bottom: 0;
        display: flex;
        flex-direction: column;
        width: 300px;
        height: 100%;
        background-color: #f5f5f5;
        padding: 15px;
        transition: left 0.7s;
        z-index: 2;

        &-left {
            @extend .sbar-cr;
            left: unset;
            right: calc(100% - 300px);
            transition: unset;
            transition: right 0.7s;
        }

        &__hide {
            @extend .sbar-cr;
            left: calc(100% - 50px);

            &-left {
                @extend .sbar-cr__hide;
                left: unset;
                transition: right 0.7s;
                right: calc(100% - 50px);
            }
        }
    }

    &-arrow {
        cursor: pointer;
        height: 12px;
        width: 12px;
        transform: rotate(270deg);


        &--expanded {
            @extend .sbar-arrow;
            transform: rotate(90deg);
        }
    }

    &-header {
        &-cr {
            padding-bottom: 20px;
            cursor: pointer;

            &-left {
                flex-direction: row-reverse !important; 
                justify-content: space-between !important;
            }
        }

        &-icon {
            font-size: 20px;
            color: black;
        }

        &-content {
            margin-left: 10px;
            font-family: 'Figtree', sans-serif;
            font-weight: 400;
            font-size: 18px;
        }
    }
    
    &-items {
        display: flex;
        flex-direction: column;
        padding: 10px 5px;
    }
}
