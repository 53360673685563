@import '../../stylesheets/colors';

.clv { // comments list view
   height: 500px;
   overflow-y: auto;

   &-ct { // comment thread
      max-width: 100%;
      padding: 0 30px;
      background-color: $color-white;
      border: 1px solid transparent; /* Removes margin collapse */
      overflow-x: auto;
   }

   &-cm { // comment
      position: relative;
   } 

   &-ch { // comment heading
      display: flex;
      align-items: center;
      height: 50px;
      font-size: 14px;
   }

   &-cbd { // comment border
      display: block;
      position: absolute;
      top: 50px;
      left: 0;
      width: 12px;
      height: calc(100% - 50px);
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      background-color: $color-indigo-opaticy1;
      background-clip: padding-box;
   }

   &-cb { // comment-body 
      padding: 0 20px;
      padding-left: 28px;
   }


   &-ci { // comment info
      color: $color-black-opaticy1;
      margin-left: 10px;
   }

   &-ca { // comment author
      color: $color-black-opaticy2;
      font-weight: bold;
   } 
   &-cc { // comment case id
      font-weight: 700;
      color: #000;
   }
   &-ccc { // comment current case
      color: $color-cornflowerblue;
   }
}
